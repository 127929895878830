import { createSelector } from 'reselect';
import { productListSelector } from '../../selectors';
import { orgPropertiesSelector } from 'modules/user/selectors';
import {
  URLS_SUBMITTED,
  KEYWORDS_SUBMITTED,
  MAPPINGS_READY,
  NOT_CONFIGURED,
} from './constants';

import { loadProductTagMappingGenerator } from './stateGenerators';
import { isEmpty, get } from 'lodash';

export const originalProductURLsListSelector = createSelector(
  productListSelector,
  loadProductTagMappingGenerator.dataSelector,
  (productList, productTags) => {
    const URL_TAG_TYPE = 1;
    const urlTags = productTags.filter((v) => v.tag.tag_type === URL_TAG_TYPE);
    const tags = [];
    for (const product of productList) {
      const obj = { product: product.name, urls: [] };
      const associatedTags = urlTags.filter((v) => v.product === product.name);
      for (const productTag of associatedTags) {
        obj.urls.push({
          id: productTag.tag.id,
          mappingId: productTag.id,
          url: productTag.tag.value,
          updated: false,
          isDeleted: false,
        });
      }
      tags.push(obj);
    }
    return tags.reduce((acc, tag) => {
      acc.push({ product: tag.product, urls: tag.urls });
      return acc;
    }, []);
  }
);

export const originalKeywordsSelector = createSelector(
  productListSelector,
  loadProductTagMappingGenerator.dataSelector,
  (productList, productTags) => {
    const KEYWORD_TAG_TYPE = 2;
    const keywordTags = productTags.filter((v) => v.tag.tag_type === KEYWORD_TAG_TYPE);
    const tags = [];
    for (const product of productList) {
      const data = {
        product: product.name,
        keywordInput: null,
        urlModalVisible: false,
        keywords: {
          strong: [],
          moderate: [],
          weak: [],
          keywordInput: {
            strong: '',
            moderate: '',
            weak: '',
          },
        },
      };
      const associatedKeywords = keywordTags.filter((v) => v.product === product.name);
      for (const productKeyword of associatedKeywords) {
        const metadata = JSON.parse(productKeyword.tag.metadata);
        const weight = parseFloat(metadata ? metadata.weight: 0);
        const keyword = {
          id: productKeyword.tag.id,
          mappingId: productKeyword.id,
          isDeleted: false,
          updated: false,
          value: productKeyword.tag.value,
          weight,
        };
          // Strong keywords have a weight >= 0.75, moderate between 0.75 and 0.5, weak below 0.5
        if (weight >= 0.75) {
          data.keywords.strong.push(keyword);
        } else if (weight < 0.75 && weight >= 0.5) {
          data.keywords.moderate.push(keyword);
        } else {
          data.keywords.weak.push(keyword);
        }
      }
      tags.push(data);
    }
    return tags;
  });

const baseSelector = (state) => state.taxonomyV2.producturlconfig;

export const productURLsSelector = createSelector(baseSelector,
  ({ productURLs }) => productURLs);

export const productKeywordsSelector = createSelector(baseSelector,
  ({ productKeywords }) => productKeywords);

export const saveURLLoadingSelector = createSelector(baseSelector,
  ({ saveURL }) => saveURL.loading);

export const isSaveButtonDisabledSelector = createSelector(baseSelector,
  ({ productURLs }) => productURLs.some((tag) => tag.urls.some((urlObj) => isEmpty(urlObj.url))));

export const isStep1ValidSelector = createSelector(orgPropertiesSelector, (orgProperties) => {
  const productState = get(orgProperties, 'turbotax_product_taxonomy_state');
  return productState !== NOT_CONFIGURED &&
  [URLS_SUBMITTED, KEYWORDS_SUBMITTED, MAPPINGS_READY].includes(productState);
});
