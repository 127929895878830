import React, { useEffect, useState } from 'react';
import { Card, Flex, Modal } from '@sixsense/core/components';
import { Button, Input, RadioGroup, Text } from 'v2-components';
import { css } from 'styles/emotion';
import { pxToRem } from '@sixsense/core/style';
import { CreditUsers, ValidationType } from '../types';
import { creditValidationState } from '../state';
import { useSharedState } from '@sixsense/core';

const styles = {
  card: css({
    width: 360,
    height: 280,
  }),
  radioGroup: css({
    margin: '-20px -10px -10px -16px',
  }),
  radio: css({
    fontSize: pxToRem(13),
    color: '#001F32',
  }),
  errorMessage: css({
    color: '#D92D20',
    marginTop: '-6px',
  }),
};

type validateUpdate = {
  credits: number;
  action : string;
  user : CreditUsers;
}

type Props = {
  isOpen: boolean;
  onConfirm: (action: string, credits: number) => void;
  onReject: () => void;
  validateUpdateCredit: (credits : validateUpdate) => ValidationType;
  user : any;
};
const radioOption = {
  dontColorText: true,
  radioClassName: styles.radio,
};

const UpdateCreditModal = ({ isOpen, onConfirm, onReject, validateUpdateCredit, user }: Props) => {
  const [action, setAction] = useState('add');
  const [credits, setCredits] = useState(0);
  const [creditValidation] = useSharedState(creditValidationState);
  const reset = () => {
    setAction('add');
    setCredits(0);
  };
  useEffect(() => {
    validateUpdateCredit({ credits, action, user });
  }, [credits]);

  return open ? (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        onReject();
        reset();
      }}
    >
      <Card className={styles.card}>
        <Card.Header>
          <Card.Header.Title style={{ textTransform: 'none' }}>
            Update Credit
          </Card.Header.Title>
        </Card.Header>
        <Card.Content style={{ minHeight: 150 }}>
          <Flex direction="column" gap={16}>
            <div className={styles.radioGroup}>
              <RadioGroup
                options={[
                  { label: 'Add Credits', value: 'add', ...radioOption },
                  {
                    label: 'Remove Credits',
                    value: 'remove',
                    ...radioOption,
                  },
                ]}
                selectedValue={action}
                onChange={(actionName) => {
                  setAction(actionName);
                  setCredits(0);
                }}
              />
            </div>
            <Flex direction="column" gap={8}>
              <Text>Number of Credits</Text>
              <Input
                type="number"
                value={credits}
                onChange={(e) => setCredits(e.target.value)}
              />
              { creditValidation.disabled && <div className={styles.errorMessage}>
                {
                  creditValidation.error
                }</div>}
            </Flex>
          </Flex>
        </Card.Content>
        <Card.Footer>
          <Flex justifyContent="flex-end">
            <Button
              onClick={() => {
                onReject();
                reset();
              }}
            >
              Cancel
            </Button>
            <div style={{ width: 8 }} />
            <Button
              disabled={creditValidation.disabled}
              type="primary"
              onClick={() => {
                onConfirm(action, credits);
                reset();
              }}
            >
              Save
            </Button>
          </Flex>
        </Card.Footer>
      </Card>
    </Modal>
  ) : null;
};

type Executor =
  | {
      resolve: (value: any) => void;
      reject: (reason?: any) => void;
    }
  | undefined;

const useConfirm = ({ validateUpdateCredit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [executor, setExecutor] = useState<Executor>(undefined);
  const [user, setUser] = useState({});

  const confirm = (selectedUser) =>
    new Promise((resolve, reject) => {
      setExecutor({ resolve, reject });
      setIsOpen(true);
      setUser(selectedUser);
    });

  const onConfirm = (action: string, credits: number) => {
    if (executor) {
      executor.resolve({ action, credits });
      setIsOpen(false);
      setExecutor(undefined);
    }
  };

  const onReject = () => {
    if (executor) {
      executor.reject();
      setIsOpen(false);
      setExecutor(undefined);
    }
  };

  return {
    confirm,
    modalProps: {
      isOpen,
      onConfirm,
      onReject,
      validateUpdateCredit,
      user,
    },
  };
};

export default Object.assign(UpdateCreditModal, { useConfirm });
