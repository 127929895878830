
import {
  loadProductTagMappingGenerator,
  INITIALIZE_URLS,
} from './stateGenerators';
import { fetchSaga } from 'store/sagas';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { orgSelector, orgPropertiesSelector } from 'modules/user/selectors';
import {
  originalProductURLsListSelector,
  productURLsSelector,
  originalKeywordsSelector,
} from './selectors';
import { toaster } from '@sixsense/core';
import { get } from 'lodash';
import { NOT_CONFIGURED } from './constants';

import { actionTypes, actions } from './modules';

const { SAVE_URLS_REQUEST } = actionTypes;


function* saveUrlsSaga(request) {
  const org = yield select(orgSelector);
  const urlMappings = yield select(productURLsSelector);
  // const editState = yield select(editStateSelector);
  // const propertyEndpoint = `organization/${org}/properties/update/`;
  const scrapydEndpoint = `turbotax/taxonomy/org/${org}/turbotax/submit_urls/`;
  // const resetModelEndpoint = `turbotax/taxonomy/org/${org}/turbotax/reset_product_model/`;
  const validateUrlEndPoint = `turbotax/taxonomy/org/${org}/turbotax/validate_urls/`;
  /*
    This saga accomplishes the following:
      1. Delete any old URL tags (if the customer is editing configuration)
      2. Submit the configured urls to trigger scrapyd web scraping
      3. Change the org property for the product taxonomy state to "urls_submitted"
      4. If the user is editing the configuration, hit the taxonomy endpoint to reset
        product model/keywords.
  */
  const urlData = [];
  let validatingURL;
  try {
    for (const mapping of urlMappings) {
      // Add urls to scraping request body
      urlData.push({ product: mapping.product, urls: mapping.urls.map((v) => v.url) });

      // Validate the URLs
      for (const { url } of mapping.urls) {
        validatingURL = url;
        yield call(request, validateUrlEndPoint, 'POST', { body: JSON.stringify([url]) });
      }
    }

    validatingURL = null;

    // // Submit scraping request
    yield call(request, scrapydEndpoint, 'POST', { body: JSON.stringify(urlData) });
    // // Reset keywords/model if the user is editing configuration
    // // if (editState !== null) {
    // //   yield call(request, resetModelEndpoint, 'POST', { body: JSON.stringify([]) });
    // // }
    // // Update the org property.
    // const propertyData = {
    // property_type_id: PRODUCT_CONFIG_PROPERTY_TYPE, choice: URLS_SUBMITTED };
    // yield call(request, propertyEndpoint, 'PUT', { body: JSON.stringify(propertyData) });
    yield put(actions.saveURLSuccess());
    toaster.showSuccess('URLs submitted successfully.');
  } catch (e) {
    yield put(actions.saveURLFailure());

    console.log(e);
    let message = 'Unable to submit URLs at this time. Please try again later';

    if (validatingURL) {
      message = `Validation failed for URL: '${validatingURL}'. Please try again.`;
    }

    toaster.showError(message);
  }
}

function* initializeURLSaga() {
  const properties = yield select(orgPropertiesSelector);
  const productState = get(properties, 'turbotax_product_taxonomy_state');
  console.log({ productState });
  let originalProductTags = yield select(originalProductURLsListSelector);
  const originalProductKeywords = yield select(originalKeywordsSelector);
  if (productState === NOT_CONFIGURED) {
    originalProductTags = originalProductTags.map((product) => ({
      product: product.product,
      urls: [...Array(5)].fill({ id: null, url: '', updated: false, isDeleted: false }),
    }));
  }

  yield put(actions.setProductURLs(originalProductTags));
  yield put(actions.setProductKeywords(originalProductKeywords));
}

function* watchSaveURLs(request) {
  yield takeLatest(SAVE_URLS_REQUEST, saveUrlsSaga, request);
}

function* initializeURLs() {
  yield takeLatest(INITIALIZE_URLS, initializeURLSaga);
}

export const productUrlConfigSagas = [
  initializeURLs,
  fetchSaga(watchSaveURLs),
  loadProductTagMappingGenerator.saga,
];
