
import {
  loadAuditsStateGenerator,
  loadLastReviewedOnGenerator,
} from '../stateGenerators';

export const overviewSagas = [
  loadLastReviewedOnGenerator.saga,
  loadAuditsStateGenerator.saga,
];
