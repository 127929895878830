export const GENAI_HEADER = 'Generative AI';

export const GENAI_SUBHEADER = 'Configure and set access controls for AI Insights for your org';

export const BACK_BUTTON_TEXT = 'Back';

export const INSIGHTS_CARD_HEADER = 'AI Insights';

export const INSIGHTS_CARD_SUBHEADER =
  'Save time by reading an AI generated summary for an account.';

export const LEARN_MORE = 'Learn More';

export const LEARN_MORE_LINK = 'https://support.6sense.com/knowledge-base/ai-insights/';
