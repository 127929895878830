import { combineReducers } from 'redux';
import { createReducerUtil } from 'utils/core';
import {
  LAST_7_MONTHS,
  PRESET_STRING,
} from '../constants';
import { loaderPresets, setPayload } from '../utils';

const LOAD_STATUSES_REQUEST = `${PRESET_STRING}/LOAD_STATUSES_REQUEST`;
const LOAD_STATUSES_SUCCESS = `${PRESET_STRING}/LOAD_STATUSES_SUCCESS`;
const LOAD_STATUSES_FAILURE = `${PRESET_STRING}/LOAD_STATUSES_FAILURE`;

function loadStatuses(dataSource='all') {
  return {
    ...loaderPresets,
    type: LOAD_STATUSES_REQUEST,
    loading: true,
    dataSource,
  };
}
function loadStatusesSuccess(overviewStatus, entitiesArray) {
  return {
    type: LOAD_STATUSES_SUCCESS,
    loading: false,
    loaded: true,
    overviewStatus,
    entitiesArray,
  };
}
function loadStatusesFailure(errMessage, errStatus) {
  return {
    type: LOAD_STATUSES_FAILURE,
    loading: false,
    error: true,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}

const loadStatusesReducer = createReducerUtil({
  ...loaderPresets,
  overviewStatus: [],
  entitiesArray: [],
}, {
  [LOAD_STATUSES_REQUEST]: setPayload,
  [LOAD_STATUSES_SUCCESS]: setPayload,
  [LOAD_STATUSES_FAILURE]: setPayload,
});

const LOAD_COUNT_BY_MAPPING = `${PRESET_STRING}/LOAD_COUNT_BY_MAPPING`;
const LOAD_COUNT_BY_MAPPING_SUCCESS = `${PRESET_STRING}/LOAD_COUNT_BY_MAPPING_SUCCESS`;
const LOAD_COUNT_BY_MAPPING_FAILURE = `${PRESET_STRING}/LOAD_COUNT_BY_MAPPING_FAILURE`;

function loadCountByMapping(dataSource='all') {
  return {
    ...loaderPresets,
    type: LOAD_COUNT_BY_MAPPING,
    loading: true,
    dataSource,
    countByMapping: {},
  };
}
function loadCountByMappingSuccess(countByMapping) {
  return {
    type: LOAD_COUNT_BY_MAPPING_SUCCESS,
    loading: false,
    loaded: true,
    countByMapping,
  };
}
function loadCountByMappingFailure(errMessage, errStatus) {
  return {
    type: LOAD_COUNT_BY_MAPPING_FAILURE,
    loading: false,
    error: true,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}

const loadCountByMappingReducer = createReducerUtil({
  ...loaderPresets,
  countByMapping: {},
}, {
  [LOAD_COUNT_BY_MAPPING]: setPayload,
  [LOAD_COUNT_BY_MAPPING_SUCCESS]: setPayload,
  [LOAD_COUNT_BY_MAPPING_FAILURE]: setPayload,
});

const LOAD_RULES_COUNT_REQUEST = `${PRESET_STRING}/LOAD_RULES_COUNT_REQUEST`;
const LOAD_RULES_COUNT_SUCCESS = `${PRESET_STRING}/LOAD_RULES_COUNT_SUCCESS`;
const LOAD_RULES_COUNT_FAILURE = `${PRESET_STRING}/LOAD_RULES_COUNT_FAILURE`;

function loadRulesCount() {
  return {
    ...loaderPresets,
    type: LOAD_RULES_COUNT_REQUEST,
    loading: true,
  };
}
function loadRulesCountSuccess(rulesCount) {
  return {
    type: LOAD_RULES_COUNT_SUCCESS,
    loading: false,
    loaded: true,
    rulesCount,
  };
}
function loadRulesCountFailure(errMessage, errStatus) {
  return {
    type: LOAD_RULES_COUNT_FAILURE,
    loading: false,
    error: true,
    errorStatus: errStatus,
    errorMessage: errMessage,
  };
}

const loadRulesCountReducer = createReducerUtil({
  ...loaderPresets,
  rulesCount: [],
}, {
  [LOAD_RULES_COUNT_REQUEST]: setPayload,
  [LOAD_RULES_COUNT_SUCCESS]: setPayload,
  [LOAD_RULES_COUNT_FAILURE]: setPayload,
});

const CHANGE_DATE_RANGE = `${PRESET_STRING}/CHANGE_DATE_RANGE`;

function changeDateRange(dateRange) {
  return {
    type: CHANGE_DATE_RANGE,
    dateRange,
  };
}

const dateRangeReducer = createReducerUtil({ dateRange: LAST_7_MONTHS }, {
  [CHANGE_DATE_RANGE]: setPayload,
});

const GET_PUBLISHED_MAPPINGS_REQUEST = `${PRESET_STRING}/EVENT/GET_PUBLISHED_MAPPINGS_REQUEST`;
const GET_PUBLISHED_MAPPINGS_FAILURE = `${PRESET_STRING}/EVENT/GET_PUBLISHED_MAPPINGS_FAILURE`;
const GET_PUBLISHED_MAPPINGS_SUCCESS = `${PRESET_STRING}/EVENT/GET_PUBLISHED_MAPPINGS_SUCCESS`;

const getPublishedMappings = () => ({
  ...loaderPresets,
  type: GET_PUBLISHED_MAPPINGS_REQUEST,
  loading: true,
});

const getPublishedMappingsFailure = (errMessage, errStatus) => ({
  type: GET_PUBLISHED_MAPPINGS_FAILURE,
  loading: false,
  error: true,
  errMessage,
  errStatus,
});

const getPublishedMappingsSuccess = (mappingsPublished) => ({
  type: GET_PUBLISHED_MAPPINGS_SUCCESS,
  loading: false,
  loaded: true,
  mappingsPublished,
});

const PUBLISH_MAPPINGS_REQUEST = `${PRESET_STRING}/EVENT/PUBLISH_MAPPINGS_REQUEST`;
const PUBLISH_MAPPINGS_FAILURE = `${PRESET_STRING}/EVENT/PUBLISH_MAPPINGS_FAILURE`;
const PUBLISH_MAPPINGS_SUCCESS = `${PRESET_STRING}/EVENT/PUBLISH_MAPPINGS_SUCCESS`;

const publishMappings = (dataSource) => ({
  ...loaderPresets,
  type: PUBLISH_MAPPINGS_REQUEST,
  loading: true,
  dataSource,
});

const publishMappingsFailure = (errMessage, errStatus) => ({
  type: PUBLISH_MAPPINGS_FAILURE,
  loading: false,
  error: true,
  errMessage,
  errStatus,
});

const publishMappingsSuccess = (dataSource, value) => ({
  type: PUBLISH_MAPPINGS_SUCCESS,
  loading: false,
  loaded: true,
  dataSource,
  value,
});

const publishMappingReducer = createReducerUtil({
  ...loaderPresets,
  mappingsPublished: {},
}, {
  [PUBLISH_MAPPINGS_REQUEST]: setPayload,
  [PUBLISH_MAPPINGS_FAILURE]: setPayload,
  [GET_PUBLISHED_MAPPINGS_REQUEST]: setPayload,
  [GET_PUBLISHED_MAPPINGS_FAILURE]: setPayload,
  [GET_PUBLISHED_MAPPINGS_SUCCESS]: setPayload,
  [PUBLISH_MAPPINGS_SUCCESS]: (state, action) => ({
    ...state,
    mappingsPublished: {
      ...state.mappingsPublished,
      [action.dataSource]: action.value,
    },
  }),
});

export const actionTypes = {
  LOAD_STATUSES_REQUEST,
  LOAD_STATUSES_FAILURE,
  LOAD_STATUSES_SUCCESS,
  CHANGE_DATE_RANGE,
  LOAD_COUNT_BY_MAPPING,
  LOAD_COUNT_BY_MAPPING_FAILURE,
  LOAD_COUNT_BY_MAPPING_SUCCESS,
  PUBLISH_MAPPINGS_REQUEST,
  PUBLISH_MAPPINGS_FAILURE,
  PUBLISH_MAPPINGS_SUCCESS,
  GET_PUBLISHED_MAPPINGS_REQUEST,
  GET_PUBLISHED_MAPPINGS_FAILURE,
  GET_PUBLISHED_MAPPINGS_SUCCESS,
  LOAD_RULES_COUNT_REQUEST,
  LOAD_RULES_COUNT_FAILURE,
  LOAD_RULES_COUNT_SUCCESS,
};

export const actions = {
  loadStatuses,
  loadStatusesFailure,
  loadStatusesSuccess,
  changeDateRange,
  loadCountByMapping,
  loadCountByMappingFailure,
  loadCountByMappingSuccess,
  publishMappings,
  publishMappingsFailure,
  publishMappingsSuccess,
  getPublishedMappings,
  getPublishedMappingsFailure,
  getPublishedMappingsSuccess,
  loadRulesCount,
  loadRulesCountFailure,
  loadRulesCountSuccess,
};

export const commonReducer = combineReducers({
  loadStatuses: loadStatusesReducer,
  dateRangeOption: dateRangeReducer,
  loadCountByMapping: loadCountByMappingReducer,
  publishMapping: publishMappingReducer,
  loadRulesCount: loadRulesCountReducer,
});
