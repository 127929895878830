/**
 * Simple copy paste of
 * Settings/routes/Integration/routes/EmailServer/routes/List/components/StatusPill.js
 * Standardize this component when possible
 */

import React from 'react';
import propTypes from 'prop-types';
import { Alert } from 'v2-components';
import { classNames } from 'utils/utils';
import { css } from 'styles/emotion';
import { pxToRem, AAColor } from '@sixsense/core/style';

type StatusBadgeProps = {
  children: any;
  className: string;
  type: string;
};
const TYPES = {
  SUCCESS: 'success',
  INFO: 'info',
  FAILURE: 'error',
  PENDING: 'warning',
};

const CLASSNAMES = {
  [TYPES.INFO]: 'primaryText',
  [TYPES.FAILURE]: 'redText',
  [TYPES.SUCCESS]: 'greenText',
  [TYPES.PENDING]: 'yellowText',
};

const styles = {
  pillContainer: css({
    padding: `${pxToRem(5)} ${pxToRem(10)}`,
    fontWeight: 600,
  }),

  primaryText: css({
    color: AAColor.BLUE,
  }),

  redText: css({
    color: AAColor.ERROR,
  }),

  greenText: css({
    color: AAColor.GREEN,
  }),

  yellowText: css({
    color: '#D5980E',
  }),
};

const StatusBadge = ({ children, className, type }: StatusBadgeProps) => (
  <Alert
    className={classNames(styles.pillContainer, className)}
    type={type}
    message={<div className={styles[CLASSNAMES[type]]}>{children}</div>}
    banner={undefined}
    icon={undefined}
  />
);

StatusBadge.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
  type: propTypes.string,
  className: propTypes.string,
};

StatusBadge.defaultProps = {
  type: TYPES.SUCCESS,
  className: '',
};

StatusBadge.TYPES = Object.freeze(TYPES);

export default StatusBadge;
