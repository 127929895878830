import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styles/emotion';
import { AAColor, pxToRem, SixsenseColor } from '@sixsense/core/style';
import { Col, Row, Text } from 'v2-components';
import { If } from 'babel-plugin-jsx-control-statements';
import StatusBadge from './StatusBadge';
import { INTEGRATION_STATUS } from '../state';

const BORDER_STYLE = `1px solid ${AAColor.GREY3}`;

const styles = {
  container: css({
    backgroundColor: `${AAColor.WHITE}`,
    marginBottom: `${pxToRem(-30)}`,
    height: '20vh',
    border: BORDER_STYLE,
  }),
  mainBanner: css({
    marginTop: '3vh',
    padding: `0 ${pxToRem(10)} 0 ${pxToRem(30)}`,
  }),
  logo: css({
    height: '70%',
    width: '70%',
  }),
  smallLogo: css({
    height: '50%',
    width: '50%',
  }),
  typeBanner: css({
    backgroundColor: `${SixsenseColor.Gray.LIGHT_4}`,
    borderRadius: '4px',
    padding: `${pxToRem(5)}`,
  }),
  mainDescription: css({
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
  }),
  mainTitle: css({
    fontSize: '28px',
  }),
  subTitle: css({
    fontSize: '13px',
    color: `${SixsenseColor.Gray.DARK_1}`,
  }),
  btn: css({
    alignItems: 'center',
    height: '35px',
    display: 'flex',
  }),
};

const Header = ({ config, integrationStatus }) => {
  const {
    header: {
      logo = '',
      mainTitle = '',
      subTitle = '',
      category = '',
      smallLogo = '',
    } = {},
  } = config || {};
  return (
    <React.Fragment>
      <div className={styles.container}>
        <Row className={styles.mainBanner}>
          <Col flexBasis={'10%'} alignSelf={Col.ALIGN_SELF.CENTER}>
            <img
              src={logo}
              alt={logo}
              className={smallLogo ? styles.smallLogo : styles.logo}
            />
          </Col>
          <Col flexBasis={'90%'}>
            <Row className={styles.mainDescription} alignItems={Row.ALIGN_ITEMS.CENTER}>
              <Text type={Text.TYPE.TITLE} bold className={styles.mainTitle}>
                {mainTitle}
              </Text>
              <If
                condition={integrationStatus === INTEGRATION_STATUS.CONFIGURED}
              >
                <StatusBadge
                  className={styles.btn}
                  type={StatusBadge.TYPES.SUCCESS}
                >
                  Active
                </StatusBadge>
              </If>
              <If condition={integrationStatus === INTEGRATION_STATUS.ERROR}>
                <StatusBadge
                  className={styles.btn}
                  type={StatusBadge.TYPES.FAILURE}
                >
                  Error
                </StatusBadge>
              </If>
            </Row>
            <Row>
              <Text
                type={Text.TYPE.SUBBODY}
                className={`${styles.subTitle} aam-b--10`}
              >
                {subTitle}
              </Text>
            </Row>
            <Row>
              <Text className={styles.typeBanner}>{category}</Text>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

Header.propTypes = {
  config: PropTypes.object,
  integrationStatus: PropTypes.string.isRequired,
};

export default Header;
