import { createReducerUtil } from 'utils/core';
import { PRESET_STRING } from '../../../constants';
import { setPayload } from '../../../utils';

const JOBS_LIST_PRESET_STRING = `${PRESET_STRING}/JOBS_LIST`;

export const initialState = {
  selectedTab: null,
  jobs: [],
  filters: null,
  counts: null,
};

const SET_TAB = `${JOBS_LIST_PRESET_STRING}/SET_TAB`;
function setTab(selectedTab) {
  return {
    type: SET_TAB,
    selectedTab,
  };
}

const SET_JOBS = `${JOBS_LIST_PRESET_STRING}/SET_JOBS`;
function setJobs(jobs) {
  return {
    type: SET_JOBS,
    jobs,
  };
}

const APPEND_JOBS = `${JOBS_LIST_PRESET_STRING}/APPEND_JOBS`;
function appendJobs(newJobs) {
  return {
    type: APPEND_JOBS,
    newJobs,
  };
}

const UPDATE_FILTERS = `${JOBS_LIST_PRESET_STRING}/UPDATE_FILTERS`;
function updateFilters(updates) {
  return {
    type: UPDATE_FILTERS,
    updates,
  };
}

const UPDATE_COUNTS = `${JOBS_LIST_PRESET_STRING}/UPDATE_COUNTS`;
function updateCounts(updates) {
  return {
    type: UPDATE_COUNTS,
    updates,
  };
}

export const jobsListReducer = createReducerUtil(
  { ...initialState },
  {
    [SET_TAB]: setPayload,
    [SET_JOBS]: setPayload,
    [APPEND_JOBS]: (state, action) => ({
      ...state,
      jobs: state.jobs.concat(action.newJobs),
    }),
    [UPDATE_FILTERS]: (state, action) => ({
      ...state,
      filters: { ...state.filters, ...action.updates },
    }),
    [UPDATE_COUNTS]: (state, action) => ({
      ...state,
      counts: { ...state.counts, ...action.updates },
    }),
  }
);
export const actionTypes = {
  SET_TAB,
  SET_JOBS,
  APPEND_JOBS,
  UPDATE_FILTERS,
  UPDATE_COUNTS,
};

export const actions = {
  setTab,
  setJobs,
  appendJobs,
  updateFilters,
  updateCounts,
};

