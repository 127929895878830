import { AD_LIBRARY_BASE_ROUTE } from "./routes/AdLibrary/constants";
import { AD_BASE_ROUTE } from "./routes/AdLibrary/routes/AdPlacement/constants";
import { AD_LIST_BASE_ROUTE } from "./routes/AdLibrary/routes/AdPlacementList/constants";
import { values } from "lodash";
import { CREATE_EDIT_AD_ROUTE } from "./routes/AdLibrary/routes/CreateEditAd/constants";
import { DEFAULT_FOLDERS } from "./ducks/campaignClassifications/constants";
import { AD_LIBRARY_CLASSIFICATION_TYPES } from "./ducks/adLibraryClassifications/constants";
import { Archive, Folder, UnsortedFolder } from "@sixsense/core/icons";
import sixSenseLogo from "./images/6senseLogo.svg";
import linkedInLogo from "./images/LinkedInLogo.svg";
import { FEATURE_FLAGS } from "constants/featureFlags";

export const ADVERTISING_BASE_ROUTE = "advertising";

export const ADVERTISING_REDUX_STATE_KEY = "advertising";

export const ADVERTISING_ACTION_PREFIX = "ADVERTISING";

export const REPORTS_ROUTE = "reports";

export const ADVERTISING_CATEGORIES = {
  CAMPAIGNS: "campaigns",
  AD_LIBRARY: "ad-library",
  SEGMENT_SYNCS: "segment-syncs",
};

export const STATIC_TREE_NODE_KEYS = {
  CAMPAIGN_LIBRARY_ROOT: "campaign_library",
  CAMPAIGN_DRAFT_FOLDER: "draft_folder",
  CAMPAIGN_ALL: "all_campaigns",
  AD_LIBRARY_ROOT: "ad_library",
  AD_LIBRARY_ALL: "all_ads",
};

export const ADVERTISING_PATHS = {
  advertising: `${ADVERTISING_BASE_ROUTE}`,
  adLibrary: `${ADVERTISING_BASE_ROUTE}/${AD_LIBRARY_BASE_ROUTE}`,
  adsList: `${ADVERTISING_BASE_ROUTE}/${AD_LIBRARY_BASE_ROUTE}/${AD_LIST_BASE_ROUTE}`,
  ad: `${ADVERTISING_BASE_ROUTE}/${AD_LIBRARY_BASE_ROUTE}/${AD_BASE_ROUTE}`,
  adCreateEdit: `${ADVERTISING_BASE_ROUTE}/${AD_LIBRARY_BASE_ROUTE}/${CREATE_EDIT_AD_ROUTE}`,
  campaignView: `${ADVERTISING_BASE_ROUTE}/campaigns/view`,
  reports: `${ADVERTISING_BASE_ROUTE}/${REPORTS_ROUTE}`,
};

export const FILTER_FIELDS = {
  createdByMe: "createdByMe",
  auditStatuses: "auditStatuses",
  folders: "folders",
  campaigns: "campaigns",
  adTypes: "adTypes",
};

export const CAMPAIGN_ROW_DRAGGABLE_TYPE = "CAMPAIGN_ROW_DRAGGABLE_TYPE";

export const SELECTED_FOLDER_SEARCH_PARAM = "folder";

export const DRAFT_FOLDER_LABEL = "Drafts";
export const ALL_ADS_LABEL = "All Ads";
export const ALL_CAMPAIGNS_LABEL = "All Campaigns";

export const AD_LIBRARY_FILTER_FIELDS = {
  [FILTER_FIELDS.adTypes]: "placement_type__in",
  [FILTER_FIELDS.campaigns]: "campaign_id__in",
  [FILTER_FIELDS.folders]: "folder_id__in",
  [FILTER_FIELDS.createdByMe]: "created_by_me",
};

export const PAGE_NUMBER_SEARCH_PARAM = "page";
export const AD_LIST_PAGE_SEARCH_PARAM = "search";
export const AD_LIST_PAGE_OPEN_PREVIEW_PARAM = "openPreview";

// values also used in API calls
export const AD_TYPE_IDS = {
  bannerAd: "banner",
  videoAd: "video",
  nativeAd: "native",
  html5Ad: "html5",
  html5DynamicAd: "html5_dynamic",
  linkedInSingleImageAd: "linkedin_image",
  linkedInVideoAd: "linkedin_video",
};

export const AD_ATTACHMENT_TYPES = {
  LINKEDIN_VIDEO_THUMBNAIL: "linkedin_video_thumbnail",
  LINKEDIN_VIDEO_CAPTION: "linkedin_video_caption",
  icon: "icon",
};

export const AD_TYPE_LABELS = {
  [AD_TYPE_IDS.bannerAd]: "Banner Ad",
  [AD_TYPE_IDS.videoAd]: "Video Ad",
  [AD_TYPE_IDS.nativeAd]: "Native Ad",
  [AD_TYPE_IDS.html5Ad]: "HTML5 Ad",
  [AD_TYPE_IDS.html5DynamicAd]: "HTML5 Dynamic Ad",
  [AD_TYPE_IDS.linkedInSingleImageAd]: "Single Image Ad",
  [AD_TYPE_IDS.linkedInVideoAd]: "Video Ad",
};

export const CREATIVE_TYPE_LABELS = {
  [AD_TYPE_IDS.bannerAd]: "Image",
  [AD_TYPE_IDS.videoAd]: "Video",
  [AD_TYPE_IDS.nativeAd]: "Image",
  [AD_TYPE_IDS.html5Ad]: "HTML5",
  [AD_TYPE_IDS.html5DynamicAd]: "HTML5",
  [AD_TYPE_IDS.linkedInSingleImageAd]: "Image",
  [AD_TYPE_IDS.linkedInVideoAd]: "Video",
};

export const SAMPLE_DYNAMIC_HTML5_TEMPLATE_DOWNLOAD_URL =
  "/org/retrieve_s3object?" +
  "filename=sample_HTML5_dynamic_text_and_landing_personalization_300x250.zip";

export const SAMPLE_HTML5_TEMPLATE_DOWNLOAD_URL =
  "/org/retrieve_s3object?filename=6sense_HTML5_sample.zip";

export const LINKEDIN_PREVIEW_OPTIONS = {
  desktop: { label: "Desktop feed", value: "desktop" },
  mobile: { label: "Mobile feed", value: "mobile" },
};

export const VIEWABILITY = "viewability";
export const LINKEDIN_CPC_BIDDING = "linkedin_cpc_bidding";
export const LINKEDIN_ADVERTISING = "linkedin_advertising";
export const ADDITIONAL_DETAILS = "additional_details";

export const FEATURE_IDENTIFIERS = {
  CBD_FILTERS: "cbd_filters",
  OPTIMIZATION: "optimization",
  ADDITIONAL_DETAILS,
  ACCOUNT_PACING: "account_pacing",
};

export const NEW_FEATURES_IDENTIFIER = values(FEATURE_IDENTIFIERS);

export const AD_CLONE_NAME_SUFFIX = " - Clone";

export const AD_AUDIT_STATUS = {
  unknown: "unknown",
  pending: "pending",
  audited: "audited",
  error: "error",
};

export const AD_AUDIT_STATUS_LABELS = {
  [AD_AUDIT_STATUS.unknown]: "Not submitted for audit",
  [AD_AUDIT_STATUS.pending]: "Audit pending",
  [AD_AUDIT_STATUS.audited]: "Audit approved",
  [AD_AUDIT_STATUS.error]: "Audit failed",
};

export const FolderIcon = (type) => {
  const folderIconMap = {
    [DEFAULT_FOLDERS.CAMPAIGNS_ARCHIVED]: Archive,
    [DEFAULT_FOLDERS.CAMPAIGNS_UNSORTED]: UnsortedFolder,
    [AD_LIBRARY_CLASSIFICATION_TYPES.UNSORTED_FOLDER]: UnsortedFolder,
  };

  return folderIconMap[type] || Folder;
};

export const DROPDOWN_OPEN_TRIGGER = "click";

export const AD_NETWORKS = {
  sixSense: "6sense",
  linkedIn: "LinkedIn",
};

export const AD_TYPES = {
  [AD_NETWORKS.sixSense]: {
    description: `Create digital display, video, native, HTML5 & HTML5 Dynamics
    ads for 6sense campaigns that can run directly through the 6sense DSP.`,
    logo: sixSenseLogo,
    types: [
      { id: AD_TYPE_IDS.bannerAd, label: "Banner Ad" },
      { id: AD_TYPE_IDS.videoAd, label: "Video Ad" },
      { id: AD_TYPE_IDS.nativeAd, label: "Native Ad" },
      { id: AD_TYPE_IDS.html5Ad, label: "HTML5 Ad" },
      { id: AD_TYPE_IDS.html5DynamicAd, label: "HTML5 Dynamic Ad" },
    ],
  },
  [AD_NETWORKS.linkedIn]: {
    description: `Create LinkedIn Single Image Ad and Video Ads for LinkedIn campaigns
    that can run directly through 6sense.`,
    logo: linkedInLogo,
    types: [
      {
        id: AD_TYPE_IDS.linkedInSingleImageAd,
        label: "Single Image Ad",
        featureFlag: FEATURE_FLAGS.hasLinkedinAdvertising,
      },
      {
        id: AD_TYPE_IDS.linkedInVideoAd,
        label: "Video Ad",
        featureFlag: FEATURE_FLAGS.hasLinkedinAdvertising,
      },
    ],
  },
};

export const VIEWABILITY_TOOLTIP_INFO = `The viewability rate is the percentage of ad impressions that
were measured as viewable by a user. This metric is reportable
for 6sense advertising campaign and ad performance data as of
January 1, 2023.`;

export const VIEWABILITY_METRICS_DOJO_LINK =
  "https://support.6sense.com/hc/en-us/articles/360037628993#performance-metrics-0-0";

export const DOUBLE_DASH = "- -";

export const SORT_ORDER = {
  ascending: "asc",
  descending: "desc",
};
export const WEEK = "week";
export const MONTH = "month";
export const LAST_MONTH = "last_month";
export const MONTH_TO_DATE = "month_to_date";
export const LAST_QUARTER = "last_quarter";
export const QUARTER_TO_DATE = "quarter_to_date";
export const DATE_RANGE = "date_range";

export const DATE_RANGE_OPTIONS = [
  { value: WEEK, label: "Last 7 Days" },
  { value: MONTH, label: "Last 30 Days" },
  { value: LAST_MONTH, label: "Last Month" },
  { value: MONTH_TO_DATE, label: "Month to Date" },
  { value: LAST_QUARTER, label: "Last Quarter" },
  { value: QUARTER_TO_DATE, label: "Quarter to Date" },
  { value: DATE_RANGE, label: "Date Range" },
];

export const POSSIBLE_VALUE_DISPLAY_TYPES = {
  PERCENTAGE: "PERCENTAGE",
  CURRENCY: "CURRENCY",
};

export const DATA_ERROR_EMPTY_STATE_MESSAGE = {
  title: "Something went wrong",
  description:
    "We are having trouble getting this data. Please refresh the page or try again later.",
};
export const PTR_DIMENSIONS = {
  TIME: "time",
  CAMPAIGN_TYPE: "campaign_type",
  SEGMENT: "segment",
  PRODUCT: "product",
  PURCHASE_ORDER: "purchase_order",
  CAMPAIGN: "campaign",
  ACCOUNT: "account_name",
  GEOGRAPHY: "geography",
};
export const VIEW_CAMPAIGN_DETAILS_ROUTE = "view";

export const PTR_GEOGRAPHY_DIMENSION_GROUPS = {
  REGION: "region",
  COUNTRY: "country",
};
export const DATE_RANGE_FRAME = "dateRange";
export const START_DATE = "startDate";
export const END_DATE = "endDate";
export const PERFORMANCE_TREND_ROUTE = "performance-trend";

export const DASHBOARD_TO_PTR_SORT_PARAMS = {
  SORT_ORDER: "sortOrder",
  SORT_BY: "sortBy",
};
