import React, { useState } from "react";
import {
  Text,
  Badge,
  Flex,
  Button,
  Link,
  Modal,
  Card,
  CloseButton,
} from "@sixsense/core/components";
import { TextColor } from "@sixsense/core/style/variables";
import { CAMPAIGN_SOURCE } from "../../../constants";
import { truncateString } from "utils/utils";
import { css } from "styles/emotion";
import { NA } from "./common/NA";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { isDraftOrScheduledCampaign } from "../../../utils";
import { pxToRem } from "@sixsense/core/style";
import { isSegmentv2 } from "routes/AdvertisingDS/utils";
import { SegmentRoute } from "@sixsense/core/segments/management/constants";

// eslint-disable-next-line react/prop-types
const LocalModal = ({ children, content, title }) => {
  const [visible, setVisible] = useState(false);

  const footer = (
    <Flex justifyContent="flex-end">
      <Button
        hierarchy="primary"
        size="small"
        onClick={() => setVisible(false)}
      >
        Done
      </Button>
    </Flex>
  );

  return (
    <Flex>
      <Modal isOpen={visible} onRequestClose={() => setVisible(false)}>
        <Card className={css({ width: pxToRem(450) })}>
          <Card.Header
            title={title}
            actions={[
              <CloseButton title="Close" onClick={() => setVisible(false)} />,
            ]}
          />
          <Card.Content>{content}</Card.Content>
          <Card.Footer>{footer}</Card.Footer>
        </Card>
      </Modal>
      <div
        onClick={() => setVisible(true)}
        className={css({ ":hover": { cursor: "pointer" } })}
      >
        {children}
      </div>
    </Flex>
  );
};

export const SEGMENT_NAME = {
  key: "segment-name",
  csvColumnKey: "segment_name",
  title: <Label name={CAMPAIGN_LABELS.segmentName} />,
  dataIndex: "segment",
  defaultSelected: true,
  customizable: true,
  width: pxToRem(180),
  render: (segment, campaign) => {
    const { campaign_status, campaign_source } = campaign;

    if (
      isDraftOrScheduledCampaign(campaign_status) ||
      (!segment && !campaign.ad_network_segments?.length) ||
      campaign_source === CAMPAIGN_SOURCE.EXTERNAL
    ) {
      return <NA />;
    }

    let all_segments = [];

    if (campaign.ad_network_segments?.length) {
      all_segments = campaign.ad_network_segments;
    }

    if (segment) all_segments.push(segment);

    const firstSegmentId = all_segments[0].id;
    if (all_segments.length > 1) {
      return (
        <Flex>
          {
            <a
              href={
                isSegmentv2(firstSegmentId)
                  ? SegmentRoute.EDIT(firstSegmentId, "accounts")
                  : `/segments/segment/${firstSegmentId}/accounts/`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Link
                size={14}
                key={all_segments[0]?.id}
                title={all_segments[0]?.name}
              >
                {truncateString(all_segments[0].name, 20)}
              </Link>
            </a>
          }
          &nbsp;
          <LocalModal
            content={
              <Flex direction="column">
                {all_segments.map((seg) => (
                  <a
                    key={seg.id}
                    href={
                      isSegmentv2(seg.id)
                        ? SegmentRoute.EDIT(seg.id, "accounts")
                        : `/segments/segment/${seg.id}/accounts/`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="py1"
                  >
                    <Link size={14} title={seg.name}>
                      {seg.name}
                    </Link>
                  </a>
                ))}
              </Flex>
            }
            title={
              <Flex direction="column">
                <Text.Title size="xl" color={TextColor.HEAD}>
                  Segments
                </Text.Title>
                <Text.Body>{campaign.campaign_name}</Text.Body>
              </Flex>
            }
          >
            <Badge color="gray" mode="light">
              +{all_segments.length - 1}
            </Badge>
          </LocalModal>
        </Flex>
      );
    }

    return (
      <a
        href={
          isSegmentv2(firstSegmentId)
            ? SegmentRoute.EDIT(firstSegmentId, "accounts")
            : `/segments/segment/${firstSegmentId}/accounts/`
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <Link
          key={all_segments[0]?.id}
          id={all_segments[0]?.id}
          size={14}
          title={all_segments[0]?.name}
        >
          {truncateString(all_segments[0].name, 25)}
        </Link>
      </a>
    );
  },
  align: "left",
};
