// import { omit } from 'lodash';

const defaultInitialState = {
  loading: true,
  loaded: false,
  error: false,
  errorMessage: '',
  errorStatus: null,
  recommendationsLoading: false,
  recommendationsLoaded: true,
  recommendationsError: false,
  authTypeLoading: false,
  authTypeLoaded: false,
  authType: '',
  authTypeError: undefined,
};

const DOWNLOAD_RECOMMENDED_CSV = 'keywords/config/DOWNLOAD_RECOMMENDED_CSV';
const DOWNLOAD_RECOMMENDED_CSV_SUCCESS = 'keywords/config/DOWNLOAD_RECOMMENDED_CSV_SUCCESS';
const DOWNLOAD_RECOMMENDED_CSV_ERROR = 'keywords/config/DOWNLOAD_RECOMMENDED_CSV_ERROR';

function downloadRecommendedCSV(client, query, inputKeywords, limit, orgName) {
  return { type: DOWNLOAD_RECOMMENDED_CSV, client, query, inputKeywords, limit, orgName };
}
function downloadRecommendedCSVSuccess(inputKeywords, orgName) {
  return { type: DOWNLOAD_RECOMMENDED_CSV_SUCCESS, inputKeywords, orgName };
}
function downloadRecommendedCSVError(errorMessage) {
  return { type: DOWNLOAD_RECOMMENDED_CSV_ERROR, errorMessage };
}

const LOAD_ALL_REQUEST = 'settings/LOAD_ALL_REQUEST';
const REFRESH = 'settings/REFRESH';

function load() {
  return { type: LOAD_ALL_REQUEST };
}

function refresh() {
  return { type: REFRESH };
}

// token used to track campaigns beacon
const LOAD_TOKEN_REQUEST = 'settings/LOAD_TOKEN_REQUEST';
const LOAD_TOKEN_SUCCESS = 'settings/LOAD_TOKEN_SUCCESS';
const LOAD_TOKEN_FAILURE = 'settings/LOAD_TOKEN_FAILURE';

function loadToken() {
  return { type: LOAD_TOKEN_REQUEST };
}

function loadTokenSuccess(payload) {
  return { type: LOAD_TOKEN_SUCCESS, payload };
}

function loadTokenFailure(reason) {
  return { type: LOAD_TOKEN_FAILURE, reason };
}

// token used to track campaigns beacon
const LOAD_TOKEN_THIRD_PARTY_REQUEST = 'settings/LOAD_TOKEN_THIRD_PARTY_REQUEST';
const LOAD_TOKEN_THIRD_PARTY_SUCCESS = 'settings/LOAD_TOKEN_THIRD_PARTY_SUCCESS';
const LOAD_TOKEN_THIRD_PARTY_FAILURE = 'settings/LOAD_TOKEN_THIRD_PARTY_FAILURE';

function loadTokenThirdParty() {
  return { type: LOAD_TOKEN_THIRD_PARTY_REQUEST };
}

function loadTokenThirdPartySuccess(payload) {
  return { type: LOAD_TOKEN_THIRD_PARTY_SUCCESS, payload };
}

function loadTokenThirdPartyFailure(reason) {
  return { type: LOAD_TOKEN_THIRD_PARTY_FAILURE, reason };
}

// initialize state
const initialState = {
  ...defaultInitialState,
  token: defaultInitialState,
  tokenThirdParty: defaultInitialState,
};

// For loading current auth type
const LOAD_AUTH_TYPE = 'SETTINGS/SAMLSSO/LOAD_AUTH_TYPE';
const LOAD_AUTH_TYPE_SUCCESS = 'SETTINGS/LOAD_AUTH_TYPE/SUCCESS';
const LOAD_AUTH_TYPE_FAILURE = 'SETTINGS/LOAD_AUTH_TYPE/FAILURE';


const loadAuthType = () => ({
  type: LOAD_AUTH_TYPE,
});
const loadAuthTypeSuccess = (authType) => ({
  type: LOAD_AUTH_TYPE_SUCCESS,
  authType,
});
const loadAuthTypeFailure = (error) => ({
  type: LOAD_AUTH_TYPE_FAILURE,
  error,
});

// token used to track campaigns beacon
const LOAD_SI_INTERNAL_LICENSE_TYPE_REQUEST = 'settings/LOAD_SI_INTERNAL_LICENSE_TYPE_REQUEST';
const LOAD_SI_INTERNAL_LICENSE_TYPE_SUCCESS = 'settings/LOAD_SI_INTERNAL_LICENSE_TYPE_SUCCESS';

const loadSiInternalLicenseType = () => ({
  type: LOAD_SI_INTERNAL_LICENSE_TYPE_REQUEST,
});

const loadSiInternalLicenseTypeSuccess = (isInternalEntLite) => ({
  type: LOAD_SI_INTERNAL_LICENSE_TYPE_SUCCESS, isInternalEntLite,
});

export function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case DOWNLOAD_RECOMMENDED_CSV: {
      return {
        ...state,
        recommendationsLoading: true,
        recommendationsLoaded: false,
        recommendationsError: false,
      };
    }
    case DOWNLOAD_RECOMMENDED_CSV_SUCCESS: {
      return {
        ...state,
        recommendationsLoading: false,
        recommendationsLoaded: true,
        recommendationsError: false,
      };
    }
    case DOWNLOAD_RECOMMENDED_CSV_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        recommendationsLoading: false,
        recommendationsLoaded: true,
        recommendationsError: true,
      };
    }
    case REFRESH: {
      return { ...initialState };
    }
    case LOAD_TOKEN_SUCCESS: {
      const { payload } = action;
      const token = payload[0].token;
      const newToken = {
        loading: false,
        loaded: true,
        error: false,
        errorMessage: '',
        token,
      };
      return {
        ...state,
        token: newToken,
      };
    }
    case LOAD_TOKEN_FAILURE: {
      const { reason } = action;
      const newToken = {
        loading: false,
        error: true,
        errorMessage: reason.toString(),
      };
      return {
        ...state,
        token: newToken,
      };
    }
    case LOAD_TOKEN_THIRD_PARTY_SUCCESS: {
      const { payload } = action;
      const tokenThirdParty = payload[0].token;
      const newToken = {
        loading: false,
        loaded: true,
        error: false,
        errorMessage: '',
        tokenThirdParty,
      };
      return {
        ...state,
        tokenThirdParty: newToken,
      };
    }
    case LOAD_TOKEN_THIRD_PARTY_FAILURE: {
      const { reason } = action;
      const newToken = {
        loading: false,
        error: true,
        errorMessage: reason.toString(),
      };
      return {
        ...state,
        tokenThirdParty: newToken,
      };
    }

    case LOAD_AUTH_TYPE: {
      const authTypeLoading = true;
      const authTypeLoaded = false;
      return {
        ...state,
        authTypeLoading,
        authTypeLoaded,
      };
    }

    case LOAD_AUTH_TYPE_SUCCESS: {
      const authTypeLoading = false;
      const authTypeLoaded = true;
      const { authType } = action;
      return {
        ...state,
        authTypeLoading,
        authTypeLoaded,
        authType,
        authTypeError: undefined,
      };
    }
    case LOAD_AUTH_TYPE_FAILURE: {
      const authTypeLoading = false;
      const authTypeLoaded = false;
      const { error } = action;
      return {
        ...state,
        authTypeLoading,
        authTypeLoaded,
        authType: '',
        authTypeError: error,
      };
    }

    case LOAD_SI_INTERNAL_LICENSE_TYPE_SUCCESS: {
      const { isInternalEntLite } = action;
      return {
        ...state,
        isInternalEntLite,
      };
    }

    default:
      return state;
  }
}

export const actionTypes = {
  LOAD_ALL_REQUEST,
  LOAD_TOKEN_REQUEST,
  LOAD_TOKEN_SUCCESS,
  LOAD_TOKEN_FAILURE,
  LOAD_TOKEN_THIRD_PARTY_REQUEST,
  LOAD_TOKEN_THIRD_PARTY_SUCCESS,
  LOAD_TOKEN_THIRD_PARTY_FAILURE,
  REFRESH,
  DOWNLOAD_RECOMMENDED_CSV,
  DOWNLOAD_RECOMMENDED_CSV_SUCCESS,
  DOWNLOAD_RECOMMENDED_CSV_ERROR,
  LOAD_AUTH_TYPE,
  LOAD_SI_INTERNAL_LICENSE_TYPE_REQUEST,
};

export const actions = {
  load,
  loadToken,
  loadTokenSuccess,
  loadTokenFailure,
  loadTokenThirdParty,
  loadTokenThirdPartySuccess,
  loadTokenThirdPartyFailure,
  refresh,
  downloadRecommendedCSV,
  downloadRecommendedCSVSuccess,
  downloadRecommendedCSVError,
  loadAuthType,
  loadAuthTypeFailure,
  loadAuthTypeSuccess,
  loadSiInternalLicenseType,
  loadSiInternalLicenseTypeSuccess,
};
