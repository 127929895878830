import { select, call, put, takeEvery } from 'redux-saga/effects';
import { actionTypes, actions } from './modules';
import { fetchSaga, debounce, takeEarliest } from 'store/sagas';
import {
  dropDownEndpointSelector,
  getSegmentTagsSelector,
  getSegmentToUnpublishSelector,
} from './selectors';
import { orderBy, isEmpty } from 'lodash';
import { orgSelector, userSelector } from 'modules/user/selectors';
import { filtersConfigSelector } from 'modules/segments/selectors';
import {
  updateSegmentsResponse,
  updateTagResponse,
} from './utils';
import { formatFilterset } from 'modules/segments/utils';
import { actions as globalActions } from 'modules/global';
import { actions as segActions } from 'modules/segments';
import { fetchConfigs } from 'modules/segments/sagas';

const { showNotification } = globalActions;
const { loadFiltersConfig } = segActions;

const {
  DROPDOWN_SEGMENTS_SEARCH,
  LOAD,
  GET_SEGMENT_TAGS,
  UNPUBLISH_SELECTED_SEGMENT,
  UPDATE_PUBLISHED_TAGS,
} = actionTypes;

const {
  loadDropdownSegmentsSuccess,
  loadDropdownSegmentsFail,
  getSegmentsListSuccess,
  getSegmentsListFail,
  search,
  unpublishSelectedSegmentFail,
  getSegmentTagsFail,
  getSegmentTagsSuccess,
  loadSuccess,
  loadFail,
  updatePublishedTagsSuccess,
  updatePublishedTagsFail,
  dismiss,
  setSegmentDependencies,
} = actions;

function* loadInitialData(request) {
  try {
    const filtersConfig = yield select(filtersConfigSelector);
    if (isEmpty(filtersConfig)) {
      yield put(loadFiltersConfig());
      yield call(fetchConfigs, request);
    }
    yield call(loadSegmentTags, request);
    yield call(loadSegmentsList, request);
    yield put(loadSuccess());
  } catch (e) {
    yield put(loadFail(e.toString()));
  }
}

function* loadSegmentsDropdownList(request) {
  const endpoint = yield select(dropDownEndpointSelector);

  try {
    const segmentResponse = yield call(request, endpoint);
    yield put(loadDropdownSegmentsSuccess(
      segmentResponse.results
    ));
  } catch (e) {
    yield put(loadDropdownSegmentsFail(e.toString()));
  }
}

function* loadSegmentsList(request) {
  const orgId = yield select(orgSelector);
  const tags = yield select(getSegmentTagsSelector);
  const filtersConfig = yield select(filtersConfigSelector);
  const endpoint = `org/${orgId}/segment/?is_published=true&limit=200`;
  try {
    const segmentsJSON = yield call(request, endpoint, 'GET');
    const updatedSegments = yield updateSegmentsResponse(
      segmentsJSON.results, tags, filtersConfig, formatFilterset);
    const segList = segmentsJSON.results.map((segment) => segment.id).join(',');
    if (segList.length !== 0) {
      const dependenciesEndpoint = `org/${orgId}/segment/${segList}/compute_dependencies_bulk/`;
      const dependenciesDict = yield call(request, dependenciesEndpoint, 'GET');
      yield put(setSegmentDependencies(dependenciesDict));
    }
    yield put(getSegmentsListSuccess(
      orderBy(updatedSegments, ['name'], ['asc']),
      segmentsJSON.count
    ));
  } catch (e) {
    yield put(getSegmentsListFail(e.toString()));
  }
}

export function* loadSegmentTags(request) {
  const orgId = yield select(orgSelector);
  const endpoint = `org/${orgId}/segment_tag/?type=internal_source`;
  try {
    const segmentTags = yield call(request, endpoint, 'GET');
    const updatedTags = yield updateTagResponse(segmentTags.results);
    yield put(getSegmentTagsSuccess(updatedTags));
  } catch (e) {
    yield put(getSegmentTagsFail(e.toString()));
  }
}

function* updatePublishedTags(request, action) {
  const orgId = yield select(orgSelector);
  const userId = yield select(userSelector);
  const segmentId = action.segmentId;
  const tags = action.tags;
  const checked = action.checked;
  const endpoint = `org/${orgId}/segment/update_published_tags/`;
  const body = {
    body: JSON.stringify({
      edited_by_id: userId,
      segment_id: segmentId,
      tags,
      checked,
    }),
  };
  try {
    yield call(request, endpoint, 'PATCH', body);
    yield put(dismiss());
    if (action.showNotification) {
      yield put(showNotification(
        'success',
        'Successfully Published Segment'
      ));
    }
    if (!action.skipPublishedSegmentCalls) {
      yield call(loadSegmentsList, request);
      yield put(search());

    }
    yield put(updatePublishedTagsSuccess(segmentId, tags, action.skipPublishedSegmentCalls));
  } catch (e) {
    yield put(dismiss());
    yield put(updatePublishedTagsFail(e.toString()));
    yield put(showNotification(
      'error',
      'Unable to save changes to Published Segment'
    ));
  }
}

function* unpublishSelectedSegment(request) {
  const orgId = yield select(orgSelector);
  const userId = yield select(userSelector);
  const segmentId = yield select(getSegmentToUnpublishSelector);
  const endpoint = `org/${orgId}/segment/unpublish_segment/`;
  const body = {
    body: JSON.stringify({
      segment_id: segmentId,
      edited_by_id: userId,
    }),
  };

  try {
    const dependenciesEndpoint = `org/${orgId}/segment/${segmentId}/compute_dependencies/`;
    const dependenciesResponse = yield call(request, dependenciesEndpoint, 'GET');
    const depKey = Object.keys(dependenciesResponse.dependencies)[0];
    const dependencies = dependenciesResponse.dependencies[depKey];
    const dependenciesArray = [];

    if (dependencies.orchestrations_dependent) {
      dependenciesArray.push('orchestrations_dependent');
    }
    if (dependencies.six_qa_dependent) {
      dependenciesArray.push('six_qa_dependent');
    }
    if (dependencies.pipeline_intelligence_dependent) {
      dependenciesArray.push('pipeline_intelligence_dependent');
    }
    if (dependencies.funnel_insights_dependent) {
      dependenciesArray.push('funnel_insights_dependent');
    }

    if (dependenciesArray.length > 0) {
      yield put(unpublishSelectedSegmentFail(
        'Segment Has Dependencies',
        dependenciesArray,
        segmentId,
      ));
    } else {
      try {
        yield call(request, endpoint, 'PATCH', body);
        yield call(loadSegmentsList, request);
        yield put(showNotification(
          'success',
          'Successfully unpublished Segment'
        ));
      } catch (e) {
        yield put(unpublishSelectedSegmentFail(e.toString()));
        yield put(showNotification(
          'error',
          'Unable to unpublish Segment'
        ));
      }
    }
  } catch (e) {
    yield put(unpublishSelectedSegmentFail('Dependencies Check Failed'));
    yield put(showNotification(
      'error',
      'Dependencies Check Failed. Try Again Later'
    ));
  }
}

function* watchSegmentsDropdownChange(request) {
  yield debounce(400, [DROPDOWN_SEGMENTS_SEARCH], loadSegmentsDropdownList, request);
}

function* watchInitialLoad(request) {
  yield debounce(400, [LOAD], loadInitialData, request);
}

function* watchRemoveSelectedSegment(request) {
  yield takeEarliest(UNPUBLISH_SELECTED_SEGMENT, unpublishSelectedSegment, request);
}

function* watchGetSegmentTags(request) {
  yield debounce(400, [GET_SEGMENT_TAGS], loadSegmentTags, request);
}

function* watchUpdatePublishedTags(request) {
  yield takeEvery(UPDATE_PUBLISHED_TAGS, updatePublishedTags, request);
}

export default [
  fetchSaga(watchSegmentsDropdownChange),
  fetchSaga(watchInitialLoad),
  fetchSaga(watchRemoveSelectedSegment),
  fetchSaga(watchGetSegmentTags),
  fetchSaga(watchUpdatePublishedTags),
];
