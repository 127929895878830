import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { PageHeader } from 'HOCS';
import {
  SalesPermission,
  withPermissionBoundary,
  PermissionBoundary,
} from '@sixsense/rbac';
import Header from './Header';
import { AccessDenied, Text } from 'v2-components';
import CRMContainer from './containers/CRMSEPContainer';
import {
  crmTypeSelector,
  sepTypeSelector,
  sandboxCRMTypeSelector,
  isSandboxOrgSelector,
} from 'modules/user/selectors';
import { ResultBoundary, Loading, Flex } from '@sixsense/core/components';
import { NoDataComponent } from './components';
import { connect } from 'react-redux';
import {
  CRMSummaryConfig,
  savedConfigsState,
  SepSummaryConfig,
  SiIntegrationsConfig,
  useSiIntegrationRequest,
} from './state';
import { useSetSharedValue } from '@sixsense/core';
import { cloneDeep } from 'lodash';
import {
  siPackagePlanNameSelector,
  siInternalLicenseTypeSelector,
} from 'routes/Settings/selectors';
import { css } from 'styles/emotion';
import { MAINTENANCE_INFO } from 'routes/Settings/constants';

type ContainerProps = {
  data: {
    crm: SiIntegrationsConfig;
    sep: SiIntegrationsConfig;
  };
};

type SalesIntegrationProps = {
  crmType: string;
  sepType: string;
  siPackagePlanName?: string;
  sandboxCrmType?: string;
  isSandbox?: boolean;
  isInternalEntLite: boolean;
};

const styles = {
  error: css({
    margin: '10px 10px 0 0',
  }),
};

const ErrorDisplay = () => (
  <Flex style={{ justifyContent: 'center', paddingTop: '200px' }}>
    <Text type={Text.TYPE.TITLE} className={styles.error}>
      Look's like something went wrong, please try again later!
    </Text>
  </Flex>
);

const PageContainer = ({
  data,
  crmType,
  sepType,
  siPackagePlanName,
  isSandbox,
  isInternalEntLite,
}: ContainerProps & SalesIntegrationProps) => {
  const setSavedConfig = useSetSharedValue(savedConfigsState);
  useEffect(() => {
    if (data) {
      const cloned = cloneDeep(data);
      Object.keys(cloned).forEach((key) => {
        const { updated, updated_by, created, created_by, ...newClone } =
          cloned[key];
        newClone.export_options = {
          Lead: newClone.lead_export_enabled,
          Contact: newClone.contact_export_enabled,
          Account: newClone.account_export_enabled,
        };
        cloned[key] = newClone;
      });
      setSavedConfig(cloned);
    }
  }, []);

  return (
    <Flex direction="column" gap={16}>
      <PermissionBoundary
        allow={(permissions) =>
          permissions.has(SalesPermission.SETTINGS_SI_INTEGRATION_CRM_EDIT) && !isInternalEntLite
        }
        allowExternalSixsenseUser={!isInternalEntLite}
      >
        {() =>
          crmType && data.crm ? (
            <CRMContainer
              type="crm"
              value={crmType}
              siPackagePlanName={siPackagePlanName}
              isSandbox={isSandbox}
            />
          ) : (
            <NoDataComponent
              title={CRMSummaryConfig.NonConfigure.title}
              noDataStr={CRMSummaryConfig.NonConfigure.description}
            />
          )
        }
      </PermissionBoundary>
      {sepType && data.sep ? (
        <CRMContainer
          type="sep"
          value={sepType}
          crmType={crmType}
          siPackagePlanName={siPackagePlanName}
          isSandbox={isSandbox}
        />
      ) : (
        <NoDataComponent
          title={SepSummaryConfig.NonConfigure.title}
          noDataStr={SepSummaryConfig.NonConfigure.description}
        />
      )}
    </Flex>
  );
};

const SalesIntegrationPage = ({
  crmType,
  sepType,
  siPackagePlanName,
  sandboxCrmType,
  isSandbox,
  isInternalEntLite = false,
}: SalesIntegrationProps) => {
  const siIntegrationResult = useSiIntegrationRequest();
  if (!siPackagePlanName) return <AccessDenied />;
  if (MAINTENANCE_INFO.with_read_only) return <AccessDenied message={MAINTENANCE_INFO.message} />;

  return (
    <ResultBoundary
      result={siIntegrationResult}
      renderError={() => <ErrorDisplay />}
      renderLoading={() => <Loading.Spinner level="page" />}
    >
      {({ data }) => (
        <PageContainer
          data={data}
          crmType={
            isSandbox && sandboxCrmType && !crmType ? sandboxCrmType : crmType
          }
          sepType={sepType}
          siPackagePlanName={siPackagePlanName}
          isSandbox={isSandbox}
          isInternalEntLite={isInternalEntLite}
        />
      )}
    </ResultBoundary>
  );
};

SalesIntegrationPage.propTypes = {
  crmType: PropTypes.string,
  sepType: PropTypes.string,
  siPackagePlanName: PropTypes.string,
  sandboxCrmType: PropTypes.string,
  isSandbox: PropTypes.bool,
  isInternalEntLite: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  crmType: crmTypeSelector(state),
  sepType: sepTypeSelector(state),
  siPackagePlanName: siPackagePlanNameSelector(state),
  sandboxCrmType: sandboxCRMTypeSelector(state),
  isSandbox: isSandboxOrgSelector(state),
  isInternalEntLite: siInternalLicenseTypeSelector(state),
});

export default compose(
  withPermissionBoundary({
    allow: (permissions) =>
      [
        SalesPermission.SETTINGS_INTEGRATION_VIEW,
        SalesPermission.SETTINGS_INTEGRATION_EDIT,
      ].some((permission) => permissions.has(permission)),
    renderDenied: () => <AccessDenied accessDenied />,
  }),
  connect(mapStateToProps),
  PageHeader([Header])
)(SalesIntegrationPage);
