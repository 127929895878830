import { orgSelector } from 'modules/user/selectors';
import { createSelector } from 'reselect';
import { stateGenerator } from 'routes/Advertising/routes/Campaigns/stateGenerator';
import {
  PRESET_STRING,
  // VALID_DATA_SOURCES,
} from '../../constants';
import cannedData from './temp.json';

export const baseSelector = (state) => state.taxonomyV2.producturlconfig;

const loadProductTagMappingEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `turbotax/taxonomy/org/${orgId}/product_tag_mapping/`
);

// const SAVE_THRESHOLD_COVERAGE_SUCCESS = `${PRESET_STRING}/SAVE_THRESHOLD_COVERAGE_SUCCESS`;


// const saveThresholdCoverageReducer = {
//   [SAVE_THRESHOLD_COVERAGE_SUCCESS]: (state, action) => ({
//     ...state,
//     data: action.coverage,
//   }),
// };

// const SAVE_ASSIGNEE_SUCCESS = `${PRESET_STRING}/SAVE_ASSIGNEE_SUCCESS`;


// const saveAdminReducer = {
//   [SAVE_ASSIGNEE_SUCCESS]: (state, action) => ({
//     ...state,
//     data: action.assigees,
//   }),
// };

export const INITIALIZE_URLS = `${PRESET_STRING}/INITIALIZE_URLS`;

function initializeURLs() {
  return {
    type: INITIALIZE_URLS,
  };
}

export const loadProductTagMappingGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: `${PRESET_STRING}/PRODUCT_TAG_MAPPING`,
  endpointSelector: loadProductTagMappingEndpointSelector,
  stateKey: 'productTagMapping',
  receivedDataTransform: () => cannedData,
  initialData: [],
  onSuccessActions: [() => initializeURLs()],
  onFailureActions: [],
  method: 'GET',
  // attachReducer: saveThresholdCoverageReducer,
});
