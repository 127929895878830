/* eslint-disable react-hooks/exhaustive-deps */
import { useRequest, useSharedState } from '@sixsense/core';
import useCurrentUser from 'hooks/useCurrentUser';
import React, { useEffect, useState } from 'react';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import { css } from 'styles/emotion';
import {
  creditValidationState,
  orgPoolSelectedState,
  searchUsersState,
  selectedBalanceActionState,
  selectedBalanceState,
  selectedRoleState,
  selectedStatusState,
  userListState,
} from '../state';
import { Loading2 as Loading } from 'v2-components';
import { CreditType, CreditUsers, SortState } from '../types';
import AddCreditModal from './AddCreditModal';
import UpdateCreditModal from './UpdateCreditModal';
import UserList from './Users/UsersTable';
import UserCardHeader from './Users/UserTableHeaderCard';
import { actions as globalActions } from 'modules/global';
import {
  BALANCE_FILTER_TYPES,
  CREDIT_MSGS,
  LICENSE_TYPES_LABEL,
  STATUS_MAPPER,
} from '../constants';
import { usersFilter } from '../utils';
import { isNil, sortBy } from 'lodash';
import { SI_PlANS } from 'routes/Settings/constants';

const styles = {
  userSection: css({
    background: '#FFFFFF',
    border: '1px solid #C6CACE',
    marginTop: '15px',
    padding: '20px 30px 24px 30px',
  }),
};

type UsersCardProps = {
  users: Array<CreditUsers>;
  selectedUsers: Set<String>;
  setSelectedUsers: (users: Set<String>) => void;
  creditInfo: CreditType;
  orgId: number;
  userRoles: Array<[]>;
  setUsers: (users: Array<CreditUsers>) => void;
  getCreditInfo: () => void;
  getUsers: () => void;
  downloadCsv: (content: Array<[]>) => void;
  planName: string;
};

const CreditPoolUsersCard = (props: UsersCardProps) => {
  const {
    users,
    selectedUsers,
    userRoles,
    setSelectedUsers,
    creditInfo,
    orgId,
    setUsers,
    getCreditInfo,
    getUsers,
    downloadCsv,
    planName,
  } = props;
  const request = useRequest();
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const [usersList, setUsersList] = useSharedState(userListState);
  const [search] = useSharedState(searchUsersState);
  const [userStatus] = useSharedState(selectedStatusState);
  const [userRole] = useSharedState(selectedRoleState);
  const [balanceAction] = useSharedState(selectedBalanceActionState);
  const [balance] = useSharedState(selectedBalanceState);
  const [creditValidation, setCreditValidation] = useSharedState(
    creditValidationState
  );
  const [loading, setLoading] = useState(false);
  const LoadingComponent = Loading.LoadingComponent;
  const [sortState, setSortState] = useState<SortState>({
    column: 'user_email',
    order: 'asc',
  });
  const isPlanE = planName === SI_PlANS.PLAN_E;
  const [orgPoolSelected] = useSharedState(orgPoolSelectedState);

  useEffect(() => {
    filterUsers();
  }, [search, userRole, userStatus, users, balanceAction, balance]);

  useEffect(() => {
    sortUsers();
  }, [sortState]);

  const filterUsers = () => {
    const query: any = {};
    let data = usersFilter(users);
    if (userRole !== -1) {
      query.role_rbac = userRole;
    }
    if (userStatus !== 'all') {
      const statusList = STATUS_MAPPER[`${userStatus}`] || [];
      data = data.filter((el) => statusList.includes(el.status));
    }
    for (const filter in query) {
      if (filter) {
        data = data.filter((el) => el[filter] === query[filter]);
      }
    }
    if (search) {
      data = data.filter((el) => el.user_email.includes(search));
    }
    data = sortBy(data, [sortState.column]);
    if (sortState.order === 'desc') {
      data = data.reverse();
    }

    if (balanceAction && !isNil(balance)) {
      if (balanceAction === BALANCE_FILTER_TYPES.BELOW) {
        data = data.filter((el) => el.total_balance_credits <= Number(balance));
      } else if (balanceAction === BALANCE_FILTER_TYPES.ABOVE) {
        data = data.filter((el) => el.total_balance_credits >= Number(balance));
      }
    }
    setUsersList(data);
  };

  const sortUsers = () => {
    if (!sortState) {
      return;
    }
    let sorted = sortBy(usersList, [sortState.column]);
    if (sortState.order === 'desc') {
      sorted = sorted.reverse();
    }
    setUsersList(sorted);
  };

  const validateAddCredit = (credits) => {
    if (selectedUsers.size * credits > creditInfo?.total_unallocated_credits) {
      setCreditValidation({
        disabled: true,
        error: CREDIT_MSGS.ADD_CREDIT_ERR_MSG,
      });
      return;
    }

    if (credits < 0 || credits % 1 !== 0) {
      setCreditValidation({
        disabled: true,
        error: CREDIT_MSGS.INVALID_ERROR_MSG,
      });
      return;
    }
    if (!credits || credits === '0') {
      setCreditValidation({
        disabled: true,
        error: '',
      });
      return;
    }
    if (creditValidation.disabled) {
      setCreditValidation({
        disabled: false,
        error: '',
      });
    }
  };
  const validateUpdateCredit = ({ credits, action, user }) => {
    if (action === 'remove' && credits > user?.total_balance_credits) {
      setCreditValidation({
        disabled: true,
        error: CREDIT_MSGS.REMOVE_CREDIT_ERR_MSG,
      });
      return;
    }
    if (
      credits > creditInfo?.total_unallocated_credits &&
      action !== 'remove'
    ) {
      setCreditValidation({
        disabled: true,
        error: CREDIT_MSGS.UPDATE_CREDIT_ERR_MSG,
      });
      return;
    }

    if (credits < 0 || credits % 1 !== 0) {
      setCreditValidation({
        disabled: true,
        error: CREDIT_MSGS.INVALID_ERROR_MSG,
      });
      return;
    }
    if (!credits || credits === '0') {
      setCreditValidation({
        disabled: true,
        error: '',
      });
      return;
    }
    if (creditValidation.disabled) {
      setCreditValidation({
        disabled: false,
        error: '',
      });
    }
  };

  const { confirm: updateCredit, modalProps: updateModalprops } =
    UpdateCreditModal.useConfirm({ validateUpdateCredit });
  const { confirm: addCredit, modalProps: addModalprops } =
    AddCreditModal.useConfirm({ validateAddCredit });

  const alertMessage = (type: string, msg: string) => {
    dispatch(globalActions.showNotification(type, msg));
  };
  let roles = [];
  if (userRoles?.length) {
    roles = userRoles.map((role: any) => ({
      label: `${role.name} ${
        isPlanE && LICENSE_TYPES_LABEL[role?.license_type]
          ? `(${LICENSE_TYPES_LABEL[role?.license_type]})`
          : ''
      }`,
      value: role.id,
      [role.id]: role.name,
    }));
  }
  const addCredits = async (credit) => {
    const body = [...selectedUsers].map((user_email) => ({
      user_email,
      credits: parseInt(credit),
    }));
    try {
      setLoading(true);
      await request(
        `credit/${orgId}/users/add/
?credit_type=sales_intelligence&org_credits_id=${orgPoolSelected?.id}`,
        {
          method: 'POST',
          body: JSON.stringify(body),
        }
      );
      setLoading(false);
      getCreditInfo();
      await getUsers();
      alertMessage('success', CREDIT_MSGS.CREDITS_ADDED_MSG);
    } catch (ex) {
      setLoading(false);
      if (ex && ex.body && typeof ex.body === 'string') {
        alertMessage('error', ex.body);
        return;
      }
      alertMessage('error', 'Some error occurred');
    }
  };

  const updateCredits = async (credits, userInfo) => {
    try {
      let url = `credit/${orgId}/user/?credit_type=sales_intelligence`;
      url = `${url}&org_credits_id=${orgPoolSelected?.id}`;
      let requestParams = {
        method: 'POST',
        body: JSON.stringify({
          total_credits: credits,
          user_email: userInfo.user_email,
        }),
      };
      if (userInfo.user_credits_id) {
        url = `credit/${orgId}/user/${userInfo.user_credits_id}/?credit_type=sales_intelligence`;
        url = `${url}&org_credits_id=${orgPoolSelected?.id}`;
        requestParams = {
          method: 'PUT',
          body: JSON.stringify({
            total_credits: credits,
            updated_by: currentUser.id,
          }),
        };
      }
      setLoading(true);
      const res: CreditUsers = await request(url, requestParams);
      if (!userInfo.user_credits_id && requestParams.method === 'POST') {
        res.user_credits_id = res.id;
        res.total_balance_credits = res.total_credits;
      }
      setUsers(
        users.map((el) =>
          res.user_email === el.user_email ? { ...el, ...res } : el
        )
      );
      getCreditInfo();
      setLoading(false);
      alertMessage('success', CREDIT_MSGS.CREDITS_UPDATED_MSG);
    } catch (ex) {
      setLoading(false);
      if (ex && ex.body && typeof ex.body === 'string') {
        alertMessage('error', ex.body);
        return;
      }
      alertMessage('error', 'Some error occurred');
    }
  };

  const handleUpdateCredit = (user) => {
    updateCredit(user)
      .then(({ action, credits }) => {
        let credit = parseInt(user.total_credits);
        const creditToUpdate = parseInt(credits);
        if (action === 'add') {
          credit += creditToUpdate;
        }
        if (action === 'remove') {
          credit -= creditToUpdate;
        }
        updateCredits(credit, user);
      })
      .catch((reason) => {
        console.log('done', reason);
      });
  };
  const handleMultiAddCredit = () => {
    addCredit()
      .then(({ credits }) => {
        addCredits(credits);
      })
      .catch((reason) => {
        console.log('done', reason);
      });
  };

  return (
    <div className={styles.userSection}>
      {loading && <LoadingComponent level="cover" />}
      <UserCardHeader
        users={usersList}
        selectedUsers={selectedUsers}
        handleMultiAddCredit={handleMultiAddCredit}
        roles={roles}
        setSelectedUsers={setSelectedUsers}
        downloadCsv={downloadCsv}
        planName={planName}
      />
      <UserList
        users={usersList}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        handleUpdateCredit={handleUpdateCredit}
        roles={roles}
        sortState={sortState}
        setSortState={setSortState}
        planName={planName}
      />
      <UpdateCreditModal {...updateModalprops} />
      <AddCreditModal {...addModalprops} />
    </div>
  );
};

export default CreditPoolUsersCard;
