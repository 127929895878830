import marketoLogo from 'images/IntegrationLogos/adobe-logo.svg';
import hubspotLogo from 'images/IntegrationLogos/hubspot-logo.svg';
import dynamicsLogo from 'images/IntegrationLogos/microsoft-logo.svg';
import eloquaLogo from 'images/IntegrationLogos/oracle-corporation-logo.svg';
import pardotLogo from 'images/IntegrationLogos/pardot-logo.svg';
import salesforceLogo from 'images/IntegrationLogos/salesforce-old-logo.svg';

// mapping hierarchy (flowchart) logos
import DynamicsFlowLogo from 'images/IntegrationLogos/TaxonomyLogos/flow-dynamics-logo.svg';
import EloquaFlowLogo from 'images/IntegrationLogos/TaxonomyLogos/flow-eloqua-logo.svg';
import HubspotFlowLogo from 'images/IntegrationLogos/TaxonomyLogos/flow-hubspot-logo.svg';
import MarketoFlowLogo from 'images/IntegrationLogos/TaxonomyLogos/flow-marketo-logo.svg';
import PardotFlowLogo from 'images/IntegrationLogos/TaxonomyLogos/flow-pardot-logo.svg';
import SalesforceFlowLogo from 'images/IntegrationLogos/TaxonomyLogos/flow-salesforce-logo.svg';
import WebLogo from 'images/IntegrationLogos/TaxonomyLogos/web-logo.svg';

import { Globe } from '@sixsense/core/icons';
import { toLower } from 'lodash';

import { browserHistory } from 'react-router';

export const ROOT_FONT_SIZE = 16;
export const PRESET_STRING = 'SETTINGS/INTEGRATION/TAXONOMYV2';

export const GLOBAL_CONT_MARGIN_VERT = '1.5rem'; // negate this and use where required
export const GLOBAL_CONT_MARGIN_HORZ = '1.5rem';

export const GLOBAL_NAV_DATA_ID = 'taxonomy_global_nav';

// classification_type's
export const EVENT = 'event';
export const PRODUCT = 'product';
export const ACTIVITY = 'activity';

// Data sources
export const WEB = 'web';
export const MAP = 'map';
export const CRM = 'crm';
export const CRM_EVENT = 'crm_event';
export const CRM_TASK = 'crm_task';

// data_sources's for event
export const EVENT_WEB = 'web';
export const EVENT_MAP = 'map';
export const EVENT_CRM = 'crm';
export const EVENT_CRM_EVENT = 'crm_event';
export const EVENT_CRM_TASK = 'crm_task';

// data_sources's for product
export const PRODUCT_WEB = 'web';
export const PRODUCT_MAP = 'map';
export const PRODUCT_CRM = 'crm';

// Labels for sources
export const TAB_LABEL_WEB = 'WEB';
export const TAB_LABEL_MAP = 'MAP';
export const TAB_LABEL_CRM_CAMPAIGN = 'CRM (Campaign)';
export const TAB_LABEL_CRM_EVENT = 'CRM (Event)';
export const TAB_LABEL_CRM_TASK = 'CRM (Task)';
export const TAB_LABEL_CRM = 'CRM';

export const TURBOTAX_SI_PACKAGE_PLAN_NAMES = new Set(['C', 'D', 'E', 'F']);
export const HAS_OTHER_PRODUCTS_WITHOUT_ADVANCED_PACKAGE =
  'has_other_products_wthout_advncd';
export const REVIEW_ACCESS_INTERNAL_USER_FLAG = 'review_taxonomy_access_internal';

export const DUMMY_PRODUCT_NAME = 'Others';
export const DUMMY_PRODUCT_VALUE = '__other__';
export const DUMMY_PRODUCT_FLAG = 'has_other_products_wthout_advncd';
export const KEYWORD_EXPERIMENT_PRODUCT = 'keywordexperiment';

// TODO: get progress and reviewer info from API call
export const getMAPLogo = (mapType) => {
  if (!mapType) {
    return null;
  }
  let logo = hubspotLogo;
  switch (toLower(mapType)) {
    case 'marketo':
      logo = marketoLogo;
      break;
    case 'pardot':
      logo = pardotLogo;
      break;
    case 'eloqua':
      logo = eloquaLogo;
      break;
    default:
      break;
  }
  return logo;
};

export const getCRMLogo = (crmType) => {
  if (!crmType) {
    return null;
  }
  let logo = salesforceLogo;
  switch (toLower(crmType)) {
    case 'dynamics':
      logo = dynamicsLogo;
      break;
    case 'hubspotcrm':
      logo = hubspotLogo;
      break;
    default:
      break;
  }
  return logo;
};

export const getFlowLogo = (integrationType) => {
  let logo = WebLogo;
  switch (toLower(integrationType)) {
    case 'marketo':
      logo = MarketoFlowLogo;
      break;
    case 'pardot':
      logo = PardotFlowLogo;
      break;
    case 'eloqua':
      logo = EloquaFlowLogo;
      break;
    case 'hubspot':
      logo = HubspotFlowLogo;
      break;
    case 'salesforce':
      logo = SalesforceFlowLogo;
      break;
    case 'dynamics':
      logo = DynamicsFlowLogo;
      break;
    case 'hubspotcrm':
      logo = HubspotFlowLogo;
      break;
    default:
      break;
  }
  return logo;
};

export const DATA_SOURCES = (crmType, mapType) => [
  {
    icon: Globe,
    title: 'WEB',
    key: 'web',
    onClick: () => browserHistory.push('/settings/taxonomyV2/web'),
  },
  {
    image: getMAPLogo(mapType),
    title: 'MAP',
    key: 'map',
    onClick: () => browserHistory.push('/settings/taxonomyV2/map'),
  },
  {
    image: getCRMLogo(crmType),
    title: 'CRM (Campaign)',
    key: 'crm',
    onClick: () => browserHistory.push('/settings/taxonomyV2/crm'),
  },
  {
    image: getCRMLogo(crmType),
    title: 'CRM (Event)',
    key: 'crm_event',
    onClick: () => browserHistory.push('/settings/taxonomyV2/crm_event'),
  },
  {
    image: getCRMLogo(crmType),
    title: 'CRM (Task)',
    key: 'crm_task',
    onClick: () => browserHistory.push('/settings/taxonomyV2/crm_task'),
  },
];

export const LAST_30_DAYS = 'Last30Days';
export const LAST_60_DAYS = 'Last60Days';
export const LAST_90_DAYS = 'Last90Days';
export const LAST_7_MONTHS = 'Last7Months';

export const DATE_RANGES_OPTIONS = [
  { value: LAST_30_DAYS, label: 'Last 30 Days' },
  { value: LAST_60_DAYS, label: 'Last 60 Days' },
  { value: LAST_90_DAYS, label: 'Last 90 Days' },
  { value: LAST_7_MONTHS, label: 'All Time (Last 7 months)' },
];

export const DATA_SOURCE_FLAG_NAMES = {
  [EVENT_WEB]: 'web_turbotax_ready',
  [EVENT_MAP]: 'map_turbotax_ready',
  [EVENT_CRM]: 'crm_turbotax_ready',
  [EVENT_CRM_EVENT]: 'event_turbotax_ready',
  [EVENT_CRM_TASK]: 'task_turbotax_ready',
};

export const DATA_SOURCE_ABBREVIATIONS = {
  [WEB]: 'WEB',
  [MAP]: 'MAP',
  [CRM]: 'CRM (Campaign)',
  [CRM_EVENT]: 'CRM (Event)',
  [CRM_TASK]: 'CRM (Task)',
};

export const VALID_DATA_SOURCES = [WEB, MAP, CRM, CRM_EVENT, CRM_TASK];

export const VALID_CLASSIFICATION = [ACTIVITY, EVENT, PRODUCT];

export const DATASOURCE_PUBLISH_THRESHOLDS = {
  [WEB]: { activity: 90, product: 90 },
  [MAP]: { activity: 90, product: 90 },
  [CRM]: { activity: 90, product: 90 },
  [CRM_EVENT]: { activity: 90 },
  [CRM_TASK]: { activity: 90 },
};
