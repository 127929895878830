import { createSelector } from 'reselect';
import { loadAssigneeGenerator, loadCoverageThresholdGenerator } from './stateGenerators';
import { isPristine, isValid } from 'redux-form';
import { validateThreshold } from './utils';

const baseSelector = (state) => state.taxonomyV2;

export const adminSelector = createSelector(
    baseSelector,
    ({ preferences }) => preferences.admin);

const isAdminValidSelector = createSelector(
  adminSelector, (admin) => admin !== '' && admin !== null && admin !== undefined,
);

export const isAdminChangedSelector = createSelector(
    adminSelector,
    loadAssigneeGenerator.dataSelector,
    (newAdmin, assignees) => {
      if (!assignees) return false;
      const currentAdmin = assignees.find((assignee) => assignee.data_source === 'all');
      if (!currentAdmin) return false;
      return currentAdmin.name !== newAdmin;
    }
);

const getAssigneeForDataSourceSelector = (dataSource) => createSelector(
    loadAssigneeGenerator.dataSelector,
    (assignees) => {
      if (!assignees) return [];
      return assignees.filter((assignee) => assignee.data_source === dataSource);
    }
);

export const getAssigneesSelector = createSelector(
    getAssigneeForDataSourceSelector('web'),
    getAssigneeForDataSourceSelector('crm'),
    getAssigneeForDataSourceSelector('map'),
    getAssigneeForDataSourceSelector('crm_task'),
    getAssigneeForDataSourceSelector('crm_event'),
    (webAssignees, crmAssignees, mapAssignees, crmTaskAssignees, crmEventAssignees) => [
        { key: 'web', title: 'WEB', assignees: webAssignees },
        { key: 'map', title: 'MAP', assignees: mapAssignees },
        { key: 'crm', title: 'CRM (Campaign)', assignees: crmAssignees },
        { key: 'crm_task', title: 'CRM (Task)', assignees: crmTaskAssignees },
        { key: 'crm_event', title: 'CRM (Event)', assignees: crmEventAssignees },
    ]);

export const thresholdCoverageFormDataSelector = createSelector(baseSelector, ({ preferences }) =>
    preferences.coverageThresholdForm);

export const isThresholdCoverageFormValidSelector = createSelector(
  thresholdCoverageFormDataSelector,
    (formValues) => {
      if (!formValues) return false;
      const isInValid = formValues.some(
        (threshold) => validateThreshold(threshold.value) !== undefined);
      if (isInValid) return false;
      return true;
    });

export const isThresholdCoverageFormPristineSelector = createSelector(
  thresholdCoverageFormDataSelector, loadCoverageThresholdGenerator.dataSelector,
    (formValues, originalData) => {
      const isFormNotPristine = formValues.every((threshold) => {
        const ds = originalData.find((od) => od.data_source === threshold.data_source);
        if (ds && ds.value !== threshold.value) {
          return false;
        }
        return true;
      });
      return isFormNotPristine;
    });

export const headerSaveButtonEnabledSelector = createSelector(
    isPristine('form_assignee'),
    isValid('form_assignee'),
    isAdminValidSelector,
    isAdminChangedSelector,
    isThresholdCoverageFormValidSelector,
    isThresholdCoverageFormPristineSelector,
    (
        isAssigneeFormPristine,
        isAssigneeFormValid,
        isAdminValid,
        isAdminChanged,
        isThresholdFormValid,
        isThresholdFormPristine,
    ) => {
      if (isAssigneeFormValid && isAdminValid && isThresholdFormValid) {
        if (!isAssigneeFormPristine || isAdminChanged || !isThresholdFormPristine) return true;
      }
      return false;
    });

export const isloadingSelector = createSelector(baseSelector, ({ preferences }) =>
  preferences.saveCoverageThresholdLoading || preferences.saveAssigneeLoading);
