import { orgSelector } from 'modules/user/selectors';
import { createSelector } from 'reselect';
import { stateGenerator } from 'routes/Advertising/routes/Campaigns/stateGenerator';
import { PRESET_STRING } from './constants';
import { getDateRanges } from './utils';
import { toaster } from '@sixsense/core';

const taxonomySelector = (state) => state.taxonomyV2;

const mappedActivitiesEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `turbotax/taxonomy/org/${orgId}/mapping_fields/`
);

export const loadMappedActivitesStateGenerator = stateGenerator({
  // taxonomySelector, doesn't seem to work with selector
  baseStateSelector: (state) => state.taxonomyV2,
  actionPrefix: `${PRESET_STRING}/MAPPED_ACTIVITIES`,
  endpointSelector: mappedActivitiesEndpointSelector,
  stateKey: 'loadMappedActivites',
  onSuccessActions: [],
  onFailureActions: [() =>
    toaster.showError('error occcured while loading mapped activity data, please try again')],
  method: 'GET',
});

// REFACTOR THIS TO MAKE IT COMMON
export const dateRangeSelector = (state) => state.taxonomyV2.common.dateRangeOption.dateRange;
// doesn't seem to work with selector
// createSelector(taxonomySelector, (state) => state.common.dateRangeOption.dateRange);

export const getStartAndEndDateSelector = createSelector(dateRangeSelector,
  (dateRange) => getDateRanges(dateRange));

export const loadRecordsReviewStatusEndpointSelector = createSelector(
  orgSelector,
  getStartAndEndDateSelector,
  (orgId, dateRanges) => dateRanges ?
  `turbotax/taxonomy/org/${orgId}/` +
  `turbotax_records_review_status_count/?start_date=${dateRanges.from}&end_date=${dateRanges.to}` :
    `turbotax/taxonomy/org/${orgId}/turbotax_records_review_status_count/`
);

export const loadRecordsReviewStatusCountGenerator = stateGenerator({
  baseStateSelector: taxonomySelector,
  actionPrefix: `${PRESET_STRING}/RECORDS_REVIEW_STATUS`,
  endpointSelector: loadRecordsReviewStatusEndpointSelector,
  stateKey: 'recordsReviewStatus',
  initialData: [],
  onSuccessActions: [],
  onFailureActions: [() =>
    toaster.showError('error occcured while loading review status data, please try again')],
  method: 'GET',
});

