import { combineReducers } from 'redux';
import { jobsReducer } from '../routes/jobs/modules';
import reviewMappingReducer from '../routes/mappingReview/modules';
import { overviewReducer } from '../routes/overview/modules';
import { preferencesReducer } from '../routes/preferences/modules';
import { productUrlConfigReducer } from '../routes/productUrlConfig/modules';
import { ruleManagerReducer } from '../routes/ruleManager/modules';
import {
  loadMappedActivitesStateGenerator,
  loadRecordsReviewStatusCountGenerator,
} from '../stateGenerators';
import { commonReducer } from './commonModules';


export const taxonomyV2Reducer = combineReducers({
  [loadMappedActivitesStateGenerator.stateKey]: loadMappedActivitesStateGenerator.reducer,
  [loadRecordsReviewStatusCountGenerator.stateKey]: loadRecordsReviewStatusCountGenerator.reducer,
  ruleManager: ruleManagerReducer,
  overview: overviewReducer,
  jobsRoot: jobsReducer,
  common: commonReducer,
  reviewMapping: reviewMappingReducer,
  preferences: preferencesReducer,
  producturlconfig: productUrlConfigReducer,
});
