import React, { useEffect } from 'react';
import { destroyAllParamsNavigate } from 'utils/navigate';
import { Text, Col, Button, Modal, Row } from 'v2-components';
import styles from './Header.module.scss';
import { actions as globalActions } from 'modules/global';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import { useRequest, useSharedState } from '@sixsense/core';
import { useOrg } from 'hooks';
import { savedConfigsState, SiSaveIntegrationsConfig, unSavedChangesState } from './state';

const title = 'SI Integrations';
const subTitle =
  `— Control synchronization, manage update dependencies,
   and configure export settings in integrations`;

export const Header = () => {
  const { id: orgId } = useOrg();
  const dispatch = useDispatch();
  const request = useRequest();
  const [config, setSavedConfig] = useSharedState(savedConfigsState);
  const [unSavedFlag, setUnSavedFlag] = useSharedState(unSavedChangesState);

  const refreshState = () => {
    setSavedConfig(undefined);
    setUnSavedFlag(false);
  };
  useEffect(() => () => refreshState(), []);

  const handleSave = async () => {
    try {
      const body: SiSaveIntegrationsConfig[] = [];
      Object.values(config).forEach(
        (val) => {
          const { export_options, ...newState } = val;
          if (newState.integrated_app) {
            delete newState.mapping_profile;
            body.push(newState);
          }
        }
      );
      await request(
        `settings/${orgId}/integrations-config/bulk_update/`,
        {
          method: 'PATCH',
          body: JSON.stringify(body),
        }
      );
      // destroyAllParamsNavigate('/settings/manage');
      dispatch(
        globalActions.showNotification(
          'success',
          'Successfully Saved'
        )
      );
      setUnSavedFlag(false);
    } catch (e) {
      dispatch(
        globalActions.showNotification('error', 'Error updating configuration.')
      );
    }
  };
  return (
    <Row className={styles.container} alignItems={Row.ALIGN_ITEMS.CENTER}>
      <Col flexBasis={'80%'}>
        <Text bold className={styles.subTitle} color={Text.COLOR.BLACK}>
          {title}
        </Text>
        <Text className={styles.subTitle} color={Text.COLOR.GREY1}>
          {subTitle}
        </Text>
      </Col>
      <Col flexBasis={'10%'}>
        <Row style={{ gap: '10px' }}>
          <Button
            className={`${styles.button} ${styles.buttonHeight}`}
            onClick={() => {
              if (unSavedFlag) {
                Modal.prompt({
                  type: Modal.PROMPT_TYPES.CONFIRM,
                  onOk: () => {
                    destroyAllParamsNavigate('/settings/manage');
                    refreshState();
                  },
                  onCancel: () => ({}),
                  title: 'Are you sure you want to navigate away?',
                  content: `You have unsaved changes on this page.
              Are you sure you want to navigate away without saving?`,
                });
              } else {
                destroyAllParamsNavigate('/settings/manage');
                refreshState();
              }
            }}
          >
            Exit
          </Button>
          <Button
            className={styles.buttonHeight}
            type="primary"
            onClick={handleSave}
            disabled={!unSavedFlag}
          >
            Save Changes
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default Header;
