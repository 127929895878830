/* eslint-disable no-console */
import React, { ReactNode, useState } from 'react';
import { Flex, Card, Loading } from '@sixsense/core/components';
import {
  CRMSummaryConfig,
  EXPORT_OPTIONS,
  savedConfigsState,
  SepSummaryConfig,
  unSavedChangesState,
} from '../state';
import { Col, Row, TetheredSelect, Text,
   ToggleSwitch, Tooltip, Icon, RadioGroup, Link } from 'v2-components';
import { css } from 'styles/emotion';
import { AAColor, pxToRem, SixsenseColor } from '@sixsense/core/style';
import { CardHeader } from '../components';
import { useRequest, useSetSharedValue, useSharedState, useSharedValue } from '@sixsense/core';
import { SI_PlANS } from 'routes/Settings/constants';
import customStyles from './CRMSEPContainer.module.scss';
import { useOrg } from 'hooks';
import { sepExportEnrichSettingOptions, sepExportEnrichSettingTitle } from '../constants';

type Props = {
  type: "crm" | "sep";
  value: string;
  crmType?: string;
  siPackagePlanName?: string;
  isSandbox?: boolean;
};
type ContentToggleProps = {
  text: string;
  configKey: string;
  tooltip?: ReactNode;
};

const styles = {
  error: css({
    margin: '10px 10px 0 0',
  }),
  subCard: css({
    background: 'white',
    border: '1px solid #C6CACE',
    marginTop: '10px',
  }),
  nestedCard: css({
    marginBottom: '15px',
  }),
  title: css({
    marginTop: '10px',
  }),
  categoryIncludedLabel: css({
    color: `${AAColor.GREY4}`,
    margin: '5px 8px',
    fontSize: '12px',
  }),
  toggleSwitch: css({
    div: {
      backgroundColor: '#00000040',
      padding: pxToRem(2),
      div: {
        backgroundColor: AAColor.WHITE,
      },
    },
  }),
  off: css({
    color: AAColor.GREY1,
    fontWeight: 'bold',
  }),
  on: css({
    color: AAColor.GREEN,
    fontWeight: 'bold',
  }),
  toggleText: css({
    marginBottom: pxToRem(15),
  }),
  select: css({
    color: SixsenseColor.Black.DEFAULT,
  }),
  helpIcon: css({
    paddingLeft: '10px',
    position: 'absolute',
  }),
  contentCol: css({
    marginTop: '5px',
    marginBottom: '10px',
  }),
  sepRadio: css({
    padding: '0px !important',
  }),
  loadingWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    padding: '40px',
  }),
  mappingDropdown: css({
    '&:hover .linkText': {
      color: '#ffffff',
    },
  }),
};

const CRMSEPContainer = ({ type, value, crmType, siPackagePlanName, isSandbox }: Props) => {
  const org = useOrg();
  const setSavedConfig = useSetSharedValue(savedConfigsState);
  const savedConfig = useSharedValue(savedConfigsState);
  const [unSavedFlag, setUnSavedFlag] = useSharedState(unSavedChangesState);
  const request = useRequest();
  const config = savedConfig[type];
  const [exportOptions, setExportOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [apiCalled, setApiCalled] = useState(false);
  const metadata =
    type === 'crm' ? CRMSummaryConfig[value] : SepSummaryConfig[value];
  if (isSandbox && type === 'crm' && value === 'Salesforce' && metadata.header.mainTitle) {
    metadata.header.mainTitle = `${value} (Sandbox)`;
  }

  const exportOptionsFiltered = EXPORT_OPTIONS.filter((el) => el.integrations.includes(value));

  const getUnifiedMappingProfiles = async (objType, sysName) => {
    try {
      const result = await request(
        // eslint-disable-next-line max-len
        `cart/si/mapping_profile/org/${org.id}/system/${sysName}/object/${objType}`,
      );
      return result;
    } catch (ex) {
      return [];
    }
  };
  const fetchMappingData = async () => {
    setApiCalled(true);
    const mappingDataResults = exportOptionsFiltered.map(async (el) => {
      const mappingData = await getUnifiedMappingProfiles(el.value, value);
      return { ...el, mappingData };
    });
    const resolvedExportOptions = await Promise.all(mappingDataResults);
    setExportOptions(resolvedExportOptions);
    setShowLoader(false);
  };

  if (exportOptions.length === 0 && !apiCalled) {
    fetchMappingData();
  }

  const handleSelectChange = (selectedValue, id) => {
    if (selectedValue === 'newmapping') {
      window.open('/settings/manage?activeContext=mapping-profiles', '_blank');
    } else {
      setSavedConfig({
        ...savedConfig,
        [type]: {
          ...config,
          ...{
            [id]: selectedValue,
          },
        },
      });
      if (!unSavedFlag) {
        setUnSavedFlag(true);
      }
    }

  };

  const handleToggleClick = (configKey) => {
    if (configKey === 'contact_account_sync_enabled' && config[configKey]) {
      setSavedConfig({
        ...savedConfig,
        [type]: {
          ...config,
          ...{
            contact_push_on_account_failure_enabled: false,
            [configKey]: !config[configKey],
          },
        },
      });
    } else {
      setSavedConfig({
        ...savedConfig,
        [type]: {
          ...config,
          [configKey]: !config[configKey],
        },
      });
    }
    if (!unSavedFlag) {
      setUnSavedFlag(true);
    }
  };
  const handleEnrichExportToggle = (configKey, EnrichValue) => {
    setSavedConfig({
      ...savedConfig,
      [type]: {
        ...config,
        [configKey]: EnrichValue,
      },
    });
    if (!unSavedFlag) {
      setUnSavedFlag(true);
    }
  };

  const handleDropdownToggleClick = (id, dropdownSelectedValue) => {
    setSavedConfig({
      ...savedConfig,
      [type]: {
        ...config,
        ...{
          [id]: !config[id],
        },
      },
    });
    if ((!dropdownSelectedValue && !config[id])) {
      setUnSavedFlag(false);
    } else {
      setUnSavedFlag(true);
    }
  };

  const ContentToggle = ({ text, configKey, tooltip }: ContentToggleProps) =>
    text ? (
      <Col flexBasis={'100%'} maxWidth={'100%'} className={styles.toggleText}>
        <Flex>
          <Col flexBasis={'80%'} alignSelf={Col.ALIGN_SELF.CENTER}>
            <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.BLACK}>
              {text}
            </Text>
            {
              tooltip &&
                <Tooltip
                  getPopupContainer={(trigger) => (trigger ? trigger.parentElement : trigger)}
                  overlay={tooltip}
                >
                  <span>
                    <Icon
                      type="info"
                      className={styles.helpIcon}
                      color={Icon.COLORS.BLUE}
                      size={Icon.SIZE.MEDIUM}
                      pointer
                    />
                  </span>
                </Tooltip>
             }
          </Col>
          <Col flexBasis={'5%'} alignSelf={Col.ALIGN_SELF.CENTER}>
            <Row justifyContent={Row.JUSTIFY_CONTENT.CENTER}>
              <ToggleSwitch
                className={styles.toggleSwitch}
                selected={config[configKey]}
                onClick={() => handleToggleClick(configKey)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;
              </ToggleSwitch>
            </Row>
          </Col>
        </Flex>
      </Col>
    ) : (
      <></>
  );

  const Loader = () => (
    <div className={styles.loadingWrapper}>
      <Loading type="spinner" size={25} />
      <Text
        color={Text.COLOR.AA_GREY}
        weight={Text.WEIGHT.BOLD}
      >
        {'Loading......'}
      </Text>
    </div>
  );

  return (
    <React.Fragment>
      <CardHeader config={metadata} integrationStatus="CONFIGURED" />
      <Card.Content
        style={{
          background: 'white',
          border: '1px solid #C6CACE',
        }}
      >
        { type === 'crm' &&
        <Col className={styles.nestedCard}>
          <Text bold type={Text.TYPE.TITLE}>
            {type === 'crm' ? 'Leads/Contacts Settings' : 'Prospect Settings'}
          </Text>
          <Card.Content
            className={styles.subCard}
            style={{ paddingBottom: '0px' }}
          >
            <ContentToggle
              text={metadata.label3}
              tooltip={metadata?.tooltips?.label3}
              configKey={'contact_account_sync_enabled'}
            />
            {
              config.contact_account_sync_enabled &&
              <ContentToggle
                text={metadata.label4}
                configKey={'contact_push_on_account_failure_enabled'}
              />
            }

          </Card.Content>
        </Col>
        }
        <Col className={styles.nestedCard}>
          <Text bold type={Text.TYPE.TITLE}>
            Allowed Object for Export
          </Text>
          <Card.Content
            className={styles.subCard}
            style={{ paddingBottom: '0px' }}
          >
            {showLoader && <Loader />}
            {exportOptions.length > 0 && exportOptions.map((el) => (
              <Col className={styles.toggleText}>
                <Flex alignItems="center">
                  <Col flexBasis={'30%'} alignSelf={Col.ALIGN_SELF.CENTER}>
                    <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.BLACK}>
                      {el.value}
                    </Text>
                  </Col>
                  <Col flexBasis={'45%'} alignSelf={Col.ALIGN_SELF.CENTER}>
                    <TetheredSelect
                      showArrow
                      placeholder={'Select Mapping Profile'}
                      id={`${el.dropdownKey}`}
                      style={{ width: '100%' }}
                      mode="single"
                      value={
                        el.mappingData &&
                        el.mappingData.find((elem) => elem.mappingId === config[el.dropdownKey])
                        ? config[el.dropdownKey] : undefined
                      }
                      disabled={!config[el.toggleKey]}
                      onChange={(e) => handleSelectChange(e, el.dropdownKey)}
                      className={customStyles.customSelectStyles}
                    >
                      {el && el.mappingData && el.mappingData.length &&
                      el.mappingData.map((elem) => (
                        <TetheredSelect.Option
                          className={customStyles.customSelectStyles}
                          key={elem.mappingId}
                          value={elem.mappingId}
                        >
                          {elem.mappingName}
                        </TetheredSelect.Option>
                      ))}
                      <TetheredSelect.Option
                        className={`${customStyles.customSelectStyles} ${styles.mappingDropdown}`}
                        key={'newmapping'}
                        value="newmapping"
                        disabled={el?.mappingData?.length >= 5}
                      >
                        {el?.mappingData?.length >= 5 ?
                        '+ New Mapping Profile (5 limit reached)' :
                        <Link className={'linkText'}>+ New Mapping Profile</Link>}
                      </TetheredSelect.Option>
                    </TetheredSelect>
                  </Col>
                  <Col flexBasis={'5%'} alignSelf={Col.ALIGN_SELF.CENTER}>
                    <Row justifyContent={Row.JUSTIFY_CONTENT.CENTER}>
                      <ToggleSwitch
                        className={styles.toggleSwitch}
                        selected={config[el.toggleKey]}
                        onClick={() =>
                          handleDropdownToggleClick(el.toggleKey, config[el.dropdownKey])
                        }
                      >
                          &nbsp;&nbsp;&nbsp;&nbsp;
                      </ToggleSwitch>
                    </Row>
                  </Col>
                </Flex>
              </Col>
             ))}
            {(type === 'sep' && crmType) && (siPackagePlanName !== SI_PlANS.PLAN_F) && <Col
              flexBasis={'50%'}
              maxWidth={'50%'}
              className={styles.toggleText}
            >
              <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.BLACK}>
              Export Constraints
              <Tooltip
                getPopupContainer={(trigger) => (trigger ? trigger.parentElement : trigger)}
                overlay={metadata?.tooltips?.label6}
              >
                <span>
                  <Icon
                    type="info"
                    className={styles.helpIcon}
                    color={Icon.COLORS.BLUE}
                    size={Icon.SIZE.MEDIUM}
                    pointer
                  />
                </span>
              </Tooltip>
              </Text>
              <RadioGroup
                isVertical
                className={styles.sepRadio}
                options={[
                  {
                    label: <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.BLACK}>
                      CRM and non-CRM records
                    </Text>,
                    value: 'false',
                  },
                  {
                    label: <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.BLACK}>
                      CRM records only
                    </Text>,
                    value: 'true',
                  },
                ]}
                onChange={(v) => v !== config.export_only_crm_existing_records_to_sep?.toString()
                  && (handleToggleClick('export_only_crm_existing_records_to_sep'))}
                selectedValue={config.export_only_crm_existing_records_to_sep?.toString()}
              />
            </Col>
              }
            {
              type === 'sep' && crmType && siPackagePlanName !== SI_PlANS.PLAN_F && (
                <Col flexBasis={'50%'} maxWidth={'50%'} className={styles.toggleText}>
                  <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.BLACK}>
                    {sepExportEnrichSettingTitle}
                    <Tooltip
                      getPopupContainer={(trigger) => (trigger ? trigger.parentElement : trigger)}
                      overlay={metadata?.tooltips?.label7}
                    >
                      <span>
                        <Icon
                          type="info"
                          className={styles.helpIcon}
                          color={Icon.COLORS.BLUE}
                          size={Icon.SIZE.MEDIUM}
                          pointer
                        />
                      </span>
                    </Tooltip>
                  </Text>
                  <RadioGroup
                    isVertical
                    className={styles.sepRadio}
                    options={sepExportEnrichSettingOptions.map(({ label, optionValue }) => ({
                      label: (
                        <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.BLACK}>
                          {label}
                        </Text>
                      ),
                      value: optionValue,
                    }))}
                    onChange={(v) => handleEnrichExportToggle('enrich_when_exporting_to_sep', v)}
                    selectedValue={config.enrich_when_exporting_to_sep?.toString()}
                  />
                </Col>
              )
            }
          </Card.Content>
        </Col>
      </Card.Content>
    </React.Fragment>
  );
};

export default CRMSEPContainer;
