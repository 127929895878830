import React, { useEffect, useState } from 'react';
import { Card, Flex, Modal } from '@sixsense/core/components';
import { Button, Input, RadioGroup, Text } from 'v2-components';
import { css } from 'styles/emotion';
import { pxToRem } from '@sixsense/core/style';
import { ValidationType } from '../types';
import { creditValidationState } from '../state';
import { useSharedState } from '@sixsense/core';

const styles = {
  card: css({
    width: 450,
    height: 300,
  }),
  radioGroup: css({
    margin: '-20px -10px -10px -16px',
  }),
  radio: css({
    fontSize: pxToRem(13),
    color: '#001F32',
  }),
  errorMessage: css({
    color: '#D92D20',
    marginTop: '-6px',
  }),
};

type validateUpdate = {
  credits: number;
  action : string;
}

type Props = {
  isOpen: boolean;
  onConfirm: (action: string, credits: number) => void;
  onReject: () => void;
  validateUpdateCredit: (credits : validateUpdate) => ValidationType;
  creditInfo: number;
};
const radioOption = {
  dontColorText: true,
  radioClassName: styles.radio,
};

const DistributeCreditModal = ({
  isOpen,
  onConfirm,
  onReject,
  validateUpdateCredit,
  creditInfo,
}: Props) => {
  const [action, setAction] = useState('add_to_workflow');
  const [credits, setCredits] = useState(0);
  const [creditValidation] = useSharedState(creditValidationState);
  const reset = () => {
    setAction('add_to_workflow');
    setCredits(0);
  };
  useEffect(() => {
    validateUpdateCredit({ credits, action });
  }, [credits]);

  return open ? (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        onReject();
        reset();
      }}
    >
      <Card className={styles.card}>
        <Card.Header>
          <Card.Header.Title style={{ textTransform: 'none' }}>
            Distribute Credits
          </Card.Header.Title>
        </Card.Header>
        <Card.Content style={{ minHeight: 170 }}>
          <Flex direction="column" gap={16}>
            <div className={styles.radioGroup}>
              <RadioGroup
                options={[
                  {
                    label: 'Add Credits to Workflow',
                    value: 'add_to_workflow',
                    ...radioOption,
                  },
                  {
                    label: 'Add Credits to SI',
                    value: 'add_to_si',
                    ...radioOption,
                  },
                ]}
                selectedValue={action}
                onChange={(actionName) => {
                  setAction(actionName);
                  setCredits(0);
                }}
              />
            </div>
            <Flex direction="column" gap={8}>
              <Text>Number of Credits</Text>
              <Input
                type="number"
                value={credits}
                onChange={(e) => setCredits(e.target.value)}
              />
              {creditValidation.disabled && (
                <div className={styles.errorMessage}>
                  {creditValidation.error}
                </div>
              )}
              <Text weight={Text.WEIGHT.LIGHT} color={Text.COLOR.GREY4}>
                Up to {creditInfo[action]}{' '}
                {action === 'add_to_workflow' ? 'unallocated' : 'balance'}{' '}
                {action === 'add_to_workflow' ? 'SI' : 'Workflow'} credits can
                be added
              </Text>
            </Flex>
          </Flex>
        </Card.Content>
        <Card.Footer>
          <Flex justifyContent="flex-end">
            <Button
              onClick={() => {
                onReject();
                reset();
              }}
            >
              Cancel
            </Button>
            <div style={{ width: 8 }} />
            <Button
              disabled={creditValidation.disabled}
              type="primary"
              onClick={() => {
                onConfirm(action, credits);
                reset();
              }}
            >
              Save
            </Button>
          </Flex>
        </Card.Footer>
      </Card>
    </Modal>
  ) : null;
};

type Executor =
  | {
      resolve: (value: any) => void;
      reject: (reason?: any) => void;
    }
  | undefined;

const useConfirm = ({ validateUpdateCredit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [executor, setExecutor] = useState<Executor>(undefined);
  const [creditInfo, setCreditinfo] = useState(0);

  const confirm = (credits) =>
    new Promise((resolve, reject) => {
      setExecutor({ resolve, reject });
      setIsOpen(true);
      setCreditinfo(credits);
    });

  const onConfirm = (action: string, credits: number) => {
    if (executor) {
      executor.resolve({ action, credits });
      setIsOpen(false);
      setExecutor(undefined);
    }
  };

  const onReject = () => {
    if (executor) {
      executor.reject();
      setIsOpen(false);
      setExecutor(undefined);
    }
  };

  return {
    confirm,
    modalProps: {
      isOpen,
      onConfirm,
      onReject,
      validateUpdateCredit,
      creditInfo,
    },
  };
};

export default Object.assign(DistributeCreditModal, { useConfirm });
