import React from 'react';
import Flowchart from './Flowchart';
import { get, toLower } from 'lodash';
import { EmptyState, Flex, Text } from '@sixsense/core/components';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import {
  EVENT_WEB,
  EVENT_MAP,
  EVENT_CRM,
  EVENT_CRM_EVENT,
  EVENT_CRM_TASK,
  DATA_SOURCE_FLAG_NAMES,
} from '../constants';
import { actions as globalActions } from 'modules/global';
import { crmTypeSelector, mapTypeSelector, orgFlagsSelector } from 'modules/user/selectors';
import { isSingleProductSelector } from '../selectors';
import { connect } from 'react-redux';
import { compose } from 'redux';

const DATASOURCE_NAME_MAPPING = {
  [EVENT_WEB]: 'Website URL',
  [EVENT_MAP]: 'Map',
  [EVENT_CRM]: 'CRM (Campaign)',
  [EVENT_CRM_EVENT]: 'CRM(Event)',
  [EVENT_CRM_TASK]: 'CRM(Task)',
};

// eslint-disable-next-line react/prop-types
const LoadingState = ({ type = 'progress' }) => {
  if (type === 'permission-error') {
    // TODO: finish this
    return (<EmptyState
      v6
      level="page"
      orientation="vertical"
      illustration="failure"
      title="Access is needed"
      // eslint-disable-next-line max-len
      description="The system needs access to below objects to populate taxonomy data mappings for CRM (Event).
    Provide access or contact our PC shweta.subramanium@6sense.com
    “Event - Table not found: sfdc_event_prepped” "
    />);
  }
  return (
    <Flex justifyContent="center" style={{ width: '80vw' }}>
      <EmptyState
        v6
        level="page"
        orientation="vertical"
        illustration="progress"
        title="Data sync is in progress"
        description={
          <Flex direction="column">
            <Text>
            Taxonomy mappings will be available soon
          </Text>
            <Text>
      Please visit the &nbsp;
      <Link
        href="/settings/integration/manage"
        target="_blank"
        rel="noopener noreferrer"
      >
        Integrations
      </Link>
      &nbsp;tab to track the progress
      </Text>
          </Flex>
    }
      />
    </Flex>
  );
};

const DataSourceComponent = ({
  showGlobalNotFound,
  crmType,
  mapType,
  isSingleProduct,
  orgFlags,
  ...props
}) => {
  const dataSource = toLower(get(props, ['params', 'dataSource']));

  if (![EVENT_WEB, EVENT_MAP, EVENT_CRM, EVENT_CRM_EVENT, EVENT_CRM_TASK].includes(dataSource)) {
    showGlobalNotFound(true);
  }

  const dataSyncInProgress = !get(orgFlags, DATA_SOURCE_FLAG_NAMES[dataSource]);

  if ((dataSource.startsWith(EVENT_CRM) && !crmType) ||
  (dataSource.startsWith(EVENT_MAP) && !mapType)) {
    return (
      <Flex justifyContent="center" style={{ width: '80vw' }}>
        <EmptyState
          v6
          level="page"
          orientation="vertical"
          illustration="404"
          title={`${DATASOURCE_NAME_MAPPING[dataSource]} not connected`}
          description={
            <Text>
        Please visit the &nbsp;
        <Link
          href="/settings/integration/manage"
          target="_blank"
          rel="noopener noreferrer"
        >
          Integrations
        </Link>
        &nbsp; tab to connect your CRM or MAP
        </Text>
      }
        />
      </Flex>
    );
  }
  let integrationType = EVENT_WEB;
  if (dataSource.startsWith(EVENT_CRM)) {
    integrationType = toLower(crmType);
  } else if (dataSource.startsWith(EVENT_MAP)) {
    integrationType = toLower(mapType);
  }
  if (dataSyncInProgress) {
    return (<LoadingState />);
  }
  return (
    <Flowchart
      integrationType={integrationType}
      containerName={DATASOURCE_NAME_MAPPING[dataSource]}
      isMultiProduct={!isSingleProduct}
      dataSource={dataSource}
    />
  );
};

DataSourceComponent.propTypes = {
  showGlobalNotFound: PropTypes.func,
  crmType: PropTypes.string,
  mapType: PropTypes.string,
  isSingleProduct: PropTypes.bool,
  orgFlags: PropTypes.object,
};


const mapDispatchToProps = {
  showGlobalNotFound: globalActions.showGlobalNotFound,
};

const mapStateToProps = (state) => ({
  crmType: crmTypeSelector(state),
  mapType: mapTypeSelector(state),
  isSingleProduct: isSingleProductSelector(state),
  orgFlags: orgFlagsSelector(state),
});

export const DataSource = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(DataSourceComponent);
