import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from '@sixsense/core/components';

const defaultText = `Action & Channels are set of pre-defined actions 
performed by users through pre-defined channels. They always go in combination`;

const AnnotationNode = ({ text = '' }) => (
  <React.Fragment>
    <Flex style={{ textWrap: 'wrap', width: '400px' }}>
      <Text.Body>{text || defaultText}</Text.Body>
    </Flex>
  </React.Fragment>
);

AnnotationNode.propTypes = {
  text: PropTypes.string,
};

export default memo(AnnotationNode);
