import {
  EVENT, EVENT_CRM, EVENT_CRM_EVENT, EVENT_CRM_TASK,
  EVENT_MAP, EVENT_WEB, PRODUCT, PRODUCT_CRM, PRODUCT_MAP, PRODUCT_WEB,
  TAB_LABEL_CRM,
  TAB_LABEL_CRM_CAMPAIGN,
  TAB_LABEL_CRM_EVENT,
  TAB_LABEL_CRM_TASK,
  TAB_LABEL_MAP,
  TAB_LABEL_WEB,
} from '../../constants';
import { MODEL_AND } from './rbmAstUtils';

export const PRESET_STRING = 'SETTINGS/TAXONOMY_V2/RULE_MANAGER';

export const EVENT_TAB_OPTIONS = [
  {
    key: TAB_LABEL_WEB,
    label: TAB_LABEL_WEB,
    value: {
      dataSource: EVENT_WEB,
      classificationType: EVENT,
    },
  },
  {
    key: TAB_LABEL_MAP,
    label: TAB_LABEL_MAP,
    value: {
      dataSource: EVENT_MAP,
      classificationType: EVENT,
    },
  },
  {
    key: TAB_LABEL_CRM_CAMPAIGN,
    label: TAB_LABEL_CRM_CAMPAIGN,
    value: {
      dataSource: EVENT_CRM,
      classificationType: EVENT,
    },
  },
  {
    key: TAB_LABEL_CRM_EVENT,
    label: TAB_LABEL_CRM_EVENT,
    value: {
      dataSource: EVENT_CRM_EVENT,
      classificationType: EVENT,
    },
  },
  {
    key: TAB_LABEL_CRM_TASK,
    label: TAB_LABEL_CRM_TASK,
    value: {
      dataSource: EVENT_CRM_TASK,
      classificationType: EVENT,
    },
  },
];

export const PRODUCT_TAB_OPTIONS = [
  {
    key: TAB_LABEL_WEB,
    label: TAB_LABEL_WEB,
    value: {
      dataSource: PRODUCT_WEB,
      classificationType: PRODUCT,
    },
  },
  {
    key: TAB_LABEL_MAP,
    label: TAB_LABEL_MAP,
    value: {
      dataSource: PRODUCT_MAP,
      classificationType: PRODUCT,
    },
  },
  {
    key: TAB_LABEL_CRM,
    label: TAB_LABEL_CRM,
    value: {
      dataSource: PRODUCT_CRM,
      classificationType: PRODUCT,
    },
  },
];

export const ENTITY_TYPE = {
  RULE: 'rule',
  GROUP: 'group',
};

export const DEFAULT_RULE = {
  type: ENTITY_TYPE.RULE,
  column: null,
  operand: null,
  values: null,
  isCaseSensitive: false,
};

export const DEFAULT_GROUP = {
  type: ENTITY_TYPE.GROUP,
  relation: MODEL_AND,
  rules: [
    DEFAULT_RULE,
  ],
};

export const EVENT_WEB_COLUMNS = [
  { dataIndex: 'title', title: 'Page Title' },
  { dataIndex: 'url', title: 'Url' },
  { dataIndex: 'description', title: 'Page Description' },
  { dataIndex: 'keywords', title: 'On-Page Keywords' },
  { dataIndex: 'event_attributes_activity_type', title: 'Event Attribute: Activity Type' },
  { dataIndex: 'created_dt', title: 'Added On' },
];

export const EVENT_MAP_COLUMNS = [
  { dataIndex: 'activity_type', title: 'Activity' },
  { dataIndex: 'programs', title: 'Program Name' },
  { dataIndex: 'campaign_name', title: 'Derived Campaign Name' },
  { dataIndex: 'asset', title: 'Derived Primary Attribute Value' },
  { dataIndex: 'created_dt', title: 'Added On' },
];

export const EVENT_CRM_COLUMNS = [
  { dataIndex: 'campaign_type', title: 'Campaign Type' },
  { dataIndex: 'member_status', title: 'Member Status' },
  { dataIndex: 'campaign_name', title: 'Campaign Name' },
  { dataIndex: 'created_dt', title: 'Added On' },
];

export const EVENT_CRM_EVENT_COLUMNS = [
  { dataIndex: 'subject', title: 'Subject' },
  { dataIndex: 'location', title: 'Location' },
  { dataIndex: 'description', title: 'Description' },
  { dataIndex: 'type', title: 'type' },
  { dataIndex: 'created_dt', title: 'Added On' },
];

export const EVENT_CRM_TASK_COLUMNS = [
  { dataIndex: 'subject', title: 'Subject' },
  { dataIndex: 'description', title: 'Description' },
  { dataIndex: 'type', title: 'Type' },
  { dataIndex: 'created_dt', title: 'Added On' },
];


export const PRODUCT_WEB_COLUMNS = [
  { dataIndex: 'title', title: 'Page Title' },
  { dataIndex: 'url', title: 'Url' },
  { dataIndex: 'description', title: 'Page Description' },
  { dataIndex: 'keywords', title: 'On-Page Keywords' },
  { dataIndex: 'event_attributes_product', title: 'Event Attribute: Product' },
  { dataIndex: 'created_dt', title: 'Added On' },
];

export const PRODUCT_MAP_COLUMNS = [
  { dataIndex: 'campaign', title: 'Campaign Name' },
  { dataIndex: 'asset', title: 'Primary Attribute Value' },
  { dataIndex: 'activity_type', title: 'Activity Type' },
  { dataIndex: 'url', title: 'Webpage URL' },
  { dataIndex: 'created_dt', title: 'Added On' },
];

export const PRODUCT_CRM_COLUMNS = [
  { dataIndex: 'campaign_name', title: 'Campaign Name' },
  { dataIndex: 'campaign_description', title: 'Campaign Description' },
  { dataIndex: 'created_dt', title: 'Added On' },
];


export const EVENT_COLUMNS = {
  [EVENT_WEB]: EVENT_WEB_COLUMNS,
  [EVENT_MAP]: EVENT_MAP_COLUMNS,
  [EVENT_CRM]: EVENT_CRM_COLUMNS,
  [EVENT_CRM_EVENT]: EVENT_CRM_EVENT_COLUMNS,
  [EVENT_CRM_TASK]: EVENT_CRM_TASK_COLUMNS,
};

export const PRODUCT_COLUMNS = {
  [PRODUCT_WEB]: PRODUCT_WEB_COLUMNS,
  [PRODUCT_MAP]: PRODUCT_MAP_COLUMNS,
  [PRODUCT_CRM]: PRODUCT_CRM_COLUMNS,
};

export const COLUMNS = {
  [EVENT]: EVENT_COLUMNS,
  [PRODUCT]: PRODUCT_COLUMNS,
};
