import {
  AD_TYPE_FILTERS,
  BID_TYPES,
  CAMPAIGN_FIELDS_LABELS,
  CAMPAIGN_FIELDS,
} from "../../constants";

export const CAMPAIGNS_CONFIGURATION_ROUTE = "configuration";

export const PAGE_STATE_KEY = "campaignConfiguration";

export const CONFIGURATION_MODES = {
  create: "create",
  edit: "edit",
};

export const LOCATION_STATE_KEYS = {
  campaignName: "campaignName",
  linkedinPageId: "linkedinPageId",
  folderId: "folderId",
  segmentId: "segmentId",
};

export const CONFIGURATION_FORM_KEY = "campaignConfiguration";

// Ideally, we should use these values from options call, but since
// The UI and the csv store different values for display_name/ labels,
// we have to maintain this mapping on the FE. This would clearly mean
// that using values from options.cost_type.choices would only add more
// code and add less value since anyways we have to maintain the mapping
// on FE
export const BID_TYPE_OPTIONS = {
  [BID_TYPES.cpm]: { label: "CPM Bidding", value: "cpm" },
  [BID_TYPES.cpc]: { label: "CPC Bidding", value: "cpc" },
};

export const FORM_STEPS_LABELS = {
  campaignSetup: "Campaign Setup",
  adsSetup: "Ads Setup",
  retargetingCriteria: "Retargeting criteria",
  advancedTargeting: "Advanced Targeting",
  reviewAndLaunch: "Review and Launch",
  review: "Review",
};

export const FORM_FIELDS = CAMPAIGN_FIELDS;
export const FORM_FIELDS_LABELS = CAMPAIGN_FIELDS_LABELS;

export const BID_TYPE_BID_AMOUNT_LABEL_MAP = {
  [BID_TYPES.cpm]: FORM_FIELDS_LABELS.maxCPM,
  [BID_TYPES.cpc]: FORM_FIELDS_LABELS.maxCPC,
};

export const BID_TYPE_SUB_TEXT = {
  [BID_TYPES.cpm]: "per 1000 impressions",
  [BID_TYPES.cpc]: "per Landing Page Clicks",
};

export const WIZARD_FORM_IDS = {
  // `basic-setup`, `campaign-alerts` and `budget` are hardcoded in campaign alert email
  // before updating any "WIZARD_FORM_IDS" search whole ntropy for usages against "redirectStep" & "redirectField"
  BASIC_SETUP_STEP: "basic-setup",
  UPLOAD_ADS_STEP: "upload-ads",
  ADVANCED_TARGETING_STEP: "advanced-targeting",
  RETARGETING_CRITERIA_STEP: "retargeting-criteria",
  REVIEW_LAUNCH_STEP: "review-and-launch",

  SCHEDULE_FIELD: "schedule",
  FREQUENCY_FIELD: "frequency",
  CAMPAIGN_ALERTS: "campaign-alerts",
  BUDGET_FIELD: "budget",
  PROFILE_FIELD: "profile-field",
  CONTEXTUAL_TARGETING: "contextual-targeting",
  DEVICE_TARGETING: "device-targeting",
  INVENTORY_TYPE: "inventory-type",
  DOMAIN: "domain",
  COUNTRY: "country",
  LANGUAGE: "language",
  BRAND_SAFETY: "brand-safety",
  ADDITIONAL_DETAILS: "additional-details",
  OPTIMIZATION: "optimization",
};

export const BETA_JOB_FUNCTIONS_IDS = [];

export const DEFAULT_TARGETED_DEVICES = ["desktop", "mobile", "tablet"];
export const TARGETING_DEVICES_OPTIONS = [
  { label: "Desktop", value: "desktop" },
  { label: "Mobile", value: "mobile" },
  { label: "Tablet", value: "tablet" },
];

export const FORM_ERRORS = {
  SHOULD_HAVE_ATLEAST_ONE_DEVICE_TARGETED:
    "Please select atleast one device for targeting.",
  SHOULD_HAVE_MINIMUM_ONE_PLACEMENT: "Please upload atleast one ad",
  PLACEMENT_GROUP_MINIMUM_ONE_PLACEMENT:
    "Placement group should have atleast one placement",
};

export const CAMPAIGN_SETUP_ERRORS = {
  FLIGHT_SCHEDULE: {
    [FORM_FIELDS.flightStartDate]: {
      IN_PAST: "Start date cannot be in the past.",
    },
    [FORM_FIELDS.flightEndDate]: {
      IN_PAST: "End date cannot be in the past.",
      LESS_THAN_WEEK: "Flight period cannot be less than 1 week.",
      BEFORE_START_DATE: "End date cannot be before start date.",
      MAX_ALLOWED_FLIGHT: "Max allowed flight period is {period} {unit}",
    },
  },
};

export const DEVICE_ICON_MAP = {
  desktop: "desktop_windows",
  mobile: "smartphone",
  tablet: "tablet_mac",
};

export const AD_INVENTORY_TYPES = {
  appAndWeb: "app_and_web",
  webOnly: "web_only",
  appOnly: "app_only",
};

export const INVENTORY_TARGET_OPTIONS = [
  { label: "App & Web", value: AD_INVENTORY_TYPES.appAndWeb },
  { label: "Web only", value: AD_INVENTORY_TYPES.webOnly },
  { label: "App Only", value: AD_INVENTORY_TYPES.appOnly },
];

export const DEFAULT_TARGETED_INVENTORY = AD_INVENTORY_TYPES.appAndWeb;

export const SAMPLE_TEMPLATE_PUBLISHER_URLS =
  "/org/retrieve_s3object?filename=AdInventorySample.csv";

export const MAX_ALLOWED_URLS = 30000;

export const PUBLISHER_LIST_TYPE = {
  BLACKLIST: "blacklist",
  WHITELIST: "whitelist",
  PREMIUMLIST: "premium_inclusion",
};

export const PUBLISHER_LIST_TYPE_LABELS = {
  [PUBLISHER_LIST_TYPE.BLACKLIST]:
    "Apply Global Exclusion List + Campaign Exclusion List.",
  [PUBLISHER_LIST_TYPE.WHITELIST]: "Target specific domains for this campaign.",
  [PUBLISHER_LIST_TYPE.PREMIUMLIST]:
    "Target using the 6sense Preferred Publisher List for this campaign.",
};

export const FORM_ERROR_MSG = "Please fill all the details before submit";

export const CREATIVE_UPLOAD_KEY = "campaign_placement_creative_upload_key";
export const ICON_UPLOAD_KEY = "campaign_adplacement_icon_upload_key";

export const VIDEO_DIM = ["1280x720", ">1280x720"];

export const HTML5_SUPPORT_VIDEO = [
  "video/mp4",
  "video/webm",
  "video/mov",
  "video/quicktime",
];

export const SUPPORTED_REDIRECT_URL_MODES = {
  ALL: "all",
  PILOT: "pilot",
  NULL: null,
};
export const SYSTEM_SEGMENT = "system";

export const HOURS_IN_A_DAY = 24;

export const EMC_CREATION_TYPES = {
  MACROS: "macro_url",
  URL: "static_url",
};

export const DUMMY_PLACEMENT_FIELDS = {
  id: "id",
  placementName: "name",
  clickUrl: "url",
  trackingId: "external_id",
  impressionTracker: "trackers.impression_url",
  clickTracker: "trackers.click_url",
};

export const DUMMY_AD_GROUP_FIELDS = {
  id: "id",
  placementGroupName: "placement_group_name",
  placements: "placements",
};

export const DUMMY_ADS_FIELDS_TO_MONITOR = [
  DUMMY_PLACEMENT_FIELDS.placementName,
  DUMMY_PLACEMENT_FIELDS.clickUrl,
  DUMMY_PLACEMENT_FIELDS.trackingId,
];

export const TARGETABLE_ACCOUNT_LIMIT = 100000;

export const LEAVE_CREATE_CAMPAIGN = "All unsaved changes will be deleted.";

export const FIELD_VALIDITY = {
  valid: "valid",
  invalid: "invalid",
  requiredValid: "requiredValid",
  optionalValid: "optionalValid",
};

export const UPLOAD_CAMPAIGN_ADS_FORM = "UploadCampaignAdsForm";
export const EMC_PLACEMENT_FORM = "emcPlacementForm";

export const UPLOAD_CAMPAIGN_ADS_FORM_FIELDS = {
  stagedPlacements: "stagedPlacements",
  existingPlacementNamesFromServerCache:
    "existingPlacementNamesFromServerCache",
  forceShowErrors: "forceShowErrors",
};

export const UPLOAD_ADS_FORM_FIELDS_NAMES = {
  AD_NAME_FIELD_NAME: "name",
  AD_TYPE_FIELD_NAME: "ad_type",
  AD_SIZE_FIELD_NAME: "ad_size",
  CLICK_URL_FIELD_NAME: "url",
  DESCRIPTION_FIELD_NAME: "description",
  AD_TITLE_FIELD_NAME: "title",
  AD_BODY_FIELD_NAME: "body_text",
  AD_ACTION_FIELD_NAME: "call_to_action",
  AD_SPONSORED_BY_FIELD_NAME: "sponsor",
  ICON_FIELD_NAME: "icon",
  DUMMY_ICON_FIELD_NAME: "dummyIcon",
};

export const FILE_TYPE_LABEL_MAP = {
  [AD_TYPE_FILTERS.banner.value]: "Images",
  [AD_TYPE_FILTERS.video.value]: "Videos",
  [AD_TYPE_FILTERS.native.value]: "Images",
  [AD_TYPE_FILTERS.html5.value]: "HTML",
  [AD_TYPE_FILTERS.html5Dynamic.value]: "HTML",
};

export const FREQUENCY_CAPPING_LIMIT = 6;
export const SEGMENT_DISABLED_TOOLTIP = `Segments with less than 1 account and more than
  100k accounts cannot be used for campaign creation.`;

export const ALLOWED_ACCOUNT_COUNT_RANGE = {
  MIN: 1,
  MAX: 100000 + 1, // exclusive
};

export const LINKEDIN_DEFAULT_COUNTRY_TARGET = [];

export const VIEWABILITY_FIELD_DOJO_LINK =
  "https://support.6sense.com/hc/en-us/articles/360037628853#inventory-type-0-12";

export const LINKEDIN_JOB_TARGETING_OPERATOR = {
  OR: "or",
  AND: "and",
};

export const MAX_ALLOWED_FREQUENCY_CAP = 255;
