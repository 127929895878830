import { createSelector } from 'reselect';

export const jobsRootSelector = (state) => state.taxonomyV2.jobsRoot;
export const jobsListSelector = createSelector(
    jobsRootSelector,
    (jobsRoot) =>
        jobsRoot.jobsList
  );

export const jobsSelector = createSelector(
    jobsListSelector,
    (jobsList) => jobsList.jobs
  );

export const selectedTabSelector = createSelector(
    jobsListSelector,
    (jobsList) => jobsList.selectedTab
  );

export const queuedBySelector = createSelector(
    jobsListSelector,
    (jobsList) => jobsList.filters
  );

export const countsSelector = createSelector(
    jobsListSelector,
    (jobsList) => jobsList.counts
  );
