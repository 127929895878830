import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  pipelineIntelligenceEnabledSelector,
  userAppRolesSelector,
  multiAppsListSelector,
  commandbarMetadataSelector,
} from 'modules/user/selectors';
import { usePermissionCheck } from '@sixsense/rbac';
import { PIPELINE_INTELLIGENCE_PERMISSIONS } from '../../constants/featurePermissions';
import { AppProvider } from '@sixsense/core/shared/context/app-context';
import { forEach, upperCase } from 'lodash';
import FlagContext from 'contexts/FlagContext';
import { useFeatureFlagsCheck } from '@sixsense/core/featureFlags';
import { FEATURE_FLAGS } from 'constants/featureFlags';
import { init } from 'commandbar';

const AppProviderWrapperComponent = (props) => {

  const {
    pipelineIntelligenceEnabled,
    userAppRoles,
    multiAppsList,
    commandbarMetadata,
  } = props;

  const showPipelineIntelligence = usePermissionCheck(PIPELINE_INTELLIGENCE_PERMISSIONS)
  && pipelineIntelligenceEnabled;

  // ****Commandbar changes****

  const shouldCommandbarDisplay = useFeatureFlagsCheck(FEATURE_FLAGS.shouldCommandbarDisplay);

  useEffect(() => {
    if (shouldCommandbarDisplay) {
      try {
        init('0a783941', { nonce: window.__CSP_NONCE__ }); // eslint-disable-line no-undef
      } catch (e) {
        // Handle error silently
      }
      try {
        window.CommandBar.boot(commandbarMetadata.visitorID.toString());
        window.CommandBar.addMetadataBatch(commandbarMetadata);
      } catch (e) {
        // Handle error silently
      }
    }
  }, [shouldCommandbarDisplay, commandbarMetadata]);

  const enabledApps = React.useMemo(() => {
    const enabledAppsLocal = [];
    if (showPipelineIntelligence) {
      enabledAppsLocal.push('PI');
    }

    const mapAppIdToAppName = (appId) => {
      const app = (multiAppsList || []).find(({ id }) => id === appId);
      if (app) {
        return upperCase(app.name);
      }
      return null;
    };
    forEach(userAppRoles, ({ app_id }) => {
      const mapAppName = mapAppIdToAppName(app_id);
      if (mapAppName) {
        enabledAppsLocal.push(
        mapAppName
          );
      }
    });
    return enabledAppsLocal;

  }, [showPipelineIntelligence, userAppRoles, multiAppsList]);

  return (<AppProvider currentApp="ABM" enabledApps={enabledApps}>
    {props.children}
  </AppProvider>);

};

AppProviderWrapperComponent.propTypes = {
  pipelineIntelligenceEnabled: PropTypes.bool,
  userAppRoles: PropTypes.array,
  multiAppsList: PropTypes.array,
  commandbarMetadata: PropTypes.any,
  children: PropTypes.any,
};

const mapStateToProps = (state) => ({
  pipelineIntelligenceEnabled: pipelineIntelligenceEnabledSelector(state),
  userAppRoles: userAppRolesSelector(state),
  multiAppsList: multiAppsListSelector(state),
  commandbarMetadata: commandbarMetadataSelector(state),
});

AppProviderWrapperComponent.defaultProps = {
};

export default compose(
  FlagContext.FlagConsumer,
  connect(mapStateToProps, {}),
)(AppProviderWrapperComponent);
