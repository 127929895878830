import { call, all, put, select, takeLatest } from 'redux-saga/effects';
import { fetchSaga } from 'store/sagas';
import { orgSelector } from 'modules/user/selectors';
import {
    EVENT,
    PRODUCT,
    EVENT_WEB,
    EVENT_MAP,
    EVENT_CRM,
    EVENT_CRM_EVENT,
    EVENT_CRM_TASK,
    PRODUCT_CRM,
    PRODUCT_MAP,
     PRODUCT_WEB,
     DATA_SOURCE_ABBREVIATIONS,
} from '../constants';
import {
  getStartAndEndDateSelector,
  isSingleProductSelector,
  dateRangeSelector,
} from '../selectors';
import { actionTypes, actions } from '../modules/commonModules';
import { toaster } from '@sixsense/core';

const {
  loadStatusesFailure,
  loadStatusesSuccess,
  loadCountByMappingFailure,
  loadCountByMappingSuccess,
  publishMappingsFailure,
  publishMappingsSuccess,
  getPublishedMappingsFailure,
  getPublishedMappingsSuccess,
  loadRulesCountFailure,
  loadRulesCountSuccess,
} = actions;
const {
  LOAD_STATUSES_REQUEST,
  LOAD_COUNT_BY_MAPPING,
  PUBLISH_MAPPINGS_REQUEST,
  GET_PUBLISHED_MAPPINGS_REQUEST,
  LOAD_RULES_COUNT_REQUEST,
} = actionTypes;


function* overviewRequest(
  request,
  orgId,
  dataSource,
  classificationType = 'event',
  dateRanges) {
  return yield call(request,
        dateRanges ? `turbotax/taxonomy/org/${orgId}/` +
        `overview/statuses/${classificationType}/${dataSource}/` +
        `?start_date=${dateRanges.from}&end_date=${dateRanges.to}`:
        `turbotax/taxonomy/org/${orgId}/overview/statuses/${classificationType}/${dataSource}/`,
        'GET');
}
export function* loadOverviewStatusSaga(request, args) {
  try {
    const { dataSource } = args;
    const isSingleProduct = yield select(isSingleProductSelector);
    const dateRanges = yield select(getStartAndEndDateSelector);
    const dateRange = yield select(dateRangeSelector);
    const orgId = yield select(orgSelector);
    let EVENT_REQUESTS = [];
    let PRODUCT_REQUESTS = [];
    let EVENT_DATA_SOURCES = [];
    if (dataSource === 'all') {
      EVENT_DATA_SOURCES = [EVENT_WEB, EVENT_MAP, EVENT_CRM, EVENT_CRM_EVENT, EVENT_CRM_TASK];
      EVENT_REQUESTS = EVENT_DATA_SOURCES.map((ds) =>
          overviewRequest(request, orgId, ds, EVENT, dateRanges));

      if (!isSingleProduct) {
        PRODUCT_REQUESTS = [PRODUCT_WEB, PRODUCT_CRM, PRODUCT_MAP].map((ds) =>
          overviewRequest(request, orgId, ds, PRODUCT, dateRanges));
      }
    } else {
      // container level check present
      EVENT_DATA_SOURCES = [dataSource];
      EVENT_REQUESTS = [dataSource].map((ds) =>
        overviewRequest(request, orgId, ds, EVENT, dateRanges));
      if (!isSingleProduct && [PRODUCT_WEB, PRODUCT_CRM, PRODUCT_MAP].includes(dataSource)) {
        PRODUCT_REQUESTS = [dataSource].map((ds) =>
          overviewRequest(request, orgId, ds, PRODUCT, dateRanges));
      }
    }

    const allRequests = [...EVENT_REQUESTS, ...PRODUCT_REQUESTS];
    const responses = yield all(allRequests);
    const overviewData = [].concat(...responses);
    // store the data sources for which we fetched the data and the associated date range
    yield put(loadStatusesSuccess(
      overviewData,
      EVENT_DATA_SOURCES.map((ds) => ({ dataSource: ds, dateRange })))
    );
  } catch (error) {
    toaster.showError('error occured while loading statuses');
    yield put(loadStatusesFailure(error.errorMessage));
  }
}

export function* loadCountByMappingSaga(request, args) {
  try {
    const { dataSource } = args;
    const isSingleProduct = yield select(isSingleProductSelector);
    const dateRanges = yield select(getStartAndEndDateSelector);
    const orgId = yield select(orgSelector);
    const returnVal = {};
    const response1 = yield call(request,
      dateRanges ? `turbotax/taxonomy/org/${orgId}/` +
      `count_by_mapping/${EVENT}/${dataSource}/` +
      `?start_date=${dateRanges.from}&end_date=${dateRanges.to}` :
      `turbotax/taxonomy/org/${orgId}/count_by_mapping/${EVENT}/${dataSource}/`,
      'GET');
    returnVal[EVENT] = response1;
    if (!isSingleProduct && [PRODUCT_WEB, PRODUCT_CRM, PRODUCT_MAP].includes(dataSource)) {
      const response2 = yield call(request,
        dateRanges ? `turbotax/taxonomy/org/${orgId}/` +
        `count_by_mapping/${PRODUCT}/${dataSource}/` +
        `?start_date=${dateRanges.from}&end_date=${dateRanges.to}`:
        `turbotax/taxonomy/org/${orgId}/count_by_mapping/${PRODUCT}/${dataSource}/`,
        'GET');
      returnVal[PRODUCT] = response2;
    }
    yield put(loadCountByMappingSuccess(
      returnVal,
    ));
  } catch (error) {
    toaster.showError('error occured while loading count by mapping');
    yield put(loadCountByMappingFailure(error.errorMessage));
  }
}

function* getPublishMappingSaga(request) {
  const org = yield select(orgSelector);

  const publishStateEndpoint = `turbotax/taxonomy/org/${org}/turbotax_publish_mappings/`;

  try {
    const publishStateResponse = yield call(request, publishStateEndpoint, 'GET');

    // Get publish state
    const publishedState = {
      [EVENT_WEB]: { event: false, product: false },
      [EVENT_MAP]: { event: false, product: false },
      [EVENT_CRM]: { event: false, product: false },
      [EVENT_CRM_EVENT]: { event: false },
      [EVENT_CRM_TASK]: { event: false },
    };
    const result = Object.keys(publishedState).reduce((acc, key) => {
      const currObj = publishedState[key];
      acc[key] = Object.keys(currObj).reduce((acc1, key1) => {
        let publishedObj = publishStateResponse.find((v) =>
          v.data_source === key && v.classification_type === key1);
        if (!publishedObj) {
          publishedObj = { is_published: false };
        }
        // eslint-disable-next-line no-param-reassign
        acc1[key1] = publishedObj.is_published;
        return acc1;
      }, {});
      return acc;
    }, {});
    yield put(getPublishedMappingsSuccess(result));
  } catch (e) {
    toaster.showError('error occured while fetching published mappings');
    yield put(getPublishedMappingsFailure(e.errorMessage));
  }
}

function* publishMappingsSaga(request, args) {
  const { dataSource } = args;
  const org = yield select(orgSelector);
  const publishEndpoint = `turbotax/taxonomy/org/${org}/turbotax_publish_mappings/`;
  const submitEndpoint = `turbotax/taxonomy/org/${org}/turbotax/submit_mappings/`;
  try {
    const body = {
      data_source: dataSource,
      classification_type: EVENT,
      org_id: org,
      is_published: true,
    };
    const combinedResponse = { };
    const response1 = yield call(request, publishEndpoint, 'POST',
    { body: JSON.stringify(body) });
    combinedResponse[EVENT] = response1.is_published;

    if ([PRODUCT_CRM, PRODUCT_MAP, PRODUCT_WEB].includes(dataSource)) {
      body.classification_type = PRODUCT;
      const response2 = yield call(request, publishEndpoint, 'POST',
      { body: JSON.stringify(body) });
      combinedResponse[PRODUCT] = response2.is_published;
    }
    // const savedState = { id: res.id, value: res.is_published };
    // Hit submit endpoint incase we need to trigger the extractor.
    yield call(request, submitEndpoint, 'POST');
    yield put(publishMappingsSuccess(dataSource, combinedResponse));
    toaster.showSuccess(`${DATA_SOURCE_ABBREVIATIONS[dataSource]} mappings published successfully`);
  } catch (e) {
    toaster.showError(`error occured while 
      publishing ${DATA_SOURCE_ABBREVIATIONS[dataSource]} mappings`);
    toaster.showError('error occured while publishing mappings');
    yield put(publishMappingsFailure(e.errorMessage));
  }
}

function* loadRulesCountSaga(request) {
  try {
    const orgId = yield select(orgSelector);
    const response = yield call(request,
      `turbotax/taxonomy/org/${orgId}/` +
      'turbotax_rules/counts/',
      'GET');
    yield put(loadRulesCountSuccess(response));
  } catch (error) {
    toaster.showError('error occured while loading rules count');
    yield put(loadRulesCountFailure(error.errorMessage));
  }
}


function* watchLoadStatuses(request) {
  yield takeLatest([LOAD_STATUSES_REQUEST], loadOverviewStatusSaga, request);
}

function* watchLoadCountByMapping(request) {
  yield takeLatest([LOAD_COUNT_BY_MAPPING], loadCountByMappingSaga, request);
}

function* watchPublishMapping(request) {
  yield takeLatest([PUBLISH_MAPPINGS_REQUEST], publishMappingsSaga, request);
}

function* watchGetPublishMapping(request) {
  yield takeLatest([GET_PUBLISHED_MAPPINGS_REQUEST], getPublishMappingSaga, request);
}

function* watchLoadRulesCount(request) {
  yield takeLatest([LOAD_RULES_COUNT_REQUEST], loadRulesCountSaga, request);
}

export const commonSagas = [
  fetchSaga(watchLoadStatuses),
  fetchSaga(watchLoadCountByMapping),
  fetchSaga(watchPublishMapping),
  fetchSaga(watchGetPublishMapping),
  fetchSaga(watchLoadRulesCount),
];
