import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
  MarkerType,
} from 'reactflow';
import { css } from 'styles/emotion';
import { NODE_TYPES, nodeTypes, MAPPING_SUB_TYPES } from './nodes';
import Header from './Header';
import {
  dataSourcePresentInStatusSelector,
  countByMappingSelector,
  loadRulesCountDataSelector,
 } from '../../selectors';
import { loadRecordsReviewStatusCountGenerator } from '../../stateGenerators';
import { actions } from '../../modules/commonModules';
import { PRODUCT_CRM, PRODUCT_MAP, PRODUCT_WEB } from '../../constants';

import 'reactflow/dist/style.css';
import { isEmpty } from 'lodash';

const styles = {
  container: css({
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    minHeight: '95vh',
  }),
};


// const testChildNodesNotUsedInModels = [
//   {
//     title: 'Marketer',
//     activityCount: 2663,
//     percentage: '2.1%',
//     subType: 'no_map',
//   },
//   {
//     title: 'SFDC',
//     activityCount: 1293,
//     percentage: '1.1%',
//     subType: 'no_map',
//   },
//   {
//     title: 'Lead',
//     activityCount: 7472,
//     percentage: '7%',
//     subType: 'no_map',
//   },
//   {
//     title: 'Email',
//     activityCount: 314,
//     percentage: '0.01%',
//     subType: 'no_map',
//   },
// ];


const getInitialNodes = ({ integrationType, containerName, isMultiProduct, dataSource }) => {
  let nodes = [];
  if ([PRODUCT_CRM, PRODUCT_MAP, PRODUCT_WEB].includes(dataSource) && isMultiProduct) {
    nodes = [
      { id: '1',
        position: { x: 520, y: 0 },
        type: NODE_TYPES.IMAGE_NODE,
        data: { integrationType, containerName } },
      { id: '2a',
        position: { x: 131, y: 150 },
        type: NODE_TYPES.TOP_LEVEL_NODE,
        data: { taxonomyType: 'activity', dataSource },
      },
      { id: '2b',
        position: { x: 770, y: 150 },
        type: NODE_TYPES.TOP_LEVEL_NODE,
        data: { taxonomyType: 'product', dataSource },
      },
      {
        id: '3a',
        position: { x: 196, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: { type: MAPPING_SUB_TYPES.MAPPED, taxonomyType: 'activity', dataSource },
      },
      { id: '4a',
        position: { x: 0, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: { type: MAPPING_SUB_TYPES.UNMAPPED, taxonomyType: 'activity', dataSource },
      },
      { id: '5a',
        position: { x: 395, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: { type: MAPPING_SUB_TYPES.EXCLUDED, taxonomyType: 'activity', dataSource },
      },
      { id: '6a',
        position: { x: -100, y: 750 },
        type: NODE_TYPES.MAPPED_PARENT_NODE,
        data: { usedInModels: true, taxonomyType: 'event', dataSource },
      },
      { id: '7a',
        position: { x: 370, y: 750 },
        type: NODE_TYPES.MAPPED_PARENT_NODE,
        data: { usedInModels: false, taxonomyType: 'event', dataSource },
      },
      { id: 'annotationA', position: { x: 75, y: 670 }, type: NODE_TYPES.ANNOTATION_NODE },
      {
        id: '3b',
        position: { x: 835, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: { type: MAPPING_SUB_TYPES.MAPPED, taxonomyType: 'product', dataSource },
      },
      { id: '4b',
        position: { x: 620, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: { type: MAPPING_SUB_TYPES.UNMAPPED, taxonomyType: 'product', dataSource },
      },
      { id: '5b',
        position: { x: 1040, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: { type: MAPPING_SUB_TYPES.EXCLUDED, taxonomyType: 'product', dataSource },
      },
      { id: '6b',
        position: { x: 770, y: 750 },
        type: NODE_TYPES.MAPPED_PARENT_NODE,
        data: { usedInModels: false, taxonomyType: 'product', dataSource },
      },
    ];
  } else {
    nodes = [
      { id: '1',
        position: { x: 400, y: 0 },
        type: NODE_TYPES.IMAGE_NODE,
        data: { integrationType, containerName } },
      { id: '2',
        position: { x: 331, y: 150 },
        type: NODE_TYPES.TOP_LEVEL_NODE,
        data: { dataSource, taxonomyType: 'activity' },
      },
      {
        id: '3',
        position: { x: 396, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: { type: MAPPING_SUB_TYPES.MAPPED, taxonomyType: 'activity', dataSource },
      },
      { id: '4',
        position: { x: 172, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: { type: MAPPING_SUB_TYPES.UNMAPPED, taxonomyType: 'activity', dataSource },
      },
      { id: '5',
        position: { x: 620, y: 450 },
        type: NODE_TYPES.MAPPING_TYPE_NODE,
        data: { type: MAPPING_SUB_TYPES.EXCLUDED, taxonomyType: 'activity', dataSource },
      },
      { id: '6',
        position: { x: 150, y: 750 },
        type: NODE_TYPES.MAPPED_PARENT_NODE,
        data: { usedInModels: true, taxonomyType: 'event', dataSource },
      },
      { id: '7',
        position: { x: 550, y: 750 },
        type: NODE_TYPES.MAPPED_PARENT_NODE,
        data: { usedInModels: false, taxonomyType: 'event', dataSource },
      },
      { id: 'annotation', position: { x: 305, y: 670 }, type: NODE_TYPES.ANNOTATION_NODE },
    ];
  }
  return nodes;
};

const getInitialEdges = ({ isMultiProduct, dataSource }) => {
  let edges = [];
  if ([PRODUCT_CRM, PRODUCT_MAP, PRODUCT_WEB].includes(dataSource) && isMultiProduct) {
    edges = [
      { id: 'e1-2a', source: '1', target: '2a' },
      { id: 'e1-2b', source: '1', target: '2b' },
      { id: 'e2a-3a', source: '2a', target: '3a' },
      { id: 'e2a-4a', source: '2a', target: '4a' },
      { id: 'e2a-5a', source: '2a', target: '5a' },
      { id: 'e3a-6a', source: '3a', target: '6a' },
      { id: 'e3a-7a', source: '3a', target: '7a' },
      { id: 'e2b-3b', source: '2b', target: '3b' },
      { id: 'e2b-4b', source: '2b', target: '4b' },
      { id: 'e2b-5b', source: '2b', target: '5b' },
      { id: 'e3a-6b', source: '3b', target: '6b' },
    ];
  } else {
    edges = [
        { id: 'e1-2', source: '1', target: '2' },
        { id: 'e2-3', source: '2', target: '3' },
        { id: 'e2-4', source: '2', target: '4' },
        { id: 'e2-5', source: '2', target: '5' },
        { id: 'e3-6', source: '3', target: '6' },
        { id: 'e3-7', source: '3', target: '7' },
    ];
  }
  return edges;
};


const defaultEdgeOptions = {
  type: 'smoothstep',
  markerEnd: { type: MarkerType.ArrowClosed },
  pathOptions: { offset: 5 },
};

const proOptions = {
  account: 'paid-pro',
  hideAttribution: true,
};

function LayoutFlowComponent({
  integrationType='web',
  containerName='Website URL',
  isMultiProduct=true,
  isDataSourcePresentInStatus,
  loadStatuses,
  dataSource,
  loadRecordsReviewStatus,
  loadCountByMapping,
  countByMapping,
  loadRulesCount,
  loadRulesCountData,

}) {
  useEffect(() => {
    if (!isDataSourcePresentInStatus) {
      loadStatuses(dataSource);
      loadRecordsReviewStatus();
    }
    loadCountByMapping(dataSource);
    if (isEmpty(loadRulesCountData)) {
      loadRulesCount();
    }
  }, [dataSource]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  console.log({ countByMapping });

  useEffect(() => {
    const initialNodes = getInitialNodes({
      integrationType,
      containerName,
      isMultiProduct,
      dataSource });
    const initialEdges = getInitialEdges({ isMultiProduct, dataSource });
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [dataSource, integrationType, setNodes, setEdges]);

  return (
    <div className={styles.container}>
      <Header
        onDateRangeChange={() => {
          loadStatuses(dataSource);
          loadRecordsReviewStatus();
          loadCountByMapping(dataSource);
        }}
        dataSource={dataSource}
      />
      <ReactFlow
        nodes={nodes}
        edges={edges}
        fitView
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        proOptions={proOptions}
        defaultEdgeOptions={defaultEdgeOptions}
        nodesDraggable={false}
      >
        <Controls position="top-right" showInteractive={false} />
        <Background variant="dots" gap={12} size={1} />
      </ReactFlow>
    </div>
  );
}

LayoutFlowComponent.propTypes = {
  integrationType: PropTypes.string,
  containerName: PropTypes.string,
  isMultiProduct: PropTypes.bool,
  isDataSourcePresentInStatus: PropTypes.bool,
  loadStatuses: PropTypes.func,
  dataSource: PropTypes.string,
  loadRecordsReviewStatus: PropTypes.func,
  loadCountByMapping: PropTypes.func,
  countByMapping: PropTypes.object,
  loadRulesCount: PropTypes.func,
  loadRulesCountData: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => ({
  isDataSourcePresentInStatus: dataSourcePresentInStatusSelector(ownProps.dataSource)(state),
  countByMapping: countByMappingSelector(state),
  loadRulesCountData: loadRulesCountDataSelector(state),

});

export const LayoutFlow = compose(
  connect(mapStateToProps, {
    loadStatuses: actions.loadStatuses,
    loadRecordsReviewStatus: loadRecordsReviewStatusCountGenerator.loadAction,
    loadCountByMapping: actions.loadCountByMapping,
    loadRulesCount: actions.loadRulesCount,
  }),
)(LayoutFlowComponent);

export default (props) => (
  <ReactFlowProvider>
    <LayoutFlow {...props} />
  </ReactFlowProvider>
);
