import React from "react";
import { Dropdown, Flex } from "@sixsense/core/components";
import { get, isEmpty } from "lodash";
import styles from "./operationsDropdown.module.scss";
import { newParamsNaviate } from "utils/navigate";
import {
  CAMPAIGN_SOURCE,
  CAMPAIGN_STATES,
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_X,
  CAMPAIGNS_ROUTE,
} from "routes/AdvertisingDS/routes/Campaigns/constants";
import {
  campaignActionsCheck,
  getNeedActionCampaignIndicators,
} from "../../../utils";
import { connect } from "react-redux";
import { actions as routeAction } from "../../../modules";
import PropTypes from "prop-types";
import {
  htmlStrings,
  LINKEDIN_ADVERTISING_TOOLTIPS,
  LINKEDIN_PAGE_STATUS,
  LINKEDIN_POSTER_ACCESS_REJECTED,
  LINKEDIN_POSTER_ACCESS_REVOKED,
  SPONSORED_CONTENT_POSTER_STATUS,
} from "utils/constants";
import * as campaignCSVExportDrawerActions from "../../../containers/CampaignCSVExportDrawer/actions";
import { filterBySelector } from "../commonSelectors";
import { Permission, usePermissionCheck } from "@sixsense/rbac";
import {
  CAMPAIGNS_CONFIGURATION_ROUTE,
  CONFIGURATION_MODES,
} from "../../CampaignConfiguration/constants";
import { browserHistory } from "react-router";
import { If } from "babel-plugin-jsx-control-statements";
import {
  emptyObjectType,
  folderType,
} from "routes/AdvertisingDS/routes/Campaigns/propTypes";
import { ArchiveCampaignActionItem } from "routes/AdvertisingDS/routes/Campaigns/components/ArchiveCampaignActionItem";
import { campaignClassificationDucks } from "routes/AdvertisingDS/ducks";
import { CLASSIFICATION_TYPES } from "routes/AdvertisingDS/ducks/campaignClassifications/constants";
import {
  ADVERTISING_BASE_ROUTE,
  DROPDOWN_OPEN_TRIGGER,
} from "routes/AdvertisingDS/constants";
import { treeNodeSelectionsSelector } from "../../../../../selectors";
import {
  linkedInDisabledPagesSelector,
  linkedInPagesAccessRejectedSelector,
  linkedInPagesAccessRevokedSelector,
} from "../../../selectors";
import CampaignActionItem from "routes/AdvertisingDS/components/CampaignActionItem";
import {
  CheckCircle,
  Copy,
  DotsVertical,
  Download,
  Edit,
  Eye,
  Flash,
  Folder,
  LineChartUp,
  Power,
  Restore,
  Trash,
} from "@sixsense/core/icons";
import {
  ACTIVATE_CAMPAIGN_MESSAGE,
  MARK_AS_COMPLETE_MESSAGE,
} from "./constants";

const {
  constants: { CAMPAIGN_CLASSIFICATION_ACTIONS },
} = campaignClassificationDucks;

const OperationDropdownComponent = ({
  campaign,
  showDeactivationModal,
  showCampaignCloneModal,
  campaignStateActionPrompt,
  toggleDownloadDrawerVisibility,
  filterBy,
  setCampaignClassificationModalVisibility,
  setArchiveCampaignModalVisibility,
  folderList,
  archivedFolder,
  selectedFolders,
  linkedinAccessRevokedPageIds,
  linkedinAccessRejectedPageIds,
  linkedinDisabledPageIds,
}) => {
  const campaignSource = campaign.campaign_source;
  const campaignStatus = get(campaign, "campaign_status", null);
  const isDraftCampaign = campaignStatus === CAMPAIGN_STATUS_X.draft;
  const hasEditPermission = usePermissionCheck([Permission.CAMPAIGN_EDIT]);
  const hasArchivedFolder = !isEmpty(archivedFolder);
  const campaignAllowedActions = campaignActionsCheck(
    campaign,
    hasArchivedFolder
  );
  const isArchivedCampaign =
    get(campaign, "folder.classification_type") ===
    CLASSIFICATION_TYPES.CAMPAIGNS_ARCHIVED;
  const showMoveToOption = hasEditPermission && !isDraftCampaign;
  const showEditOption =
    hasEditPermission &&
    !isArchivedCampaign &&
    [
      CAMPAIGN_SOURCE.INTERNAL,
      CAMPAIGN_SOURCE.CONTEXTUAL,
      CAMPAIGN_SOURCE.RETARGETING,
      CAMPAIGN_SOURCE.EXTERNAL,
      CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING,
    ].includes(campaignSource) &&
    campaignStatus !== CAMPAIGN_STATUS.completed.key;
  const showViewConfigOption =
    !showEditOption &&
    !isArchivedCampaign &&
    [
      CAMPAIGN_SOURCE.INTERNAL,
      CAMPAIGN_SOURCE.CONTEXTUAL,
      CAMPAIGN_SOURCE.RETARGETING,
      CAMPAIGN_SOURCE.EXTERNAL,
    ].includes(campaignSource);
  const showViewOption = hasEditPermission ? !isDraftCampaign : true;
  const showDownloadOption = !isDraftCampaign;
  const needActionIndicators = getNeedActionCampaignIndicators(campaign)
    .map(({ message, type, tooltip }) => ({ message, type, tooltip }))
    .filter((m) => !isEmpty(m));

  let linkedinPageStatus = "";
  if (campaignSource === CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING) {
    if (linkedinAccessRevokedPageIds.includes(campaign.linkedin_ads_page_id)) {
      needActionIndicators.push({ message: LINKEDIN_POSTER_ACCESS_REVOKED });
      linkedinPageStatus = SPONSORED_CONTENT_POSTER_STATUS.REVOKED;
    }
    if (linkedinAccessRejectedPageIds.includes(campaign.linkedin_ads_page_id)) {
      needActionIndicators.push({ message: LINKEDIN_POSTER_ACCESS_REJECTED });
      linkedinPageStatus = SPONSORED_CONTENT_POSTER_STATUS.REJECTED;
    }
    if (linkedinDisabledPageIds.includes(campaign.linkedin_ads_page_id)) {
      linkedinPageStatus = LINKEDIN_PAGE_STATUS.DISABLED;
    }
  }

  // TODO: Rename CAMPAIGN_STATUS_X
  const isArchiveDisabled =
    campaignStatus === CAMPAIGN_STATUS_X.active ||
    campaignStatus === CAMPAIGN_STATUS_X.scheduled;
  const moveOrRestoreAction = isArchivedCampaign
    ? CAMPAIGN_CLASSIFICATION_ACTIONS.restore
    : CAMPAIGN_CLASSIFICATION_ACTIONS.move;

  // TODO: onExportAsCsv is currently duplicated here and in CampaignToolbar, need to de-duplicate
  const onExportAsCsv = () => {
    const additionalQueryParams = {
      ...filterBy,
      campaign_state: CAMPAIGN_STATES.submitted,
      classification_id: get(selectedFolders, "[0].id"),
    };

    toggleDownloadDrawerVisibility(
      true,
      [campaign.id],
      additionalQueryParams,
      1
    );
  };

  return (
    <Flex alignItems="center" className={styles.pointer}>
      <Dropdown
        trigger={DROPDOWN_OPEN_TRIGGER}
        menu={
          <Dropdown.Menu>
            {showViewOption && (
              <Dropdown.Menu.Item
                onClick={() =>
                  newParamsNaviate(
                    `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/view/${campaign.id}/`
                  )
                }
                before={<LineChartUp />}
                data-6si-id="campaign_list-action_dropdown-view_performance"
                data-testid="campaign_list-action_dropdown-view_performance"
              >
                View Performance
              </Dropdown.Menu.Item>
            )}
            {showEditOption &&
              CampaignActionItem({
                isDisabled: !isDraftCampaign && linkedinPageStatus !== "",
                tooltipText: LINKEDIN_ADVERTISING_TOOLTIPS[linkedinPageStatus],
                onClick: () =>
                  browserHistory.push(
                    // eslint-disable-next-line max-len
                    `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${CAMPAIGNS_CONFIGURATION_ROUTE}/${CONFIGURATION_MODES.edit}/${campaign.id}`
                  ),
                label: "Edit",
                id: "Edit",
                dataPendo: "edit",
                icon: <Edit />,
              })}
            <If condition={showViewConfigOption}>
              <Dropdown.Menu.Item
                id="view-campaign-config"
                key="view-campaign-config"
                onClick={() =>
                  browserHistory.push(
                    // eslint-disable-next-line max-len
                    `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${CAMPAIGNS_CONFIGURATION_ROUTE}/${CONFIGURATION_MODES.edit}/${campaign.id}`
                  )
                }
                before={<Eye />}
                data-6si-id="campaign_list-action_dropdown-view-configuration"
                data-testid="campaign_list-action_dropdown-view-configuration"
              >
                View Configuration
              </Dropdown.Menu.Item>
            </If>
            {showMoveToOption && (
              <Dropdown.Menu.Item
                id="move-campaign"
                key="move-campaign"
                onClick={() =>
                  setCampaignClassificationModalVisibility(
                    true,
                    [campaign.id],
                    moveOrRestoreAction
                  )
                }
                data-6si-id={`campaign_list-action_dropdown-${moveOrRestoreAction}`}
                data-testid={`campaign_list-action_dropdown-${moveOrRestoreAction}`}
                before={isArchivedCampaign ? <Restore /> : <Folder />}
              >
                {isArchivedCampaign ? "Restore" : "Move"}
              </Dropdown.Menu.Item>
            )}
            {campaignAllowedActions.canDeactivate && (
              <Dropdown.Menu.Item
                id="deactivate-campaign"
                key="deactivate-campaign"
                onClick={() => showDeactivationModal(campaign)}
                before={<Power />}
                data-6si-id="campaign_list-action_dropdown-deactivate"
                data-testid="campaign_list-action_dropdown-deactivate"
              >
                Deactivate
              </Dropdown.Menu.Item>
            )}
            {campaignAllowedActions.canActivate &&
              CampaignActionItem({
                isDisabled: !isDraftCampaign && linkedinPageStatus !== "",
                tooltipText: LINKEDIN_ADVERTISING_TOOLTIPS[linkedinPageStatus],
                onClick: () =>
                  campaignStateActionPrompt(
                    true,
                    campaign,
                    "activate",
                    `${ACTIVATE_CAMPAIGN_MESSAGE}: ${get(
                      campaign,
                      "campaign_name",
                      ""
                    )}?`
                  ),
                label: "Activate",
                id: "activate",
                dataPendo: "activate",
                icon: <Flash />,
              })}
            {campaignAllowedActions.canDelete && (
              <Dropdown.Menu.Item
                id="delete-campaign"
                key="delete-campaign"
                data-6si-id="campaign_list-action_dropdown-delete"
                data-testid="campaign_list-action_dropdown-delete"
                onClick={() => {
                  if (campaignStatus === CAMPAIGN_STATUS_X.draft) {
                    campaignStateActionPrompt(
                      true,
                      campaign,
                      "delete",
                      htmlStrings.DELETE_WARNING_MESSAGE
                    );
                  }
                }}
                before={<Trash />}
              >
                Delete
              </Dropdown.Menu.Item>
            )}
            {campaignAllowedActions.canEnd && (
              <Dropdown.Menu.Item
                id="end-campaign"
                key="end-campaign"
                onClick={() =>
                  campaignStateActionPrompt(
                    true,
                    campaign,
                    "end",
                    `${MARK_AS_COMPLETE_MESSAGE}: ${get(
                      campaign,
                      "campaign_name",
                      ""
                    )}?`
                  )
                }
                before={<CheckCircle />}
              >
                Mark as Complete
              </Dropdown.Menu.Item>
            )}
            {campaignAllowedActions.canClone &&
              CampaignActionItem({
                isDisabled: !isDraftCampaign && linkedinPageStatus !== "",
                tooltipText: LINKEDIN_ADVERTISING_TOOLTIPS[linkedinPageStatus],
                onClick: () => showCampaignCloneModal(campaign, folderList),
                label: "Clone",
                id: "Clone",
                dataPendo: "clone",
                icon: <Copy />,
              })}
            {showDownloadOption && (
              <Dropdown.Menu.Item
                id="download-campaign"
                data-6si-id="campaign_list-action_dropdown-download"
                data-test="campaign_list-action_dropdown-download"
                key="download-campaign"
                onClick={onExportAsCsv}
                before={<Download />}
              >
                Download
              </Dropdown.Menu.Item>
            )}
            {campaignAllowedActions.canArchive &&
              !isDraftCampaign &&
              ArchiveCampaignActionItem({
                isArchiveDisabled,
                onClick: () =>
                  setArchiveCampaignModalVisibility(true, [campaign.id]),
              })}
          </Dropdown.Menu>
        }
      >
        <div
          data-6si-id="campaign_list-action_dropdown"
          data-testid="campaign_list-action_dropdown"
        >
          <DotsVertical />
        </div>
      </Dropdown>
    </Flex>
  );
};
OperationDropdownComponent.propTypes = {
  campaign: PropTypes.object.isRequired,
  showDeactivationModal: PropTypes.func.isRequired,
  showCampaignCloneModal: PropTypes.func.isRequired,
  campaignStateActionPrompt: PropTypes.func.isRequired,
  toggleDownloadDrawerVisibility: PropTypes.func.isRequired,
  setCampaignClassificationModalVisibility: PropTypes.func.isRequired,
  filterBy: PropTypes.object.isRequired,
  folderList: PropTypes.arrayOf(folderType).isRequired,
  selectedFolders: PropTypes.array.isRequired,
  setArchiveCampaignModalVisibility: PropTypes.func.isRequired,
  archivedFolder: PropTypes.oneOfType([folderType, emptyObjectType]).isRequired,
  linkedinAccessRejectedPageIds: PropTypes.array,
  linkedinAccessRevokedPageIds: PropTypes.array,
  linkedinDisabledPageIds: PropTypes.array,
};
const mapStateToProps = (state) => ({
  filterBy: filterBySelector(state),
  folderList:
    campaignClassificationDucks.selectors.classificationChoicesSelector(state),
  selectedFolders: treeNodeSelectionsSelector(state),
  archivedFolder:
    campaignClassificationDucks.selectors.archivedFolderSelector(state),
  // TODO: right now only the delete action takes place using this generator. Hence the name.
  // this would be generalized in the near future to handle other updates
  linkedinAccessRevokedPageIds: linkedInPagesAccessRevokedSelector(state),
  linkedinAccessRejectedPageIds: linkedInPagesAccessRejectedSelector(state),
  linkedinDisabledPageIds: linkedInDisabledPagesSelector(state),
});
const dispatchToProps = {
  showDeactivationModal: routeAction.showDeactivationModal,
  showCampaignCloneModal: routeAction.showCampaignCloneModal,
  campaignStateActionPrompt: routeAction.campaignStateActionPrompt,
  toggleDownloadDrawerVisibility:
    campaignCSVExportDrawerActions.toggleVisibility,
  setCampaignClassificationModalVisibility:
    campaignClassificationDucks.actionCreators
      .setClassificationDrawerVisibility,
  setArchiveCampaignModalVisibility:
    routeAction.setArchiveCampaignModalVisibility,
};
const OperationDropdown = connect(
  mapStateToProps,
  dispatchToProps
)(OperationDropdownComponent);

export const OPERATIONS_DROPDOWN = {
  fixed: "right",
  customizable: false,
  key: "row_action",
  title: "",
  csvOverrides: {
    hide: true,
  },
  dataIndex: "id",
  width: 50,
  render: (campaignName, campaign) => (
    <OperationDropdown campaignName={campaignName} campaign={campaign} />
  ),
};
