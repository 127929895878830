import React from 'react';
import styles from './ToggleSwitch.module.scss';
import PropTypes from 'prop-types';
import { Row, Text, Loading } from 'v2-components';
import { classNames } from 'utils/utils';

/**
* Toggle Switch Component
*
* @param {string, array or react element} children - content of Modal
* @param {string} className - Apply styles to parent container
* @param {func} onClick - to invoke when clicking toggle switch
* @param {bool} disabled - Disable the toogle switch
* @param {bool} selected - Whether to toggle to active state
* @param {bool} loading - Whether to show loader on switch
*/

const ToggleSwitch = (props) => {
  const { children, selected, onClick, disabled, className, loading } = props;
  return (
    <Row
      className={classNames(styles.container, (disabled || loading) && styles.disabled, className)}
      onClick={(e) => !(disabled || loading) && onClick(e)}
      alignItems={'center'}
    >
      <div
        className={`
          ${styles.circleOuter}
          ${selected && styles.selected}
          ${(disabled || loading) && styles.disabledOuterCircle}
        `}
      >
        {!selected &&
          <div
            className={classNames(styles.circleInner,
              (disabled || loading) && styles.disabledCircle)}
          >
            {loading && (
              <Loading
                className={styles.loader}
                color={'white'}
                spinnerHeight={20}
                spinnerWidth={20}
              />
            )}
          </div>
        }
        <Text
          color={'white'}
          className={classNames(styles.textStyle,
            disabled && styles.disabledCircle)}
        >
          {children}
        </Text>
        {selected &&
          <div
            className={classNames(styles.circleInner,
              (disabled || loading) && styles.disabledCircle)}
          >
            {loading && (
              <Loading
                className={styles.loader}
                color={'white'}
                spinnerHeight={20}
                spinnerWidth={20}
              />
            )}
          </div>
        }
      </div>

    </Row>
  );
};

ToggleSwitch.defaultProps = {
  onClick: () => ({}),
};

ToggleSwitch.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default ToggleSwitch;
