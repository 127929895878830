import React from 'react';
import sfdcLogo from 'images/IntegrationLogos/salesforce-old-logo.svg';
import eloquaLogo from 'images/IntegrationLogos/oracle-corporation-logo.svg';
import pardotLogo from 'images/IntegrationLogos/pardot-logo.svg';
import slackLogo from 'images/IntegrationLogos/slack-logo.svg';
import hubspotLogo from 'images/IntegrationLogos/hubspot-logo.svg';
import defaultLogo from 'images/comet-o.svg';
import msLogo from 'images/IntegrationLogos/microsoft-office-logo.svg';
import googleLogo from 'images/IntegrationLogos/google-logo-v2.svg';
import marketoLogo from 'images/IntegrationLogos/adobe-logo.svg';
import dynamicsLogo from 'images/IntegrationLogos/microsoft-logo.svg';
import salesloftLogo from 'images/IntegrationLogos/salesloft-logo.svg';
import linkedInLogo from 'images/IntegrationLogos/linkedin-logo.svg';
import metaLogo from 'images/IntegrationLogos/meta-logo2.svg';
import g2Logo from 'images/IntegrationLogos/g2-logo.svg';
import trustradiusLogo from 'images/IntegrationLogos/trustradius-logo.svg';
import outreachLogo from 'images/IntegrationLogos/outreach-logo.svg';
import bomboraLogo from 'images/IntegrationLogos/bombora-logo.svg';
import apikeyLogo from 'images/IntegrationLogos/apikey-logo.svg';
import webtagLogo from 'images/IntegrationLogos/webtag-logo.svg';
import googleAdsIcon from 'images/google_advertising_icon.svg';
import SaleswhaleLogo from 'images/IntegrationLogos/6sense.svg';
import SnowflakeLogo from 'images/IntegrationLogos/snowflake-logo.svg';
import s3Logo from 'images/IntegrationLogos/s3-logo.svg';
import PeerspotLogo from 'images/IntegrationLogos/peerspot-logo.svg';
import GartnerLogo from 'images/IntegrationLogos/gartner-logo.svg';
import { LINKEDIN_ADVERTISING_STATE_KEY } from 'utils/constants';
import { Link } from 'v2-components';
import TechTargetLogo from 'images/IntegrationLogos/techtarget-logo.svg';
import { WorkflowsDefaultRole } from '@sixsense/rbac';
import gongLogo from 'images/IntegrationLogos/gong-logo.svg';

export const imageMap = {
  default: defaultLogo,
  salesforce: sfdcLogo,
  eloqua: eloquaLogo,
  pardot: pardotLogo,
  slack: slackLogo,
  hubspot: hubspotLogo,
  microsoft: msLogo,
  google: googleLogo,
  marketo: marketoLogo,
  hubspotcrm: hubspotLogo,
  dynamics: dynamicsLogo,
  salesloft: salesloftLogo,
  outreach: outreachLogo,
  linkedin_segments: linkedInLogo,
  [LINKEDIN_ADVERTISING_STATE_KEY]: linkedInLogo,
  facebook: metaLogo,
  G2: g2Logo,
  trustradius: trustradiusLogo,
  salesforce_sandbox: sfdcLogo,
  bombora: bomboraLogo,
  apikey: apikeyLogo,
  webtag: webtagLogo,
  googleAds: googleAdsIcon,
  hvs: sfdcLogo,
  saleswhale: SaleswhaleLogo,
  snowflake: SnowflakeLogo,
  s3: s3Logo,
  PeerSpot: PeerspotLogo,
  buyer_discovery: GartnerLogo,
  hubspotcrm_sandbox: hubspotLogo,
  gong: gongLogo,
  TechTarget: TechTargetLogo,
};

export const STATE_KEYS = {
  g2: 'G2',
  trustradius: 'trustradius',
  microsoft: 'microsoft',
  google: 'google',
  salesloft: 'salesloft',
  outreach: 'outreach',
  slack: 'slack',
  linkedinSegments: 'linkedin_segments',
  linkedinAdvertising: LINKEDIN_ADVERTISING_STATE_KEY,
  facebook: 'facebook',
  salesforce_sandbox: 'salesforce_sandbox',
  bombora: 'bombora',
  apikey: 'apikey',
  webtag: 'webtag',
  googleAds: 'googleAds',
  hvs: 'hvs',
  saleswhale: 'saleswhale',
  snowflake: 'snowflake',
  s3: 's3',
  PeerSpot: 'PeerSpot',
  buyer_discovery: 'buyer_discovery',
  hubspotcrm_sandbox: 'hubspotcrm_sandbox',
  gong: 'Gong',
  TechTarget: 'TechTarget',
};

// sync_source_type_id
export const integrationNameToIDMap =
  {
    marketo: 1,
    salesforce: 2,
    eloqua: 3,
    files: 5,
    beacon: 6,
    'site catalyst': 7,
    pardot: 8,
    hubspot: 9,
    dynamics: 10,
    trustradius: 11,
    hubspotcrm: 14,
    microsoft: 15,
    G2: 16,
    google: 17,
    salesloft: 18,
    outreach: 19,
    slack: 20,
    linkedin_segments: 21,
    facebook: 22,
    bombora: 23,
    apikey: 24,
    webtag: 25,
    hvs: 26,
    googleAds: 28,
    saleswhale: 29,
    linkedin_advertising: 30,
    snowflake: 31,
    s3: 34,
    PeerSpot: 35,
    buyer_discovery: 36,
    // TODO need to update
    gong: 43,
    TechTarget: 42,
  };

export const idToIntegrationNameMap =
  {
    1: 'marketo',
    2: 'salesforce',
    3: 'eloqua',
    5: 'files',
    6: 'beacon',
    7: 'site catalyst',
    8: 'pardot',
    9: 'hubspot',
    10: 'dynamics',
    11: 'trustradius',
    14: 'hubspotcrm',
    15: 'microsoft',
    16: 'G2',
    17: 'google',
    18: 'salesloft',
    19: 'outreach',
    20: 'slack',
    21: 'linkedin_segments',
    22: 'facebook',
    23: 'bombora',
    24: 'apikey',
    25: 'webtag',
    26: 'hvs',
    28: 'googleAds',
    29: 'saleswhale',
    30: 'linkedin_advertising',
    31: 'snowflake',
    34: 's3',
    35: 'PeerSpot',
    36: 'buyer_discovery',
    // TODO need to update
    43: 'gong',
    42: 'TechTarget',
  };

export const categoryNameToID = {
  advertising: 6,
  b2b_software_review: 13,
  b2b_software_review_dia: 15,
  chatbot_activites: 14,
  conversational_email: 10,
  conversational_intelligence: 18,
  crm: 1,
  data_warehouse: 12,
  mail_server: 5,
  map: 2,
  messaging: 8,
  object_storage: 16,
  partner: 4,
  sep: 7,
  web: 3,
  web_and_api_key: 9,
};
// add the types that too far to reference here
export const MICROSOFT = 'microsoft';
export const GOOGLE = 'google';
export const SALESLOFT = 'salesloft';
export const OUTREACH = 'outreach';
export const HVS = 'hvs';
export const SALESWHALE = 'saleswhale';

export const ACCESS_DENIED_MSG =
  'Access denied. Please contact your admin for access to view this page.';

export const ALREADY_EXISTS = (integrationName) =>
  `Another ${integrationName} has already been connected and at a time, only 1 can be connected. 
If you want to reset an existing integration, please reach out to 6sense support for assistance.`;

export const SANDBOX_DISABLED =
  'Sandbox is disabled for this organization. Please reach out to 6sense support for assistance';

export const CRM_DISABLED_DUE_TO_SANDBOX =
  'The integration is disabled since this is a sandbox organization';

export const REDUX_STATE_KEY = 'integration-manage';

export const STATUS_CODES = {
  ERROR: 0,
  SUCCESS: 1,
  NOT_CONFIGURED: -1,
  INACTIVE: -2,
  PENDING: -3,
  ENABLED: 2,
};

export const SIAPP_PLAN_TYPE = {
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
};

export const ABM_ROLES_ID = {
  PRIMARY_ADMINISTRATOR: 1,
  ADMINISTRATOR: 2,
  OPERATION_USER: 3,
};

export const WF_ROLES_ID = {
  PRIMARY_ADMINISTRATOR: WorkflowsDefaultRole.PRIMARY_ADMINISTRATOR,
  WORKFLOW_ADMINISTRATOR: WorkflowsDefaultRole.ADMINISTRATOR,
  REVOPS_USER: WorkflowsDefaultRole.REVOPS_USER,
  VIEW_ONLY_USER: WorkflowsDefaultRole.VIEW_ONLY_USER,
};

export const CE_ROLES_ID = {
  PRIMARY_ADMINISTRATOR: 13,
  CE_ADMINISTRATOR: 14,
};

export const SI_ROLES_ID = {
  // delete 9, 10 after QA signoff
  SALES_PRIMARY_ADMIN: 9,
  SALES_ADMIN: 10,

  A_PRIMARY_ADMINISTRATOR: 22,

  B_PRIMARY_ADMINISTRATOR: 23,

  C_PRIMARY_ADMINISTRATOR: 24,
  C_ADMINISTRATOR: 25,
  C_SDR: 26,
  C_AE: 27,

  D_PRIMARY_ADMINISTRATOR: 28,
  D_ADMINISTRATOR: 29,
  D_SDR: 30,
  D_AE: 31,

  E_FULL_PRIMARY_ADMINISTRATOR: 32,
  E_FULL_ADMINISTRATOR: 33,
  E_FULL_SDR: 34,
  E_FULL_AE: 35,

  E_LITE_PRIMARY_ADMINISTRATOR: 36,
  E_LITE_ADMINISTRATOR: 37,
  E_LITE_SDR: 38,
  E_LITE_AE: 39,
};

export const GENRIC_INTEGRATION_STATUS = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  ERROR: 'Error',
  ACTIVE: 'Active',
  SYNC_DISABLED: 'Sync Disabled',
  ENABLED: 'Enabled',
};

 // to be removed once advertising integrations apis can be called internally
export const GENERIC_STATUS_CODES = {
  0: 'ERROR',
  1: 'ACTIVE',
  '-3': 'IN_PROGRESS',
  '-1': 'NOT_STARTED',
  2: 'ENABLED',
};

export const GOOGLE_INTEGRATION_DISABLED = (
  <React.Fragment>
  This feature is available as an add-on to your
  subscription. Please get in touch with your respective CSM to activate.&nbsp;
  <Link
    newWindow
    /* eslint-disable max-len */
    link="https://support.6sense.com/knowledge-base/8899607551763-6sense-segments-for-google-ads-beta/"
  >
  Click here
  </Link>
  &nbsp;for more details.
  </React.Fragment>
);
