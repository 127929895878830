import { Permission } from '@sixsense/rbac';
import googleAdsLogo from 'images/google-ads-logo.png';
import linkedinAdsLogo from 'images/linkedin-ads-logo.png';
import facebookAdsLogo from 'images/facebook-business-manager.svg';
import PreRequisitesModal from './routes/FacebookAdsIntegration/components/PreRequisitesModal';
import { AD_NETWORKS, AD_NETWORK_LABELS } from 'constants/campaigns';


export const SOCIAL_SYNC_REDUX_STATE_KEY = 'socialSync';

export const REDIRECT_URI = `${window.location.protocol}//${window.location.host.split('.').slice(1)
  .join('.')}/login`;

export const SOCIAL_AD_NETWORK_LOGO = {
  [AD_NETWORKS.google]: googleAdsLogo,
  [AD_NETWORKS.linkedin]: linkedinAdsLogo,
  [AD_NETWORKS.facebook]: facebookAdsLogo,
};

export const SOCIAL_ADDITIONAL_RESOURCES = {
  [AD_NETWORKS.google]: [
    {
      text: 'How to connect Google account?',
      /* eslint-disable max-len */
      link: 'https://support.6sense.com/knowledge-base/8899607551763-6sense-segments-for-google-ads-beta/',
    },
    {
      text: 'FAQs: 6sense Segments for Google Ads',
      link: 'https://support.6sense.com/hc/en-us/articles/8472281573139',
    },
  ],
  [AD_NETWORKS.linkedin]: [
    {
      text: '6sense Segments for LinkedIn Ads',
      link: 'https://support.6sense.com/hc/en-us/articles/360046590094',
    },
    {
      text: 'FAQs: 6sense Segments for LinkedIn Ads',
      link: 'https://support.6sense.com/hc/en-us/articles/360044773854-FAQ-LinkedIn-Push',
    },
  ],
  [AD_NETWORKS.facebook]: [
    {
      text: 'FAQs: 6sense Segments for Meta',
      // eslint-disable-next-line max-len
      link: 'https://support.6sense.com/hc/en-us/articles/1500001473522-FAQ-6sense-Segments-for-Facebook-Ads',
    },
    {
      text: 'Prerequisites Steps',
      onClick: () => PreRequisitesModal(),
    },
  ],
};

export const SOCIAL_ADS_PERMISSION = {
  [AD_NETWORKS.google]: Permission.GOOGLE_ADS_INTEGRATION_CONSENT_EDIT,
  [AD_NETWORKS.facebook]: Permission.FACEBOOK_INTEGRATION_CONSENT_EDIT,
};

export const AD_ACCOUNT_FIELDS = {
  NAME: 'name',
  STATUS: 'account_status',
  CAN_SYNC_SEGMENT: 'can_sync_segment',
  ACCOUNT_ID: 'account_id',
  ACTIVE_SEGMENT_SYNC: 'active_segments_synced',
  ACTIVE_USER_SEGMENT_SYNC: 'active_user_segments_synced',
};

export const SOCIAL_AD_CONSENT_KEY = {
  [AD_NETWORKS.google]: 'google_consent',
  [AD_NETWORKS.linkedin]: 'linkedin_consent',
  [AD_NETWORKS.facebook]: 'facebook_consent',
};

export const SOCIAL_WINDOW_MESSAGING_TYPE = {
  [AD_NETWORKS.google]: 'google-message',
  [AD_NETWORKS.linkedin]: 'linkedin-message',
  [AD_NETWORKS.facebook]: 'facebook-message',
};

export const FACEBOOK_REDIRECT_URI = `${window.location.protocol}//${window.location.host.split('.')
  .slice(1).join('.')}/settings/integration/facebook-business-manager/redirect`;

export const SOCIAL_REDIRECT_URI = {
  [AD_NETWORKS.google]: REDIRECT_URI,
  [AD_NETWORKS.linkedin]: REDIRECT_URI,
  [AD_NETWORKS.facebook]: FACEBOOK_REDIRECT_URI,
};

export const ACCOUNT_ACCESS_DENIED_TEXT = 'You don\'t have access to this account';

export const SOCIAL_OPT_IN_RESOURCE_LINKS = {
  [AD_NETWORKS.google]: {
    /* eslint-disable max-len */
    howTo: 'https://support.6sense.com/knowledge-base/8899607551763-6sense-segments-for-google-ads-beta/',
    faq: 'https://support.6sense.com/hc/en-us/articles/8472281573139',
  },
  [AD_NETWORKS.facebook]: {
    howTo: 'https://support.6sense.com/hc/en-us/articles/360059994674',
    faq: 'https://support.6sense.com/hc/en-us/articles/1500001473522',
  },
};

export const INTEGRATION_OPT_IN_CONSENT_TEXT = {
  [AD_NETWORKS.google]:
    'I agree to allow 6sense to integrate with our Google Ad accounts',
};

export const SOCIAL_AD_NETWORK_AD_ACCOUNT_TABLE_TITLE = {
  [AD_NETWORKS.google]: 'Google',
  [AD_NETWORKS.linkedin]:
    AD_NETWORK_LABELS[AD_NETWORKS.linkedin],
  [AD_NETWORKS.facebook]:
    AD_NETWORK_LABELS[AD_NETWORKS.facebook],
};

export const ERROR_CODE_FOR_ACCESS_ABOUT_TO_EXPIRE = 'INT0002';
