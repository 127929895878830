import {
  PASSWORD_PLACEHOLDER, MARKETO, SALESFORCE, DYNAMICS, PARDOT, HUBSPOT_CRM, HUBSPOT,
  INTEGRATION_STATUS, CONFIGURATION_TYPES, FEATURE_FLAGS_AND_IDENTIFIERS_MAPPING,
} from './constants';
import { entries, isEmpty, omit, lowerCase, findKey, memoize, get, has } from 'lodash';
import { formatDate, getUsersTimeZone } from 'utils/utils';

export const makePostBodyForCredential = (config, configType, orgId) => {
  if (configType === MARKETO) {
    const { client_id, client_secret, identity_end_point } = config;
    return {
      clientId: client_id,
      clientSecret: client_secret,
      identityEndpoint: identity_end_point,
      orgId,
    };
  } else if (configType === SALESFORCE) {
    const { username, password, access_token } = config;
    return {
      username,
      password,
      securityToken: access_token || '',
      orgId,
    };
  } else if (configType === DYNAMICS) {
    const { client_id, client_secret, org_uri, tenant_id } = config;
    return {
      clientId: client_id,
      clientSecret: client_secret,
      tenantId: tenant_id,
      orgUri: org_uri,
      orgId,
    };
  } else if (configType === PARDOT) {
    const { username, password, userkey } = config;
    return {
      username,
      password,
      userKey: userkey || '',
      orgId,
    };
  } else if (configType === HUBSPOT_CRM || configType === HUBSPOT) {
    const { username, password, apikey } = config;
    return {
      username,
      password,
      apiKey: apikey || '',
      orgId,
    };
  }
  const { username, password, company_name } = config;
  return {
    username,
    password,
    companyName: company_name,
    orgId,
  };
};

export const clearUnchangedSecrets = (body) => {
  const nextBody = { ...body };
  if (nextBody.password === PASSWORD_PLACEHOLDER) {
    delete nextBody.password;
  }
  if (nextBody.client_secret === PASSWORD_PLACEHOLDER) {
    delete nextBody.client_secret;
  }
  return nextBody;
};

export const cleanPasswordPolicy = (body) => {
  const nextBody = { ...body };
  if (nextBody.password_policy === 'Never') {
    nextBody.password_policy = null;
  }
  return nextBody;
};

export const getSubdomainType = (domainConfig) => {
  let subdomainType = '';
  if (domainConfig.is_standard) subdomainType = 'isStandard';
  else if (domainConfig.is_assigned) subdomainType = 'isAssigned';
  else subdomainType = 'isCustom';
  return subdomainType;
};

// maybe this can look through the default api limits, access the limits
// out of the changed values by building up the api key
// todo: this should just be a selector
export const makeApiLimitPatchBody = (
  changedValues,
  integration_type,
  organization_id,
  defaultApiLimit,
) => {
  const { api_type } = defaultApiLimit;

  const [, daily_limit] = entries(changedValues)[0];

  return {
    api_type, // 'REST'
    daily_limit,
    integration_type,
    organization_id,
  };
};

export const makeLoginPostBody = (formValues, mapType, orgId) =>
  makePostBodyForCredential(formValues, mapType, orgId);

/**
 * @param  {string} objectName The name we want to clean eg: SFDC Lead
 * @return {string} The cleaned name: Lead (removed SFDC)
 */
export const cleanObjectName = (objectName) => {
  if (isEmpty(objectName)) return objectName;
  const nameArr = objectName.split(' ');
  if (nameArr.length <=1) return objectName;
  // add other entries as required
  const constantsDictionary = [
    'sfdc',
    'salesforce',
    'marketo',
    'eloqua',
    'pardot',
    'hubspot',
    'dynamics',
    'crm',
    'map',
  ];
  if (constantsDictionary.includes(lowerCase(nameArr[0]))) {
    return nameArr.slice(1).join(' ');
  }
  return nameArr.join(' ');
};

export const getDateTimestampInUTCFormat = (integrationDate) => {
  const { timezoneAbbrevation, utcOffset } = getUsersTimeZone();
  return `${formatDate('MMM DD, YYYY')(integrationDate)}
  ${formatDate('hh:mm a')(integrationDate)}
  ${timezoneAbbrevation} (UTC${utcOffset})`;
};

export const summaryHOCStatusMapper = (status) => {
  switch (status) {
    case 'ACTIVE':
      return INTEGRATION_STATUS.CONFIGURED;
    case 'ERROR':
      return INTEGRATION_STATUS.ERROR;
    case 'IN_PROGRESS':
      return INTEGRATION_STATUS.IN_PROGRESS;
    case 'NOT_STARTED':
      return INTEGRATION_STATUS.NOT_CONFIGURED;
    case 'UNKNOWN':
      return INTEGRATION_STATUS.UNKNOWN;
    case 'SYNC_DISABLED':
      return INTEGRATION_STATUS.SYNC_DISABLED;
    default:
      return status;
  }
};

/**
 * Util for handling loading states in modules
 */
export const loaderPresets = {
  loading: false,
  loaded: false,
  error: false,
  errorStatus: undefined,
  errorMessage: undefined,
};

/**
 * Util intended to be used with reducerUtil, to set the state as the action payload.
 */
export const setPayload = (state, action) => {
  const payload = omit(action, 'type');
  return { ...state, ...payload };
};

export const areCredentialsValid = (credentials, authType) => {
  if (isEmpty(credentials)) return false;
  if (credentials.is_deleted) return false;
  // if basic auth and creds are present then its valid
  if (authType === CONFIGURATION_TYPES.BASIC) return true;
  if (authType === CONFIGURATION_TYPES.OAUTH) {
    return !!credentials.is_valid;
  }
  return false;
};

/**
 *
 * @param {string} topicName  - topicName which shows up on the pendo dashboard.
 * For integrations we would be following a convention such as
 * Integrations-${route/feature}-${event}
 *
 * @param {*} payload - json payload to be sent to pendo.
 */
export const pendoTrack = (topicName, payload) => {
  const { pendo } = window;
  if (pendo && pendo.track) {
    pendo.track(topicName, payload);
  }
};

export const getFeatureFlagForIdentifier = memoize((identifier) =>
  findKey(FEATURE_FLAGS_AND_IDENTIFIERS_MAPPING, (identifiers) =>
    identifiers.includes(identifier)
  )
);

export const isIntegrationFeatureFlagEnabledForOrg = ({
  orgFeatureFlags,
  featureIdentifier,
} = {}) => {
  const featureFlagName = getFeatureFlagForIdentifier(featureIdentifier);
  return (
    orgFeatureFlags[featureFlagName] === true ||
    orgFeatureFlags[featureFlagName] === undefined
  );
};

// Checking if in Maintenance mode or not

export const getMaintenanceInfo = () => {
  try {
    const maintenanceString = get(window, 'process.env.MAINTENANCE_STATUS', '');
    const maintenanceInfo = JSON.parse(maintenanceString.replace(/&quot;/g, '"'));

    // ensure maintenance info has at least a message and app list
    if (!has(maintenanceInfo, 'message') || !has(maintenanceInfo, 'apps')) {
      throw new Error('Maintenance info seems to be malformed');
    }
    return maintenanceInfo;
  } catch (err) {
    return '';
  }
};
