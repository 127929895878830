import React from 'react';
import {
  SEGMENT_QUERY,
  SYSTEM_NOTIFICATIONS_QUERY,
  CONTACT_LEAD_MANAGEMENT_QUERY,
  USER_MANAGEMENT_QUERY,
  USAGE_METRICS_QUERY,
  MAPPING_PROFILES_QUERY,
} from './queries';
import {
  ConversationalEmailPermission,
  Permission,
  SalesPermission,
  WorkflowsPermission,
  usePermissionCheck,
} from '@sixsense/rbac';
import { TagsIcon } from 'routes/Orchestrations/components';
import { checkFunnelInsightsAccess } from 'routes/Reports/routes/Reports/utils';
import { EnabledIconWrapper } from '../../components/EnabledIconWrapper';
import {
  checkSiAccess,
  checkSiAppEnabled,
  checkWorkflowsAppEnabled,
  getAppUrlWithOrgName,
  showLegacySiSettings,
  checkMappingProfileTabVisibility,
} from './utils';
import { useOrg } from 'hooks';
import {
  useSISettingsViewEditPermissions,
} from 'routes/Settings/routes/SalesConfig/SISettingsPermissions';
import { useActionsViewEditPermissions } from
  'routes/Settings/routes/ActionInPlatformAndApps/ActionsSettingsPermissions';
import { CRM_SYSTEMS, systemNameMap } from '../Mapping/constants';
import { checkIsAdminForABM, checkIsWorkflowsUser } from '../Integration/routes/Manage/selectors';
import { Badge, Flex } from '@sixsense/core/components';
import { StarCluster } from '@sixsense/core/icons';
import { useSettingsFiscalYearViewPermissions } from '../FiscalYear/hooks';
import { isSiteLoadedInIframe } from 'utils/utils';

const useMappingProfilePermissions = () => {
  const siPermission = usePermissionCheck([
    SalesPermission.SETTINGS_SI_MAPPING_PROFILES_EDIT,
  ]);
  const workflowsPermission = usePermissionCheck([
    WorkflowsPermission.MAPPING_PROFILE_VIEW,
  ]);
  return siPermission || workflowsPermission;
};

const subVisibilityCheck = (subOptions) => (showInfo, isExternalSixsenseUser, permissions) =>
  subOptions.some(({ requiredPermissions, visibilityCheck }) => {
    if (visibilityCheck && !visibilityCheck(showInfo, isExternalSixsenseUser, permissions)) {
      return false;
    }
    if (
      !isExternalSixsenseUser &&
      requiredPermissions &&
      !requiredPermissions.some((p) => permissions && permissions.has(p))
    ) {
      return false;
    }
    return true;
  });

export const ACTIONS_SETTINGS = [
  {
    title: 'Actions in Platform and Apps',
    id: 'Actions in Platform and Apps',
    description: 'Show or hide actions in the platform and apps',
    location: () => '/settings/actionsettings',
    requiredPermissions: [Permission.SETTINGS_ACTIONS_VIEW, Permission.SETTINGS_ACTIONS_EDIT],
    visibilityCheck: (showInfo) => showInfo.flags.hasPredictive
      && showLegacySiSettings(showInfo),
    usePermissions: useSISettingsViewEditPermissions,
  },
];

export const SECURITY_SETTINGS = [
  {
    title: (
      <span>
        Authentication <EnabledIconWrapper />
      </span>
    ),
    id: ('Authentication'),
    description: 'Configure your SAML SSO settings',
    location: () => '/settings/security',
    requiredPermissions: [
      Permission.SETTINGS_AUTHENTICATION_VIEW,
      Permission.SETTINGS_AUTHENTICATION_EDIT,
    ],
    visibilityCheck: (showInfo) => checkSiAccess(showInfo, 'authentication'),
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: (
      <span>
        Contact Details
      </span>
    ),
    id: ('Contact_Details'),
    description: 'Configure your Privacy and Security email settings',
    location: () => '/settings/security/contact_details',
    requiredPermissions: [
      Permission.SETTINGS_AUTHENTICATION_VIEW,
      Permission.SETTINGS_AUTHENTICATION_EDIT,
    ],
    visibilityCheck: (showInfo) => checkSiAccess(showInfo, 'contact_details'),
    usePermissions: useSISettingsViewEditPermissions,
  },
];


export const SALES_INTELLIGENCE_SETTINGS = [
  {
    title: 'SI Integration Settings ',
    id: 'SI Integration Settings ',
    description: `Control synchronization, manage update dependencies,
     and configure export settings in integrations between
      Sales Intelligence and CRM platforms and SEPs`,
    location: () => '/settings/sales-integration',
    requiredPermissions: [
      SalesPermission.SETTINGS_INTEGRATION_VIEW,
      SalesPermission.SETTINGS_INTEGRATION_EDIT,
    ],
    visibilityCheck: (showInfo) => checkSiAppEnabled(showInfo),
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Talking points',
    id: 'Talking points',
    description: 'Add and manage curated or automated custom talking points',
    location: () => '/settings/talkingpointrules/manage',
    requiredPermissions: [
      SalesPermission.SETTINGS_TALKING_POINT_VIEW,
      SalesPermission.SETTINGS_TALKING_POINT_EDIT,
    ],
    visibilityCheck: (showInfo) => checkSiAppEnabled(showInfo)
      && checkSiAccess(showInfo, 'talkingpoints'),
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'SI Actions Settings',
    id: 'SI Actions Settings',
    description: 'Show or hide actions in the platform and apps',
    location: () => '/settings/si-actionssettings',
    requiredPermissions: [
      SalesPermission.SETTINGS_SI_ACTIONS_VIEW,
      SalesPermission.SETTINGS_SI_ACTIONS_EDIT,
    ],
    visibilityCheck: (showInfo) => checkSiAppEnabled(showInfo),
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Components & metrics',
    id: 'Components & metrics',
    description: `Configure the Sales Intelligence experience (dashboards, detail pages)
     to display insights most relevant to your organization`,
    location: () => '/settings/componentsmetrics',
    requiredPermissions: [
      SalesPermission.SETTINGS_COMPONENTS_METRICS_VIEW,
      SalesPermission.SETTINGS_COMPONENTS_METRICS_EDIT,
    ],
    visibilityCheck: (showInfo) => checkSiAppEnabled(showInfo),
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Activities',
    id: 'Activities',
    description: `Customize how and what kinds of sales- and marketing-related
     activities are shown in the Sales Intelligence experience`,
    location: () => '/settings/activities',
    requiredPermissions: [
      SalesPermission.SETTINGS_ACTIVITIES_VIEW,
      SalesPermission.SETTINGS_ACTIVITIES_EDIT,
    ],
    visibilityCheck: (showInfo) => checkSiAppEnabled(showInfo),
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Persona',
    id: 'Persona',
    description: `Customize what job levels and job functions are displayed
     in the persona map based to your organization’s need`,
    location: () => '/settings/personamap',
    requiredPermissions: [
      SalesPermission.SETTINGS_PERSONA_VIEW,
      SalesPermission.SETTINGS_PERSONA_EDIT,
    ],
    visibilityCheck: (showInfo) => checkSiAppEnabled(showInfo),
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'SI Alerts',
    id: 'SI Alerts',
    description: 'Manage Sales Intelligence alerts',
    location: () => '/settings/sialerts',
    requiredPermissions: [
      SalesPermission.SETTINGS_ALERT_VIEW,
      SalesPermission.SETTINGS_ALERT_EDIT,
    ],
    visibilityCheck: (showInfo) => checkSiAppEnabled(showInfo),
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Credits',
    id: 'Credits',
    description: 'Track SI credits and allocate credits among SI users',
    location: () => '/settings/creditmanagement',
    requiredPermissions: [
      SalesPermission.SETTINGS_CREDIT_VIEW,
      SalesPermission.SETTINGS_CREDIT_EDIT,
    ],
    visibilityCheck: (showInfo) => checkSiAppEnabled(showInfo) && !showInfo.isInternalEntLite,
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Privacy Data Control',
    id: 'Privacy Data Control',
    description: 'Set rules to exclude contact data access to comply with privacy laws',
    location: () => '/settings/privacyControl',
    requiredPermissions: [
      SalesPermission.SETTINGS_PRIVACY_DATA_CONTROL,
    ],
    visibilityCheck: (showInfo) => checkSiAppEnabled(showInfo),
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Sales Intelligence',
    id: 'Sales Intelligence 1',
    description: `Configure this experience to display
      the insights most relevant to your organization`,
    location: (condition) =>
      condition
        ? '/settings/salesconfig/navigation'
        : '/settings/salesconfig/talkingpointrules/manage',
    requiredPermissions: [
      Permission.SETTINGS_SALES_INTELLIGENCE_VIEW,
      Permission.SETTINGS_SALES_INTELLIGENCE_EDIT,
      Permission.SETTINGS_SALES_INTELLIGENCE_TPR_EDIT,
    ],
    visibilityCheck: (showInfo) => (showInfo.flags.hasPredictive &&
      showLegacySiSettings(showInfo)),
    usePermissions: useActionsViewEditPermissions,
  },
  {
    title: 'Generative AI',
    id: 'Generative AI',
    description: 'Configure and set access controls for AI Insights for your org',
    location: () => '/settings/generativeai',
    requiredPermissions: [
      SalesPermission.SETTINGS_GENERATIVE_AI_VIEW,
      SalesPermission.SETTINGS_GENERATIVE_AI_EDIT,
    ],
    visibilityCheck: (showInfo) => checkSiAppEnabled(showInfo)
      && checkSiAccess(showInfo, 'genai'),
    usePermissions: useSISettingsViewEditPermissions,
  },
];


export const ORCHESTRATION = [
  {
    title: 'Orchestration Mapping Profiles',
    id: 'Orchestration Mapping Profiles',
    description: 'Configure which fields will be enriched with 6sense data in your CRM or MAP',
    location: () => '/settings/mapping',
    q: MAPPING_PROFILES_QUERY,
    subvalues: [
      {
        title: 'FIELDS ENABLED',
        path: 'stats.mappingProfiles.fieldsEnabled',
      },
    ],
    requiredPermissions: [Permission.SETTINGS_MAPPINGS_PROFILE_EDIT],
    visibilityCheck: (showInfo) => showInfo.customerPackages.length > 0,
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Contact & Lead Management',
    id: 'Contact & Lead Management',
    description: `Configure limits and prioritization logic
      for contact acquisition and related orchestrations`,
    location: () => '/settings/contactOrchestration',
    q: CONTACT_LEAD_MANAGEMENT_QUERY,
    subvalues: [
      {
        title: 'PURCHASED THIS MONTH',
        path: 'stats.contactLeadManagement.purchasedThisMonth',
      },
    ],
    requiredPermissions: [
      Permission.SETTINGS_CONTACT_PURCHASE_LIMIT_EDIT,
      Permission.SETTINGS_ACCOUNT_MATCHING_EDIT,
    ],
    visibilityCheck: (showInfo) =>
      (showInfo.zen && !showInfo.zen.is_deleted) ||
      (showInfo.predictive && !showInfo.predictive.is_deleted),
    usePermissions: useActionsViewEditPermissions,
  },
];

export const getCustomValuemapping = (org = {}) => {
  const { crmType, mapType } = org;
  let crmCard = {};
  let mapCard = {};
  const mappingCards = [];

  if (crmType) {
    const cType = crmType.toLowerCase();
    crmCard = {
      title: `${systemNameMap[cType]} Custom Value Mapping`,
      id: `${systemNameMap[cType]} Custom Value Mapping`,
      description: 'Set up mapping profiles to map 6sense fields with your CRM fields.',
      location: () => '/settings/mapping?system=crm&tab=Employee%20Range&pageSource=global',
      requiredPermissions: [
        Permission.SETTINGS_MAPPINGS_PROFILE_EDIT,
        SalesPermission.SETTINGS_SI_MAPPING_PROFILES_EDIT,
        WorkflowsPermission.MAPPING_PROFILE_VIEW,
        WorkflowsPermission.MAPPING_PROFILE_EDIT,
      ],
      visibilityCheck: (showInfo) => {
        const { user } = showInfo;
        const isAbmAdmin = checkIsAdminForABM(user);
        const isWorkflowsUser = checkIsWorkflowsUser(user);
        return CRM_SYSTEMS.includes(cType) &&
          (!(showInfo?.permissions.has(SalesPermission.BE_PREDICTIVE) &&
            !showInfo?.permissions.has(SalesPermission.BE_PROSPECTING)) ||
            isAbmAdmin || isWorkflowsUser
          );
      },
    };
    mappingCards.push(crmCard);
  }

  if (mapType) {
    const mType = mapType.toLowerCase();
    mapCard = {
      title: `${systemNameMap[mType] || mType} Custom Value Mapping`,
      id: `${systemNameMap[mType] || mType} Custom Value Mapping`,
      description: 'Set up mapping profiles to map 6sense fields with your MAP fields.',
      location: () => '/settings/mapping?system=map&tab=Employee%20Range&pageSource=global',
      requiredPermissions: [
        Permission.SETTINGS_MAPPINGS_PROFILE_EDIT,
        WorkflowsPermission.MAPPING_PROFILE_VIEW,
        WorkflowsPermission.MAPPING_PROFILE_EDIT,
      ],
      visibilityCheck: (showInfo) => {
        const { user } = showInfo;
        const isWorkflowsUser = checkIsWorkflowsUser(user);
        const isAbmAdmin = checkIsAdminForABM(user);
        return !!mType && (isAbmAdmin || isWorkflowsUser);
      },
    };
    mappingCards.push(mapCard);
  }

  return mappingCards;
};

const ABM = [
  {
    title: 'Published Segments',
    id: 'Published Segments',
    description: 'Specify segments to be published for specific 6sense workflows and experiences',
    location: () => '/settings/publishedsegments',
    q: SEGMENT_QUERY,
    subvalues: [
      {
        title: 'SEGMENTS PUBLISHED',
        path: 'stats.segment.published',
      },
    ],
    requiredPermissions: [
      Permission.SETTINGS_PUBLISH_SEGMENT_VIEW,
      Permission.SETTINGS_PUBLISH_SEGMENT_EDIT,
    ],
    usePermissions: useActionsViewEditPermissions,
  },
];

const CONVERSATIONAL_EMAIL = [
  {
    title: 'Conversational Email',
    id: 'Conversational Email',
    description: 'Access the Conversational Email settings here',
    location: () => `${window.process.env.CE_UI_ENDPOINT}settings/`,
    requiredPermissions: [
      ConversationalEmailPermission.SETTINGS_AI_INBOX_VIEW,
    ],
    isNotInternal: true,
    newWindow: true,
  },
];

export const getAppSettings = (advertisingSubOption) => [
  {
    title: 'ABM',
    id: 'abm',
    appId: 'ABM',
    subOption: ABM,
    visibilityCheck: subVisibilityCheck(ABM),
    location: () => '/settings/manage?activeContext=abm',
    isSubVisibilityCheck: true,
  },
  {
    title: 'Advertising',
    id: 'advertising',
    subOption: advertisingSubOption,
    visibilityCheck: subVisibilityCheck(advertisingSubOption),
    location: () => '/settings/manage?activeContext=advertising',
    isSubVisibilityCheck: true,
  },
  {
    title: 'Conversational Email',
    id: 'conversational_email',
    appId: 'CE',
    subOption: CONVERSATIONAL_EMAIL,
    visibilityCheck: subVisibilityCheck(CONVERSATIONAL_EMAIL),
    location: () => '/settings/manage?activeContext=conversational-email',
    isSubVisibilityCheck: true,
  },
  {
    title: 'Workflows',
    id: 'workflows',
    appId: 'Workflows',
    // visibilityCheck: () => get(window, 'process.env.WORKFLOWS_DEBUG_MODE', false),
    visibilityCheck: (showInfo) => checkWorkflowsAppEnabled(showInfo),
    location: () => '/settings/manage?activeContext=workflows-settings',
    getIFrameURL: () => {
      // should return app url with org name for content security policy on iframe events
      const workflowsLink = getAppUrlWithOrgName(window?.process?.env?.WORKFLOWS_UI_ENDPOINT);
      return `${workflowsLink}settings-headless`;
    },
    isIframe: true,
    requiredPermissions: [
      WorkflowsPermission.MAPPING_PROFILE_VIEW,
      WorkflowsPermission.MERGE_SETTINGS_VIEW,
      WorkflowsPermission.TIE_BREAK_SETTINGS_VIEW,
      WorkflowsPermission.CREDIT_SETTINGS_VIEW,
      WorkflowsPermission.TERRITORY_MGMT_VIEW,
      WorkflowsPermission.ROUND_ROBIN_VIEW,
    ],
  },
  {
    title: 'Orchestration',
    id: 'orchestration',
    subOption: ORCHESTRATION,
    visibilityCheck: subVisibilityCheck(ORCHESTRATION),
    location: () => '/settings/manage?activeContext=orchestration',
    isSubVisibilityCheck: true,
  },
  {
    title: 'Sales Intelligence',
    id: 'sales_app',
    subOption: SALES_INTELLIGENCE_SETTINGS,
    visibilityCheck: subVisibilityCheck(SALES_INTELLIGENCE_SETTINGS),
    location: () => '/settings/manage?activeContext=sales-intelligence',
    isSubVisibilityCheck: true,
  },
];

export const SYSTEM_NOTIFICATIONS = [
  {
    title: 'Notifications',
    id: 'Notifications',
    description: 'Configure who should be notified for system and other notifications',
    location: () => '/settings/notifications',
    q: SYSTEM_NOTIFICATIONS_QUERY,
    subvalues: [
      {
        title: 'NOTIFIED USERS',
        path: 'stats.systemNotifications.notifiedUsers',
      },
    ],
    requiredPermissions: [
      Permission.SETTINGS_NOTIFICATIONS_VIEW,
      Permission.SETTINGS_NOTIFICATIONS_EDIT,
    ],
    usePermissions: useActionsViewEditPermissions,
  },
];

export const YOUR_USERS = [
  {
    title: 'User Management',
    id: 'User Management',
    description: "Manage platform users' access",
    location: () => '/settings/usermanagement',
    q: USER_MANAGEMENT_QUERY,
    subvalues: [
      {
        title: 'ACTIVE',
        path: 'stats.userManagement.active',
      },
      {
        title: 'INVITED',
        path: 'stats.userManagement.invited',
      },
      {
        title: 'DISABLED',
        path: 'stats.userManagement.disabled',
      },
    ],
    requiredPermissions: [
      Permission.SETTINGS_USER_MANAGEMENT_VIEW,
      Permission.SETTINGS_USER_MANAGEMENT_EDIT_USER,
      Permission.SETTINGS_USER_MANAGEMENT_ADMIN_ASSIGNMENT,
      Permission.SETTINGS_USER_MANAGEMENT_OWNER_ASSIGNMENT,
    ],
    visibilityCheck: (showInfo) =>
      showInfo.flags.has_user_management ||
      showInfo.user.is_dev ||
      showInfo.user.is_staff ||
      showInfo.user.is_superuser ||
      showInfo.user.is_internaluser,
    usePermissions: useSISettingsViewEditPermissions,
  },
];

export const ADMINISTRATOR_REPORTS = [
  {
    title: 'Usage',
    id: 'Usage',
    description: "Monitor users' activites on the platform",
    location: () => '/settings/reports/usage',
    requiredPermissions: [Permission.SETTINGS_ADMIN_REPORTS_VIEW],
    q: USAGE_METRICS_QUERY,
    subvalues: [
      {
        title: 'PLATFORM LOGINS LAST 3 MONTHS',
        path: 'stats.usageMetrics.platformLoginsLast3Months',
      },
      {
        title: 'SALES INTELLIGENCE LOGINS LAST 3 MONTHS',
        path: 'stats.usageMetrics.siLoginsLast3Months',
      },
    ],
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Value',
    id: 'Value',
    description: "See how 6sense contributes to your company's successes",
    location: () => '/settings/reports/value',
    requiredPermissions: [Permission.SETTINGS_ADMIN_REPORTS_VIEW],
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Model',
    id: 'Model',
    description: 'Explore the basis for 6sense algorithms',
    location: () => '/settings/reports/model',
    requiredPermissions: [Permission.SETTINGS_ADMIN_REPORTS_VIEW],
    visibilityCheck: ({ zen, predictive }) =>
      (zen && !zen.is_deleted) || (predictive && !predictive.is_deleted),
    usePermissions: useSISettingsViewEditPermissions,
  },
];

const OppStageConfigTitle = () => {
  const org = useOrg();
  return (
    <span>
      Opportunity Stage Config {org.id !== 4 && <TagsIcon type="BETA_TAG" />}
    </span>
  );
};

export const getDataSettings = (org = {}) => [
  {
    title: 'Keywords',
    id: 'Keywords',
    description: 'Branded and generic keyword configuration for intent activity tracking',
    location: () => '/settings/keywords',
    requiredPermissions: [Permission.SETTINGS_KEYWORDS_VIEW, Permission.SETTINGS_KEYWORDS_EDIT],
    visibilityCheck: (showInfo) => checkSiAccess(showInfo, 'keywords'),
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Exclusions',
    id: 'Exclusions',
    description: 'Use exclusions to focus analytics and campaigns on relevant data',
    location: () => '/settings/exclusions?listType=company',
    requiredPermissions: [
      Permission.SETTINGS_EXCLUSIONS_INVENTORY_VIEW,
      Permission.SETTINGS_EXCLUSIONS_WEBSITE_VIEW,
      Permission.SETTINGS_EXCLUSIONS_COMPANY_VIEW,
      Permission.SETTINGS_EXCLUSIONS_INVENTORY_EDIT,
      Permission.SETTINGS_EXCLUSIONS_WEBSITE_EDIT,
      Permission.SETTINGS_EXCLUSIONS_COMPANY_EDIT,
    ],
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: (
      <span>
        Data Standardization
      </span>
    ),
    id: ('Data Standardization'),
    description: 'Map your data to the 6sense database',
    location: () => '/settings/standardization',
    isExternal: false,
    requiredPermissions: [Permission.SETTINGS_TAXONOMY_VIEW, Permission.SETTINGS_TAXONOMY_EDIT],
    visibilityCheck: (showInfo) =>
      showInfo.flags.enable_data_standardization_v2,
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Data Standardization Repete',
    id: 'Data Standardization Repete',
    description: 'Use Repete to perform data standardization',
    location: () => '/standardization',
    /**
     * Behavior based on the `isExternal` flag:
     *
     * - `isExternal: true`
     *   - The site is loaded as a first-party site.
     *   - Clicking on the Data Standardization Repete link will trigger a hard reload.
     *
     * - `isExternal: false`
     *   - The site is loaded within an iframe.
     *   - Clicking on the Data Standardization Repete link will open the link in a new tab.
     *   - When treated as an internal React route(trigger only for reat routes):
     *     - The `listenBefore` listener in `src/history.js` will be called.
     *     - If navigation does not occur within the settings, the URL will open in a new tab.
     */
    isExternal: !isSiteLoadedInIframe(),
    visibilityCheck: (showInfo) =>
      showInfo.user.is_staff || showInfo.user.is_superuser || showInfo.user.is_dev,
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Data Taxonomy',
    id: 'Data Taxonomy',
    getDescription: (showInfo) => {
      const isFirstPartyTaxonomyAccessible = showInfo.flags.use_turbotax;
      const isPartnerTaxonomyAccessible =
        showInfo.flags.dp_turbotax_ready && PARTNER_DATA_QA_USERS.includes(showInfo.user.username);

      // if user can ONLY access first party taxonomy ie:'use_turbotax',
      // show first party taxonomy specific description
      if (isFirstPartyTaxonomyAccessible && !isPartnerTaxonomyAccessible) {
        return 'Taxonomy for Website URLs, MAP, CRM data';
      }

      // if user can ONLY access partner taxonomy ie:'dp_turbotax_ready'
      // && user belongs to partner qa group,
      // show partner taxonomy specific description
      if (!isFirstPartyTaxonomyAccessible && isPartnerTaxonomyAccessible) {
        return 'Partner data taxonomy for integrations like TrustRadius, G2, etc';
      }

      return 'Track & manage your taxonomy data for first and second party data providers';
    },
    location: () => '/settings/taxonomy',
    isExternal: false,
    requiredPermissions: [Permission.SETTINGS_TAXONOMY_VIEW, Permission.SETTINGS_TAXONOMY_EDIT],
    visibilityCheck: (showInfo) => {
      if (showInfo.flags.enable_aidt_v2) {
        return false;
      }
            /* (Data taxonomy cards visibility : Is First party accessible 'use_turbotax') OR
       (Is partner taxonomy accessible 'dp_turbotax_ready' AND
        whether logged-in user belongs to qa group)
       05/06/24: Give higher precendence to the disable_turbotax_UI flag.
       For most customers it will False.
       This flag is intended for running parallel pipeline jobs so UI should be disabled.
       In the future it can be repurposed if required.
       However, QA users are unaffected by this flag
       */
      return (!showInfo.flags.disable_turbotax_UI ||
        PARTNER_DATA_QA_USERS.includes(showInfo.user.username)) &&
      (showInfo.flags.use_turbotax ||
      (showInfo.flags.dp_turbotax_ready && PARTNER_DATA_QA_USERS.includes(showInfo.user.username))
      && checkSiAccess(showInfo, 'taxonomy'));
    },
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Data Taxonomy',
    id: 'Data Taxonomy v2',
    getDescription: (showInfo) => {
      // TODO: fix this description part
      const isFirstPartyTaxonomyAccessible = showInfo.flags.use_turbotax;
      const isPartnerTaxonomyAccessible =
        showInfo.flags.dp_turbotax_ready && PARTNER_DATA_QA_USERS.includes(showInfo.user.username);

      // if user can ONLY access first party taxonomy ie:'use_turbotax',
      // show first party taxonomy specific description
      if (isFirstPartyTaxonomyAccessible && !isPartnerTaxonomyAccessible) {
        return 'Taxonomy for Website URLs, MAP, CRM data';
      }

      // if user can ONLY access partner taxonomy ie:'dp_turbotax_ready'
      // && user belongs to partner qa group,
      // show partner taxonomy specific description
      if (!isFirstPartyTaxonomyAccessible && isPartnerTaxonomyAccessible) {
        return 'Partner data taxonomy for integrations like TrustRadius, G2, etc';
      }

      return 'Track & manage your taxonomy data for first and second party data providers';
    },
    location: () => '/settings/taxonomyV2',
    isExternal: false,
    requiredPermissions: [Permission.SETTINGS_TAXONOMY_VIEW, Permission.SETTINGS_TAXONOMY_EDIT],
    visibilityCheck: (showInfo) => {
      if (!showInfo.flags.enable_aidt_v2) {
        return false;
      }
        /* (Data taxonomy cards visibility : Is First party accessible 'use_turbotax') OR
       (Is partner taxonomy accessible 'dp_turbotax_ready' AND
        whether logged-in user belongs to qa group)
       05/06/24: Give higher precendence to the disable_turbotax_UI flag.
       For most customers it will False.
       This flag is intended for running parallel pipeline jobs so UI should be disabled.
       In the future it can be repurposed if required.
       However, QA users are unaffected by this flag
       */
      return (!showInfo.flags.disable_turbotax_UI ||
        PARTNER_DATA_QA_USERS.includes(showInfo.user.username)) &&
      (showInfo.flags.use_turbotax ||
      (showInfo.flags.dp_turbotax_ready && PARTNER_DATA_QA_USERS.includes(showInfo.user.username))
      && checkSiAccess(showInfo, 'taxonomy'));
    },
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: (
      <span>
        <OppStageConfigTitle />
      </span>
    ),
    id: ('OppStageConfigTitle'),
    description:
      'Order and group together your opportunity statuses to enable the Funnel Insights report.',
    location: () => '/settings/opportunity-stage-config',
    requiredPermissions: [
      Permission.SETTINGS_OPP_STAGE_CONFIG_VIEW,
      Permission.SETTINGS_OPP_STAGE_CONFIG_EDIT,
    ],
    visibilityCheck: (showInfo) => checkFunnelInsightsAccess(showInfo.org, showInfo.user.username),
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: '6QA Customization',
    id: '6QA Customization',
    description: "Configure your accounts' 6sense Qualification criteria",
    location: () => '/settings/custom6QA',
    requiredPermissions: [Permission.SETTINGS_CUSTOM6QA_VIEW, Permission.SETTINGS_CUSTOM6QA_EDIT],
    visibilityCheck: ({ predictive }) => predictive && !predictive.is_deleted,
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Custom Value Mappings',
    id: 'Custom Value Mappings',
    description: `Configure these mappings if your organization uses values different
      from 6sense's set of firmographic classifications.`,
    location: () => '/settings/custom-value-mappings',
    subOption: getCustomValuemapping(org),
    visibilityCheck: subVisibilityCheck(getCustomValuemapping(org)),
    isSubVisibilityCheck: true,
  },
  {
    title: 'Relevant Job Titles',
    id: 'Relevant Job Titles',
    description: 'Select job titles relevant to your sales team and your go-to-market strategy',
    location: () => '/settings/jobtitles',
    requiredPermissions: [
      SalesPermission.SETTINGS_JOBTITLES_VIEW,
      SalesPermission.SETTINGS_JOBTITLES_EDIT,
    ],
    visibilityCheck: (showInfo) => checkSiAppEnabled(showInfo) &&
      checkSiAccess(showInfo, 'jobtitles'),
    usePermissions: useSISettingsViewEditPermissions,
  },

  {
    title: 'Mapping Profiles',
    id: 'Mapping Profiles',
    location: () => '/settings/manage?activeContext=mapping-profiles',
    description: 'Set up mapping profiles to map 6sense fields with your CRM/MAP/SEP fields.',
    getIFrameURL: () => {
      // should return app url with org name for content security policy on iframe events
      const workflowsLink = getAppUrlWithOrgName(window?.process?.env?.WORKFLOWS_UI_ENDPOINT);
      return `${workflowsLink}settings-headless/mappingProfiles`;
    },
    isIframe: true,
    requiredPermissions: [
      SalesPermission.SETTINGS_SI_MAPPING_PROFILES_EDIT,
      WorkflowsPermission.MAPPING_PROFILE_VIEW,
    ],
    usePermissions: useMappingProfilePermissions,
    visibilityCheck: checkMappingProfileTabVisibility,
  },
  {
    title: 'Relevant Psychographics',
    id: 'Relevant Psychographics',
    description: `Select topics, themes,
     initiatives, and principles relevant to your sales team and your go-to-market strategy`,
    location: () => '/settings/psychographics',
    requiredPermissions: [
      SalesPermission.SETTINGS_PSYCHOGRAPHICS_VIEW,
      SalesPermission.SETTINGS_PSYCHOGRAPHICS_EDIT,
    ],
    visibilityCheck: (showInfo) => checkSiAppEnabled(showInfo)
      && checkSiAccess(showInfo, 'psychographics'),
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Relevant Technologies',
    id: 'Relevant Technologies',
    description: `Use exclusions relating to technologies
     in use to focus analytics and campaigns on relevant data`,
    location: () => '/settings/technologies',
    requiredPermissions: [
      SalesPermission.SETTINGS_TECHNOGRAPHICS_VIEW,
      SalesPermission.SETTINGS_TECHNOGRAPHICS_EDIT,
    ],
    visibilityCheck: (showInfo) => checkSiAppEnabled(showInfo),
    usePermissions: useSISettingsViewEditPermissions,
  },
  {
    title: 'Fiscal Year',
    id: 'Fiscal Year',
    description: '',
    location: () => '/settings/fiscalyear',
    requiredPermissions: [
      Permission.SETTINGS_FISCAL_YEAR_VIEW,
    ],
    usePermissions: useSettingsFiscalYearViewPermissions,
  },
];

export const INTEGRATION_SETTINGS = [
  {
    title: 'Integrations',
    id: 'Integrations',
    description: 'Manage CRM, MAP, and other integrations',
    location: () => '/settings/integration/manage',
    requiredPermissions: [
      Permission.SETTINGS_INTEGRATION_CRM_EDIT,
      Permission.SETTINGS_INTEGRATION_CRM_VIEW,
      Permission.SETTINGS_INTEGRATION_MAP_EDIT,
      Permission.SETTINGS_INTEGRATION_MAP_VIEW,
      Permission.SETTINGS_INTEGRATION_SEP_EDIT,
      Permission.SETTINGS_INTEGRATION_SEP_VIEW,
      Permission.SETTINGS_INTEGRATION_SLACK_EDIT,
      Permission.SETTINGS_INTEGRATION_SLACK_VIEW,
      Permission.CAMPAIGN_EDIT,
    ],
    usePermissions: useSISettingsViewEditPermissions,
  },
];

const QA_USERS = [
  // test user
  'testuser@6sense.com',
  // turbotax-eng channel members
  'anmol.suri@6sense.com',
  'anshul.agrawal@6sense.com',
  'anudeepthi.koppula@6sense.com',
  'harshad.borde@6sense.com',
  'michael@6sense.com',
  'neerav.shah@6sense.com',
  'riddhesh.sajwan@6sense.com',
  'shini.gangrade@6sense.com',
  'shireesh.gadidesi@6sense.com',
  'tulika.jaipuriar@6sense.com',
  'vedant.gawade@6sense.com',
  'rathika.rajendran@6sense.com',
  // qa team
  'ritika.rajkotia@6sense.com',
  'shraddha.uke@6sense.com',
  'sushmita.gupta@6sense.com',
  'lina.bajirao@6sense.com',
  'aakash.pardeshi@6sense.com',
];

export const getSettingsMapping = (flags, advertisingSubOption, org, apps) => [
  {
    title: 'App Settings',
    id: 'App Settings',
    children: getAppSettings(advertisingSubOption)
    .filter(({ appId }) => !appId || apps[appId]),
    visibilityCheck: subVisibilityCheck(getAppSettings(advertisingSubOption)),
    isSubVisibilityCheck: true,
  },
  {
    title: null,
    id: 'Random YOUR_USERS',
    children: YOUR_USERS,
    visibilityCheck: subVisibilityCheck(YOUR_USERS),
    isSubVisibilityCheck: true,
  },
  {
    title: null,
    id: 'Random SYSTEM_NOTIFICATIONS',
    children: SYSTEM_NOTIFICATIONS,
    visibilityCheck: subVisibilityCheck(SYSTEM_NOTIFICATIONS),
    isSubVisibilityCheck: true,
  },
  {
    title: 'Administrator Reports',
    id: 'Administrator Reports',
    children: ADMINISTRATOR_REPORTS,
    check: !flags.reportsLandingPageEnabled,
    visibilityCheck: (...params) => !flags.reportsLandingPageEnabled &&
      subVisibilityCheck(ADMINISTRATOR_REPORTS)(...params),
    isSubVisibilityCheck: true,
  },
  {
    title: 'Data Settings',
    id: 'Data Settings',
    children: getDataSettings(org),
    visibilityCheck: subVisibilityCheck(getDataSettings(org)),
    isSubVisibilityCheck: true,
  },
  {
    title: null,
    id: 'Integration Settings',
    children: INTEGRATION_SETTINGS,
    visibilityCheck: subVisibilityCheck(INTEGRATION_SETTINGS),
    isSubVisibilityCheck: true,
  },
  {
    title: 'Actions Settings',
    id: 'Actions Settings',
    children: ACTIONS_SETTINGS,
    visibilityCheck: subVisibilityCheck(ACTIONS_SETTINGS),
    isSubVisibilityCheck: true,
  },
  {
    title: 'Security Settings',
    id: 'Security Settings',
    children: SECURITY_SETTINGS,
    visibilityCheck: subVisibilityCheck(SECURITY_SETTINGS),
    isSubVisibilityCheck: true,
  },
];

export const PARTNER_DATA_QA_USERS = [...QA_USERS];


export const CONSENT_MANAGEMENT_TITLE =
  (
    <span>
      Contact Consent Management&nbsp;&nbsp;
      <Badge color="violet" mode="light">
        <Flex gap={4} alignItems="center">
          <StarCluster />
          New
        </Flex>
      </Badge>
    </span>
  );
