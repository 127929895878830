import React from 'react';
import { css } from 'styles/emotion';
import { Button, Text } from 'v2-components';
import { Card, Flex } from '@sixsense/core/components';
import { pxToRem } from '@sixsense/core/style';
import { destroyAllParamsNavigate } from 'utils/navigate';

const styles = {
  noDataContainer: css({
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '250px',
    paddingBottom: 20,
  }),
  noDataMsg: css({
    height: '50%',
    marginTop: 20,
    color: '#505C62',
  }),
  button: css({
    marginTop: pxToRem(8),
    width: '314px',
  }),
};

type NoDataProps = {
  title: string;
  noDataStr: string;
};

const NoDataComponent = ({ title, noDataStr }: NoDataProps) => (
  <Card.Content
    style={{
      background: 'white',
      border: '1px solid #C6CACE',
    }}
  >
    <Flex direction="column">
      <Text bold type={Text.TYPE.TITLE}>
        {title}
      </Text>
      <Button
        onClick={() => {
          destroyAllParamsNavigate('/settings/integration/manage');
        }}
        className={styles.button}
      >
        {noDataStr}
      </Button>
    </Flex>
  </Card.Content>
);

export default NoDataComponent;
