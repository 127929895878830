import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PageHeader } from 'HOCS';
import { AccessDenied, Loading2 as Loading } from 'v2-components';
import { SalesPermission, withPermissionBoundary } from '@sixsense/rbac';
import CreditManagementHeader from '../components/CreditManagementHeader';
import { useRequest, useRequestValue, useSharedState } from '@sixsense/core';
import { compose } from 'redux';
import CreditDetailsComponent from '../components/CreditDetails';
import {
  CREDIT_MSGS,
  SI_CREDIT_POOL_LABELS,
  WF_CREDIT_POOL_LABELS,
  TOTAL_WF_CREDIT_POOL_LABELS,
  TOTAL_CREDIT_POOL_LABELS,
} from '../constants';
import {
  siOrgCreditsRequestState,
  wfOrgCreditsRequestState,
  totalOrgCreditsRequestState,
  userRolesState,
  usersListRequestState,
  creditValidationState,
  orgPoolSelectedState,
  orgCreditPoolState,
  dataCreditsUsageState,
} from '../state';
import UsersCard from '../components/UsersCard';
import { Alert, Flex } from '@sixsense/core/components';
import { orgFlagsSelector, orgObjSelector } from 'modules/user/selectors';
import { css } from 'styles/emotion';
import { CreditType, CreditUsers } from '../types';
import {
  siPackagePlanNameSelector,
  isPrimaryAdminSelector,
  isWorkflowEnabledSelector,
  isInternalStaffUserSelector,
  siInternalLicenseTypeSelector,
} from 'routes/Settings/selectors';
import { actions } from 'v2-components/Download/modules';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import { actions as globalActions } from 'modules/global';
import DistributeCreditModal from '../components/DistributeCreditModal';
import { MAINTENANCE_INFO } from 'routes/Settings/constants';

const styles = {
  container: css({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }),
  text: css({
    fontSize: 16,
    marginBottom: 8,
  }),
};

const errorDisplay = (message) => (
  <Flex
    className={styles.container}
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    <div className={styles.text}>{message}</div>
  </Flex>
);

type Props = {
  org: any;
  orgFlags: any;
  siPackagePlanName: string;
  isPrimaryAdmin: string;
  showEntireView: boolean;
  dl: any;
  isInternalEntLite: boolean;
};
const LoadingComponent = Loading.LoadingComponent;

const CreditManagementContainer = ({
  org,
  orgFlags,
  siPackagePlanName,
  isPrimaryAdmin,
  showEntireView,
  dl,
  isInternalEntLite = false,
}: Props) => {
  const dispatch = useDispatch();
  const { id: orgId } = org;
  const request = useRequest();
  const [selectedUsers, setSelectedUsers] = useState<Set<String>>(new Set());
  const [callCreditPool, setCallCreditPool] = useState<Boolean>(false);
  const [users, setUsers] = useSharedState(usersListRequestState);
  const [siCreditInfo, setSICreditInfo] = useSharedState<CreditType>(
    siOrgCreditsRequestState
  );
  const [wfCreditInfo, setWFCreditInfo] = useSharedState<CreditType>(
    wfOrgCreditsRequestState
  );
  const [totalCreditInfo, setTotalCreditInfo] = useSharedState<CreditType>(
    totalOrgCreditsRequestState
  );
  const userRoles = useRequestValue(userRolesState, { orgId });
  const [loading, setLoading] = useState(false);
  const [credtitError, setCreditError] = useState('');
  const [creditValidation, setCreditValidation] = useSharedState(
    creditValidationState
  );
  const [dataCreditsUsageFlag, setdataCreditsUsageFlag] = useSharedState(
    dataCreditsUsageState
  );
  const [orgPoolSelected] = useSharedState<CreditType>(orgPoolSelectedState);
  const { data: creditPoolData = [], status } = useRequestValue(
    orgCreditPoolState,
    { orgId, callCreditPool }
  );
  const alertMessage = (type: string, msg: string) => {
    dispatch(globalActions.showNotification(type, msg));
  };

  useEffect(() => {
    setdataCreditsUsageFlag(orgFlags.data_credits_usage_allowed);
  }, [orgFlags?.data_credits_usage_allowed]);

  useEffect(() => {
    if (
      status === 'LOADED' &&
      creditPoolData &&
      Array.isArray(creditPoolData) &&
      !creditPoolData.length
    ) {
      setCreditError(CREDIT_MSGS.CREDIT_NOT_ALLOTED);
    }
  }, [creditPoolData]);

  const validateUpdateCredit = ({ credits, action }) => {
    if (
      action === 'add_to_workflow' &&
      credits > siCreditInfo?.total_unallocated_credits
    ) {
      setCreditValidation({
        disabled: true,
        error: CREDIT_MSGS.DISTRIBUTE_CREDIT_ERR_MSG,
      });
      return;
    }

    if (
      action === 'add_to_si' &&
      credits > (wfCreditInfo?.total_balance_credits || 0)
    ) {
      setCreditValidation({
        disabled: true,
        error: CREDIT_MSGS.DISTRIBUTE_CREDIT_ERR_MSG,
      });
      return;
    }

    if (credits < 0 || credits % 1 !== 0) {
      setCreditValidation({
        disabled: true,
        error: CREDIT_MSGS.INVALID_ERROR_MSG,
      });
      return;
    }
    if (!credits || credits === '0') {
      setCreditValidation({
        disabled: true,
        error: '',
      });
      return;
    }
    if (creditValidation.disabled) {
      setCreditValidation({
        disabled: false,
        error: '',
      });
    }
  };

  const { confirm: distributeCredit, modalProps: distributeModalprops } =
    DistributeCreditModal.useConfirm({ validateUpdateCredit });

  useEffect(() => {
    if (siPackagePlanName && !isInternalEntLite && orgPoolSelected?.id) {
      resetCedits();
      getUsers();
      getCreditInfo();
      setTotalCreditInfo(orgPoolSelected);
    }
  }, [orgPoolSelected]);

  if (!siPackagePlanName || isInternalEntLite) return <AccessDenied />;

  const getCreditInfo = async () => {
    await getSICreditInfo();
    await getWFCreditInfo();
  };

  const resetCedits = () => {
    setWFCreditInfo({});
    setSICreditInfo({});
    setUsers([]);
  };

  const getSICreditInfo = async () => {
    try {
      let siCreditsUrl = `credit/${orgId}/?credit_type=sales_intelligence`;
      siCreditsUrl = `${siCreditsUrl}&org_credits_id=${orgPoolSelected?.id}`;
      const res: CreditType = await request(siCreditsUrl);
      setSICreditInfo(res);
    } catch (ex) {
      if (ex && ex.body && typeof ex.body === 'string') {
        setCreditError(ex.body);
      }
    }
  };
  const getWFCreditInfo = async () => {
    try {
      if (
        !orgPoolSelected?.wf_org_credits_id ||
        orgPoolSelected?.wf_org_credits_id === '0'
      ) {
        return;
      }
      let wfCreditsUrl = `credit/${orgId}/?credit_type=workflows`;
      wfCreditsUrl = `${wfCreditsUrl}&org_credits_id=${orgPoolSelected?.wf_org_credits_id}`;
      const res: CreditType = await request(wfCreditsUrl);
      setWFCreditInfo(res);
    } catch (ex) {
      if (ex && ex.body && typeof ex.body === 'string') {
        // setCreditError(ex.body);
      }
    }
  };

  const getUsers = async () => {
    try {
      setLoading(true);
      let usersApiUrl = `credit/${orgId}/users/?credit_type=sales_intelligence`;
      usersApiUrl = `${usersApiUrl}&org_credits_id=${orgPoolSelected?.id}`;
      const res: Array<[CreditUsers]> = await request(usersApiUrl);
      setUsers(res);
      setLoading(false);
    } catch (ex) {
      setLoading(false);
      if (ex && ex.body && typeof ex.body === 'string') {
        setCreditError(ex.body);
      }
    }
  };

  const downloadCsv = (content) => {
    // Get the current date in 'YYYY-MM-DD' format
    const currentDate = new Date().toISOString().split('T')[0];
    const fileName = `6sense_credit_report_${currentDate}.csv`;
    return dl(content, fileName, 'report');
  };

  const distributeCredits = async (action, credits) => {
    try {
      let distributeCreditUrl = `credit/${orgId}/distribute/?credit_type=sales_intelligence`;
      distributeCreditUrl = `${distributeCreditUrl}&org_credits_id=${orgPoolSelected?.id}`;
      const body = {
        credits,
      };
      if (action === 'add_to_workflow') {
        body.add_credits_to_workflow = true;
      }
      if (action === 'add_to_si') {
        body.add_credits_to_si = true;
      }
      const requestParams = {
        method: 'POST',
        body: JSON.stringify(body),
      };
      setLoading(true);
      await request(distributeCreditUrl, requestParams);
      await getCreditInfo();
      if (action === 'add_to_workflow') {
        setCallCreditPool(!callCreditPool);
      }
      setLoading(false);
      alertMessage('success', CREDIT_MSGS.CREDITS_UPDATED_MSG);
    } catch (ex) {
      setLoading(false);
      if (ex && ex.body && typeof ex.body === 'string') {
        alertMessage('error', ex.body);
        return;
      }
      alertMessage('error', 'Some error occurred');
    }
  };

  const handleDistributeCredit = () => {
    const creditInfo = {
      add_to_workflow: siCreditInfo?.total_unallocated_credits,
      add_to_si: wfCreditInfo?.total_balance_credits || 0,
    };
    distributeCredit(creditInfo)
      .then(({ action, credits }) => {
        // call distribute api
        distributeCredits(action, credits);
      })
      .catch((reason) => {
        console.log('done', reason);
      });
  };

  const getToolTips = () =>
    showEntireView ? TOTAL_WF_CREDIT_POOL_LABELS : TOTAL_CREDIT_POOL_LABELS;

  if (MAINTENANCE_INFO.with_read_only) return <AccessDenied message={MAINTENANCE_INFO.message} />;

  return (
    <React.Fragment>
      {loading && <LoadingComponent level="cover" />}
      {credtitError ? (
        errorDisplay(credtitError)
      ) : (
        <div>
          <Flex gap={8} direction="column">
            {!dataCreditsUsageFlag && (
              <Alert
                align="center"
                titleText={CREDIT_MSGS.CREDIT_USAGE_DISABLED}
              />
            )}
            <CreditDetailsComponent
              creditData={showEntireView ? totalCreditInfo : siCreditInfo}
              sections={getToolTips()}
              label={showEntireView ? '' : 'SI Credits'}
              showDistributeButton={showEntireView && isPrimaryAdmin}
              distributeCredits={handleDistributeCredit}
            />
            {showEntireView && (
              <Flex gap={8}>
                <CreditDetailsComponent
                  creditData={siCreditInfo}
                  sections={SI_CREDIT_POOL_LABELS}
                  label="SI Credits"
                />
                <CreditDetailsComponent
                  creditData={wfCreditInfo}
                  sections={WF_CREDIT_POOL_LABELS}
                  label="Workflow Credits"
                />
              </Flex>
            )}
          </Flex>
          <UsersCard
            users={users}
            setUsers={setUsers}
            creditInfo={siCreditInfo}
            setSelectedUsers={setSelectedUsers}
            selectedUsers={selectedUsers}
            orgId={orgId}
            getCreditInfo={getSICreditInfo}
            userRoles={userRoles?.data || []}
            getUsers={getUsers}
            downloadCsv={downloadCsv}
            planName={siPackagePlanName}
          />
        </div>
      )}
      <DistributeCreditModal {...distributeModalprops} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  org: orgObjSelector(state),
  orgFlags: orgFlagsSelector(state),
  siPackagePlanName: siPackagePlanNameSelector(state),
  isPrimaryAdmin: isPrimaryAdminSelector(state),
  showEntireView: isWorkflowEnabledSelector(state) || isInternalStaffUserSelector(state),
  isInternalEntLite: siInternalLicenseTypeSelector(state),
});

const CreditManagement = compose(
  withPermissionBoundary({
    allow: (permissions) =>
      [
        SalesPermission.SETTINGS_CREDIT_VIEW,
        SalesPermission.SETTINGS_CREDIT_EDIT,
      ].some((permission) => permissions.has(permission)),
    renderDenied: () => <AccessDenied />,
  }),
  connect(mapStateToProps, { dl: actions.download }),
  PageHeader([CreditManagementHeader])
)(CreditManagementContainer);


export default CreditManagement;
