import { Addon, Organization, Package } from '@sixsense/core/types';
import { get } from 'lodash';


export const orgHasUnifiedContacts = (flags, isPredictive, hasEssentials) => {
  const orgHasContactsOrLeads =
    flags && (flags.has_crm_contact || flags.has_crm_lead || flags.has_map_contact);
  const orgIsPredictiveOrHasEssentials = isPredictive || hasEssentials;
  return orgHasContactsOrLeads && orgIsPredictiveOrHasEssentials;
};

export const toOrganization = (
    orgObj: any,
    flags: any = {},
    isPredictive,
    hasEssentials,
    predictiveProducts = [],
): Organization => {
  const packages: Set<Package> = new Set();
  if (get(orgObj.packages, 'aa.is_deleted', true) === false) {
    packages.add(Package.PLATFORM);
  }
  if (get(orgObj.packages, 'zen.is_deleted', true) === false) {
    packages.add(Package.ESSENTIALS);
  }
  if (orgObj.has_predictive) {
    packages.add(Package.ADVANCED);
  }

  const addons: Set<Addon> = new Set();
  if (orgObj.properties?.bombora_topic_cap !== '0') {
    addons.add(Addon.BOMBORA);
  }

  const { sync_sources = [] } = orgObj;

  if (sync_sources.find((sync_source) => sync_source.sync_source_type === 'trustradius' &&
    !sync_source.is_deleted)) {
    addons.add(Addon.TRUST_RADIUS);
  }

  if (sync_sources.find((sync_source) => sync_source.sync_source_type === 'G2' &&
    !sync_source.is_deleted)) {
    addons.add(Addon.G2);
  }

  const products = predictiveProducts.map((p) => ({
    id: p.id,
    name: p.name,
    displayName: p.display_name,
  }));

  const defaultProduct = products.at(0);

  return {
    id: orgObj.id,
    name: orgObj.name,
    packages,
    addons,
    hasPredictive: get(orgObj, 'has_predictive', false),
    modelsReady: get(orgObj, 'flags.models_ready', false),
    hasUnifiedContacts: orgHasUnifiedContacts(flags, isPredictive, hasEssentials),
    integrations: {
      crm: orgObj.crmType,
      map: orgObj.mapType,
      sep: orgObj.sepType,
    },
    products,
    defaultProduct,
  };
};

export const getCommandbarMetadata = (
    userObj: any,
    orgObj: any
): any => ({
  visitorID: userObj.id,
  orgID: orgObj.id,
  orgName: orgObj.org_name,
  roleID: userObj.role_id,
  appName: 'ABM',
});
