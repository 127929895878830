import { combineReducers } from 'redux';
import { PRESET_STRING } from '../../constants';
import { createReducerUtil } from 'utils/core';
import { loaderPresets, setPayload } from '../../utils';
import { cloneDeep } from 'lodash';
import update from 'immutability-helper';


import {
    loadProductTagMappingGenerator,
} from './stateGenerators';

export const SET_PRODUCT_URLS = `${PRESET_STRING}/SET_PRODUCT_URLS`;
export const SET_PRODUCT_KEYWORDS = `${PRESET_STRING}/SET_PRODUCT_KEYWORDS`;

export const SAVE_URLS_REQUEST = `${PRESET_STRING}/SAVE_URLS_REQUEST`;
export const SAVE_URLS_SUCCESS = `${PRESET_STRING}/SAVE_URLS_SUCCESS`;
export const SAVE_URLS_FAILURE = `${PRESET_STRING}/SAVE_URLS_FAILURE`;

export function saveURLs(productURLs) {
  return {
    ...loaderPresets,
    type: SAVE_URLS_REQUEST,
    productURLs,
    loading: true,
  };
}

function saveURLSuccess() {
  return {
    type: SAVE_URLS_SUCCESS,
    loading: false,
  };
}

function saveURLFailure() {
  return {
    type: SAVE_URLS_FAILURE,
    loading: false,
  };
}

export function setProductURLs(urls) {
  return {
    type: SET_PRODUCT_URLS,
    urls,
  };
}

export function setProductKeywords(keywords) {
  console.log('setting product keywords');
  return {
    type: SET_PRODUCT_KEYWORDS,
    keywords,
  };
}

const productURLReducer = createReducerUtil([], {
  [SET_PRODUCT_URLS]: (_, action) => action.urls,
});

/**
 * add,remove and move keyword actions
 */

const MOVE_KEYWORD = `${PRESET_STRING}/PRODUCT_CONFIG/MOVE_KEYWORD`;
const moveKeyword = (mappingIndex, newBucket, oldBucket, oldBucketIndex, keyword) => ({
  type: MOVE_KEYWORD,
  mappingIndex,
  newBucket,
  oldBucket,
  oldBucketIndex,
  keyword,
});

const REMOVE_KEYWORD = `${PRESET_STRING}/PRODUCT_CONFIG/REMOVE_KEYWORD`;
const removeKeyword = (mappingIndex, bucket, bucketIndex) => ({
  type: REMOVE_KEYWORD,
  mappingIndex,
  bucket,
  bucketIndex,
});

const SET_KEYWORD_INPUT = `${PRESET_STRING}/PRODUCT_CONFIG/SET_KEYWORD_INPUT`;
const setKeywordInput = (value, mappingIndex, bucket) => ({
  type: SET_KEYWORD_INPUT,
  value,
  mappingIndex,
  bucket,
});

const ADD_KEYWORD = `${PRESET_STRING}/PRODUCT_CONFIG/ADD_KEYWORD`;
const addKeyword = (value, mappingIndex, bucket) => ({
  type: ADD_KEYWORD,
  value,
  mappingIndex,
  bucket,
});

const productKeywordReducer = createReducerUtil([], {
  [SET_PRODUCT_KEYWORDS]: (_, action) => action.keywords,
  [MOVE_KEYWORD]: (state, action) => {
    const { mappingIndex, newBucket, oldBucket, oldBucketIndex, keyword } = action;
    const newKeyword = cloneDeep(keyword);
    newKeyword.updated = true;
    return update(state, {
      [mappingIndex]: {
        keywords: {
          [newBucket]: { $push: [newKeyword] },
          [oldBucket]: { $splice: [[oldBucketIndex, 1]] },
        } },
    });
  },
  [REMOVE_KEYWORD]: (state, action) => {
    const { mappingIndex, bucket, bucketIndex } = action;
    return update(state, {
      [mappingIndex]: {
        keywords: {
          [bucket]: {
            [bucketIndex]: {
              isDeleted: { $set: true },
            } } } },
    });
  },
  [SET_KEYWORD_INPUT]: (state, action) => {
    const { value, mappingIndex, bucket } = action;
    return update(state, {
      [mappingIndex]: {
        keywords: {
          keywordInput: {
            [bucket]: { $set: value },
          },
        } },
    });
  },
  [ADD_KEYWORD]: (state, action) => {
    const { value, mappingIndex, bucket } = action;
    const keyword = { id: null, value, isDeleted: false, updated: false };
    return update(state, {
      [mappingIndex]: {
        keywords: { [bucket]: { $push: [keyword] },
          keywordInput: {
            [bucket]: { $set: '' },
          },
        },

      },
    });
  },
});

const saveURLReducer = createReducerUtil([], {
  [SAVE_URLS_REQUEST]: setPayload,
  [SAVE_URLS_SUCCESS]: setPayload,
  [SAVE_URLS_FAILURE]: setPayload,
});

export const actions = {
  saveURLs,
  saveURLFailure,
  saveURLSuccess,
  setProductURLs,
  setProductKeywords,
  moveKeyword,
  removeKeyword,
  setKeywordInput,
  addKeyword,
};

export const actionTypes = {
  SAVE_URLS_REQUEST,
  SET_PRODUCT_URLS,
  SAVE_URLS_SUCCESS,
  SAVE_URLS_FAILURE,
  MOVE_KEYWORD,
};

export const productUrlConfigReducer = combineReducers({
  [loadProductTagMappingGenerator.stateKey]: loadProductTagMappingGenerator.reducer,
  productURLs: productURLReducer,
  productKeywords: productKeywordReducer,
  saveURL: saveURLReducer,
});
