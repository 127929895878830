import {
  ADVERTISING_BASE_ROUTE,
  ADVERTISING_CATEGORIES,
  LINKEDIN_PREVIEW_OPTIONS,
  PAGE_NUMBER_SEARCH_PARAM,
  SELECTED_FOLDER_SEARCH_PARAM,
  SORT_ORDER,
  STATIC_TREE_NODE_KEYS,
  WEEK,
  MONTH,
  MONTH_TO_DATE,
  LAST_MONTH,
  LAST_QUARTER,
  QUARTER_TO_DATE,
} from "./constants";
import {
  CLASSIFICATION_TYPES,
  DEFAULT_FOLDERS,
} from "./ducks/campaignClassifications/constants";
import { AD_LIBRARY_BASE_ROUTE } from "./routes/AdLibrary/constants";
import { AD_LIST_BASE_ROUTE } from "./routes/AdLibrary/routes/AdPlacementList/constants";
import { CAMPAIGNS_ROUTE } from "./routes/Campaigns/constants";
import { CAMPAIGN_LIST_ROUTE } from "./routes/Campaigns/routes/ListCampaigns/constants";
import {
  difference,
  filter,
  get,
  intersection,
  isArray,
  isBoolean,
  isEmpty,
  isEqual,
  isObject,
  last,
  mapValues,
  omit,
  pick,
  pickBy,
  set,
  some,
  split,
  uniqBy,
} from "lodash";
import * as qs from "qs";
import { browserHistory } from "react-router";
import { generateQueryParams } from "./routes/Campaigns/utils";
import {
  computeRangeSelection,
  copyToClipboard,
  numberToDollar,
} from "../../utils/utils";
import { CAMPAIGN_SOURCE, RELEASE_TYPES } from "utils/constants";
import { isFeatureFlagEnabledForOrg } from "./featureGating/utils";
import moment from "moment";
import LinkedInIcon from "./images/LinkedInLogo.svg";
import SixsenseIcon from "./images/6senseLogo.svg";
import ExternalMediaIcon from "./images/externalMediaIcon.svg";

export const pullUnSortedFolderForward = (folderList) => {
  const unsortedFolder = folderList.find(
    (folder) =>
      folder.classification_type === DEFAULT_FOLDERS.CAMPAIGNS_UNSORTED
  );

  const sortedFolders = folderList
    .filter(
      (folder) =>
        folder.classification_type !== DEFAULT_FOLDERS.CAMPAIGNS_UNSORTED
    )
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

  return [unsortedFolder, ...sortedFolders];
};

export const addKeyPrefix = (prefix, node) => ({
  ...node,
  key: `${prefix}/${node.key}`,
  ...(node.children
    ? { children: node.children.map((n) => addKeyPrefix(prefix, n)) }
    : {}),
});

export const getAdvertisingViewTypeFromFolderKey = (key) => key.split("/")[0];

export const computeAdsFiltersData = (filters, labelMap = {}) => {
  const filterBy = mapValues(filters, (value, key) => {
    if (isArray(value)) {
      return value.map(({ value: v }) => v);
    } else if (isBoolean(value)) {
      return value ? [key] : [];
    }
    return [];
  });

  const filterState = mapValues(filters, (value, key) => {
    if (isArray(value)) {
      return value.reduce(
        (filterObject, v) => ({ ...filterObject, [get(v, "value")]: true }),
        {}
      );
    } else if (isBoolean(value)) {
      return value ? { [key]: value } : {};
    }
    return [];
  });

  const allPossibleFilterOptions = Object.values(
    mapValues(filters, (value, key) => {
      if (isArray(value)) {
        return { label: "", filterKey: key, choices: value };
      } else if (isBoolean(value)) {
        return {
          label: "",
          filterKey: key,
          choices: [{ label: get(labelMap, key, key), value: key }],
        };
      }
      return [];
    })
  );

  return { filterBy, filterState, allPossibleFilterOptions };
};

export const getModifiedFilters = (filterState, filters) => {
  const updatedFilters = mapValues(filters, (value, key) => {
    if (isArray(value)) {
      return value.filter(({ value: v }) => get(filterState, `${key}.${v}`));
    } else if (isBoolean(value)) {
      return get(filterState, `${key}.${key}`, false);
    }
    return value;
  });
  return updatedFilters;
};

export const treeNodeKeysUtils = {
  separator: "/",
  addCategoryAsPrefix(prefix, key) {
    return `${prefix}${this.separator}${key}`;
  },
  extractCategoryFromPrefix(key) {
    return key.split(this.separator)[0];
  },
  extractFolderId(key) {
    return key && key.split(this.separator)[1];
  },
  getFolderObject(treeNodeKey, folderList) {
    const folderId = this.extractFolderId(treeNodeKey);

    if (STATIC_TREE_NODE_KEYS.CAMPAIGN_DRAFT_FOLDER === folderId) {
      return {
        classification_type: CLASSIFICATION_TYPES.CAMPAIGNS_DRAFT,
        name: "Drafts",
      };
    }

    if (!isNaN(parseInt(folderId))) {
      return folderList.find(({ id }) => id === parseInt(folderId));
    }

    return null;
  },
};

export const addPrefixToTreeNodeKeys = (prefix, node) => ({
  ...node,
  key: treeNodeKeysUtils.addCategoryAsPrefix(prefix, node.key),
  ...(node.children
    ? { children: node.children.map((n) => addPrefixToTreeNodeKeys(prefix, n)) }
    : {}),
});

export const isDirectoryTreeVisible = (testPathname) =>
  [
    `/${ADVERTISING_BASE_ROUTE}/${AD_LIBRARY_BASE_ROUTE}/${AD_LIST_BASE_ROUTE}/`,
    `/${ADVERTISING_BASE_ROUTE}/${AD_LIBRARY_BASE_ROUTE}/${AD_LIST_BASE_ROUTE}`,
    `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${CAMPAIGN_LIST_ROUTE}/`,
    `/${ADVERTISING_BASE_ROUTE}/${CAMPAIGNS_ROUTE}/${CAMPAIGN_LIST_ROUTE}`,
  ].includes(testPathname);

export const genCampaignTreeNodeKey = (key) =>
  treeNodeKeysUtils.addCategoryAsPrefix(ADVERTISING_CATEGORIES.CAMPAIGNS, key);
export const genAdLibraryTreeNodeKey = (key) =>
  treeNodeKeysUtils.addCategoryAsPrefix(ADVERTISING_CATEGORIES.AD_LIBRARY, key);

export const syncSelectedFolderToSearchParams = (selectedFolderId) => {
  const prevSearchParamsObject = qs.parse(
    browserHistory.getCurrentLocation().search,
    { ignoreQueryPrefix: true }
  );
  const nextSearchParamsObject = pickBy({
    ...prevSearchParamsObject,
    [SELECTED_FOLDER_SEARCH_PARAM]: selectedFolderId,
  });

  if (isEqual(nextSearchParamsObject, prevSearchParamsObject) === false) {
    const nextSearchParams = generateQueryParams(nextSearchParamsObject);
    browserHistory.replace({
      pathname: browserHistory.getCurrentLocation().pathname,
      search: nextSearchParams,
    });
  }
};

export const getCampaignsFolderFromQuery = (queryParams, campaignFolders) => {
  const folderIdInQueryParams = get(queryParams, SELECTED_FOLDER_SEARCH_PARAM);
  const validCampaignFolder = campaignFolders.some(
    ({ id }) => genCampaignTreeNodeKey(id) === folderIdInQueryParams
  );
  const oneOfCampaignsStaticTreeNodes = [
    genCampaignTreeNodeKey(STATIC_TREE_NODE_KEYS.CAMPAIGN_DRAFT_FOLDER),
    genCampaignTreeNodeKey(STATIC_TREE_NODE_KEYS.CAMPAIGN_ALL),
  ].includes(folderIdInQueryParams);
  if (oneOfCampaignsStaticTreeNodes || validCampaignFolder) {
    return folderIdInQueryParams;
  }

  return null;
};

export const getAdLibraryFolderFromQuery = (queryParams, adLibraryFolders) => {
  const folderIdInQueryParams = get(queryParams, SELECTED_FOLDER_SEARCH_PARAM);
  const validAdLibraryFolder = adLibraryFolders.some(
    ({ id }) => genAdLibraryTreeNodeKey(id) === folderIdInQueryParams
  );
  const oneOfAdLibraryStaticTreeNodes = [
    genAdLibraryTreeNodeKey(STATIC_TREE_NODE_KEYS.AD_LIBRARY_ALL),
  ].includes(folderIdInQueryParams);
  if (oneOfAdLibraryStaticTreeNodes || validAdLibraryFolder) {
    return folderIdInQueryParams;
  }
  return null;
};

export const syncSearchParams = (filters, filterKeys) => {
  const prevSearchParams = qs.parse(
    browserHistory.getCurrentLocation().search,
    { ignoreQueryPrefix: true }
  );

  const nextFiltersSearchParams = filters;
  const allFiltersKeys = Object.values(filterKeys);
  const filtersSearchParamsToOmit = allFiltersKeys.filter(
    (filterKey) =>
      Object.keys(nextFiltersSearchParams).includes(filterKey) === false
  );

  const nextParamsObject = {
    ...omit(prevSearchParams, filtersSearchParamsToOmit),
    ...nextFiltersSearchParams,
  };
  const nextSearchParams = generateQueryParams(nextParamsObject);

  browserHistory.replace({
    pathname: browserHistory.getCurrentLocation().pathname,
    search: nextSearchParams,
  });
};

export const createIncrementedName = (allNames, newItemName) => {
  let foundUnique = false;
  let matchesFound = 0;
  let suggestedName = newItemName;
  const find = (query) =>
    allNames.find((name) => name.toLowerCase() === query.toLowerCase());

  while (foundUnique === false) {
    suggestedName = matchesFound
      ? `${newItemName}(${matchesFound})`
      : newItemName;

    if (!find(suggestedName)) {
      foundUnique = true;
    } else {
      matchesFound += 1;
    }
  }
  return suggestedName;
};

export const computeMultiSelection = (
  controlDown,
  shiftDown,
  clickedRowKey,
  lastclickedRowKey,
  selections,
  folderSelectionPool
) => {
  const multiSelectBlackList = [
    genCampaignTreeNodeKey(STATIC_TREE_NODE_KEYS.CAMPAIGN_DRAFT_FOLDER),
    genCampaignTreeNodeKey(STATIC_TREE_NODE_KEYS.CAMPAIGN_ALL),
    genAdLibraryTreeNodeKey(STATIC_TREE_NODE_KEYS.AD_LIBRARY_ROOT),
    genAdLibraryTreeNodeKey(STATIC_TREE_NODE_KEYS.AD_LIBRARY_ALL),
  ];

  const oneOfBlackedListedSelected =
    intersection(multiSelectBlackList, [...selections, clickedRowKey]).length >
    0;

  const selectionContainsDifferentCategories =
    uniqBy(selections, (k) => treeNodeKeysUtils.extractCategoryFromPrefix(k))
      .length !== 1;
  const discardPreviousSelection =
    oneOfBlackedListedSelected || selectionContainsDifferentCategories;

  if (!discardPreviousSelection) {
    if (controlDown) {
      if (selections.includes(clickedRowKey)) {
        return selections.filter((key) => key !== clickedRowKey);
      }
      return [...selections, clickedRowKey];
    } else if (shiftDown) {
      const nextSelection = computeRangeSelection(
        folderSelectionPool,
        clickedRowKey,
        lastclickedRowKey,
        selections
      );
      if (nextSelection.length !== 0) {
        return nextSelection;
      }
    }
  }

  return [clickedRowKey];
};

export const syncPageNumberToSearchParams = (pageNumber) => {
  const prevSearchParams = browserHistory.getCurrentLocation().query;

  const relatedParams = pick(prevSearchParams, [PAGE_NUMBER_SEARCH_PARAM]);
  if (isEqual(relatedParams, { [PAGE_NUMBER_SEARCH_PARAM]: `${pageNumber}` })) {
    return;
  }

  prevSearchParams[PAGE_NUMBER_SEARCH_PARAM] = pageNumber;

  const nextSearchParams = generateQueryParams(prevSearchParams);

  browserHistory.replace({
    pathname: browserHistory.getCurrentLocation().pathname,
    search: nextSearchParams,
  });
};

export const pageNumberQueryParamsValidator = (params) => {
  const pageNumber = parseInt(get(params, PAGE_NUMBER_SEARCH_PARAM));

  if (pageNumber || pageNumber === 0) return pageNumber;

  return null;
};

export const callToActionOptionTransform = (optionChoices) =>
  optionChoices.map(([value, label]) => ({
    value,
    label: label.replace("_", " "),
  }));

export const linkedinAdPreviewOptions = (creativeDimensions) => {
  const creativeWidth = Number(creativeDimensions?.split("x")[0]);
  const creativeHeight = Number(creativeDimensions?.split("x")[1]);
  const isVerticalCreative = creativeHeight > creativeWidth;

  return [
    ...(!isVerticalCreative ? [LINKEDIN_PREVIEW_OPTIONS.desktop] : []),
    LINKEDIN_PREVIEW_OPTIONS.mobile,
  ];
};

export const testIdString = (inputString) =>
  inputString.toLowerCase().replaceAll(" ", "-");

export const getReleaseTypeDisplay = (releaseType) => {
  const releaseTypeDisplay = {
    [RELEASE_TYPES.ALPHA]: "Alpha",
    [RELEASE_TYPES.BETA]: "Beta",
    [RELEASE_TYPES.EARLY_ACCESS]: "Early Access Program",
  };

  return releaseTypeDisplay[releaseType] || releaseType;
};

export const generateFilterPills = (filters, allPossibleOptions, filterBy) =>
  Object.keys(filters).reduce((pills, filterKey) => {
    const filterOption = allPossibleOptions.find(
      ({ filterKey: optionFilterKey }) => filterKey === optionFilterKey
    );
    const selectedChoicesValues = filterBy[filterKey] || [];

    const choices = selectedChoicesValues.map((selectedChoiceValue) => {
      const foundChoice = filterOption.choices.find(
        ({ value }) => value === selectedChoiceValue
      );

      return foundChoice
        ? {
            label: foundChoice.label,
            value: foundChoice.value,
            filterKey,
          }
        : undefined;
    });

    return filter([...pills, ...choices]);
  }, []);

export const guessFileType = (file) => {
  if (file.type) return file.type;

  const fileExtension = last(split(file?.name, "."));

  const extensionToMIME = {
    srt: "application/x-subrip",
  };

  return extensionToMIME[fileExtension];
};

export const mapColumnKeys = (columns) =>
  columns && columns.map(({ key }) => key);

export const updateAndGetLatestColumnConfig = (
  allColumns,
  defaultColumns,
  orgFeatureFlags,
  storageManager
) => {
  let usersColumnConfig = storageManager.get();

  if (usersColumnConfig && Array.isArray(usersColumnConfig)) {
    const defaultColumnsKeysList = mapColumnKeys(defaultColumns);
    const userSelectedColumnKeyList = difference(
      usersColumnConfig,
      defaultColumnsKeysList
    );
    usersColumnConfig = userSelectedColumnKeyList.reduce((acc, columnKey) => {
      acc[columnKey] = true;
      return acc;
    }, {});

    storageManager.set(usersColumnConfig);
  }

  if (usersColumnConfig === null) {
    return defaultColumns;
  }

  // This part deselects columns which were manually selected by user but,
  // are no more available to users because respective feature flag is turned off.
  // e.g. if `has_emc` flag is turned off by csm's then all external campaign related
  // columns will be deselected.
  usersColumnConfig = mapValues(usersColumnConfig, (value, key) => {
    const isFeatureEnabled = isFeatureFlagEnabledForOrg({
      orgFeatureFlags,
      featureIdentifier: key,
    });
    if (isFeatureEnabled === false) {
      return false;
    }
    return value;
  });
  storageManager.set(usersColumnConfig);
  // Deselect column logic ends here

  const userSelectedAndDefaultColumnMerged = allColumns.filter(
    ({ key, customizable, defaultSelected }) => {
      if (customizable === false) {
        return true;
      }
      if (Object.keys(usersColumnConfig).includes(key)) {
        return usersColumnConfig[key];
      }
      return defaultSelected;
    }
  );
  return userSelectedAndDefaultColumnMerged;
};

export const updateColumnConfig = (
  selections,
  defaultColumns,
  storageManager
) => {
  const selectionColumnKeysList = mapColumnKeys(selections);
  const defaultColumnKeysList = mapColumnKeys(defaultColumns);

  const columnsDeselectedByUser = difference(
    defaultColumnKeysList,
    selectionColumnKeysList
  );
  const currentUserPreference = storageManager.get();

  const updatedUserPreference = mapValues(currentUserPreference, () => false);
  selectionColumnKeysList.forEach((columnKey) => {
    updatedUserPreference[columnKey] = true;
  });
  columnsDeselectedByUser.forEach((columnKey) => {
    updatedUserPreference[columnKey] = false;
  });

  storageManager.set(updatedUserPreference);
};

export const filterStatDates = (date) => {
  // Allows to select only last completed UTC days
  const currentDate = moment.utc().format("YYYY-MM-DD");
  const selectedDate = moment(date).format("YYYY-MM-DD");
  return moment(selectedDate).isBefore(currentDate, "day");
};

export const getLastUtcDay = () => moment.utc().subtract(1, "day"); // Returns last completed utc day

const isFilterApplied = (filterValue) => {
  if (isEmpty(filterValue)) return false;

  return Object.values(filterValue).some((value) => value);
};

const isFilterChange = (state1, state2) =>
  Object.entries(state1).reduce((anyChanged, [filterKey, filterValue]) => {
    if (anyChanged) return true;

    if (isEmpty(state2[filterKey])) return isFilterApplied(filterValue);

    const filterKeys = Object.keys(filterValue);

    for (const key of filterKeys) {
      if (!(key in state2[filterKey])) {
        if (filterValue[key] === true) {
          return true;
        }
      } else if (state2[filterKey][key] !== filterValue[key]) {
        return true;
      }
    }

    return false;
  }, false);

export const anyFilterChanged = (filterState1, filterState2) =>
  isFilterChange(filterState1, filterState2) ||
  isFilterChange(filterState2, filterState1);

export const checkIfAnyTrue = (object) =>
  some(object, (value) => some(value, (x) => x));
export const writeTextToClipboard = (text) => {
  try {
    const span = document.createElement("span");
    span.innerText = text;
    document.body.appendChild(span);
    const copyResult = copyToClipboard(span);
    document.body.removeChild(span);

    return copyResult;
  } catch (e) {
    return false;
  }
};

export const computeNextSortOrder = (
  currentSortOrder,
  currentSortBy,
  newSortBy
) => {
  const sortingOrderSequence = [
    null,
    SORT_ORDER.descending,
    SORT_ORDER.ascending,
  ];

  const currentSortOrderSequence = sortingOrderSequence.findIndex(
    (i) => i === currentSortOrder
  );

  const nextSortOrder =
    currentSortBy === newSortBy
      ? sortingOrderSequence[
          (currentSortOrderSequence + 1) % sortingOrderSequence.length
        ]
      : sortingOrderSequence[1];

  return nextSortOrder;
};

export const getCampaignLogo = (campaignSource) => {
  switch (campaignSource) {
    case CAMPAIGN_SOURCE.LINKEDIN:
    case CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING:
      return LinkedInIcon;
    case CAMPAIGN_SOURCE.INTERNAL:
    case CAMPAIGN_SOURCE.RETARGETING:
    case CAMPAIGN_SOURCE.CONTEXTUAL:
      return SixsenseIcon;
    case CAMPAIGN_SOURCE.EXTERNAL:
      return ExternalMediaIcon;
    default:
      return null;
  }
};

export const getDateRangeByFilter = (newDateRange) => {
  switch (newDateRange) {
    case WEEK: {
      return [getLastUtcDay().subtract(6, "day"), getLastUtcDay()];
    }
    case MONTH: {
      return [getLastUtcDay().subtract(29, "day"), getLastUtcDay()];
    }
    case LAST_MONTH: {
      return [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ];
    }
    case MONTH_TO_DATE: {
      return [getLastUtcDay().startOf("month"), getLastUtcDay()];
    }
    case LAST_QUARTER: {
      return [
        moment().subtract(3, "months").startOf("quarter"),
        moment().subtract(3, "months").endOf("quarter"),
      ];
    }
    case QUARTER_TO_DATE: {
      return [
        moment().quarter(getLastUtcDay().quarter()).startOf("quarter"),
        getLastUtcDay(),
      ];
    }
    default:
      return [getLastUtcDay(), getLastUtcDay()];
  }
};

export const getNumberOfFiltersApplied = (filters) =>
  Object.values(filters).reduce(
    (acc, filterValues) =>
      acc +
      (isObject(filterValues)
        ? Object.values(filterValues).reduce(
            (innerAcc, val) => (val ? innerAcc + 1 : innerAcc),
            0
          )
        : 1),
    0
  );

export const hydrateFilterOption = (data, filterOptions = [], filterByKey) => {
  const filterOption = filterOptions.find(
    ({ filterKey }) => filterKey === filterByKey
  );
  const choices = data.map(({ label, value } = {}) => ({
    label,
    value: `${value}`,
  }));
  set(filterOption, "choices", choices);
};

export const transformNumber = (number, config = {}) => {
  const { insertCommas, abbreviate, removeDecimals, showCurrency, compact } =
    config;
  let newNumber = numberToDollar(number, { insertCommas, abbreviate, compact });

  if (!showCurrency) {
    newNumber = newNumber.substring(1);
  }
  if (removeDecimals) {
    return newNumber.split(".")[0];
  }
  if (abbreviate) {
    return newNumber.replace(/\s/g, "");
  }
  return newNumber;
};

export const isSegmentv2 = (segmentId) => segmentId > 1500000;

export const getFilterQueryParams = (filters, filterKeys) => {
  const prevSearchParams = qs.parse(
    browserHistory.getCurrentLocation().search,
    { ignoreQueryPrefix: true }
  );

  const nextFiltersSearchParams = filters;
  const allFiltersKeys = Object.values(filterKeys);
  const filtersSearchParamsToOmit = allFiltersKeys.filter(
    (filterKey) =>
      Object.keys(nextFiltersSearchParams).includes(filterKey) === false
  );

  const nextParamsObject = {
    ...omit(prevSearchParams, filtersSearchParamsToOmit),
    ...nextFiltersSearchParams,
  };

  return nextParamsObject;
};

export const syncParamsWithUrl = (paramsObject) => {
  browserHistory.replace({
    pathname: browserHistory.getCurrentLocation().pathname,
    search: generateQueryParams(paramsObject),
  });
};

export const getCurrentSearchParams = () => {
  const searchString = browserHistory.getCurrentLocation().search;
  const searchParams = new URLSearchParams(searchString);
  const searchParamsEntries = searchParams.entries();
  return Object.fromEntries(searchParamsEntries);
};
