import { get, isEmpty, lowerCase } from 'lodash';
import { queryStringParamsSelector } from 'modules/global/selectors';
import { createSelector } from 'reselect';
import {
  EVENT, EVENT_CRM, EVENT_CRM_EVENT, EVENT_CRM_TASK,
  EVENT_MAP, EVENT_WEB, PRODUCT, PRODUCT_CRM, PRODUCT_MAP, PRODUCT_WEB,
} from '../../constants';

export const ruleManagerSelector = (state) => state.taxonomyV2.ruleManager;
export const ruleEditSelector = (state) => state.taxonomyV2.ruleManager.ruleEdit;
export const ruleListSelector = (state) => state.taxonomyV2.ruleManager.ruleList;

export const pathNameSelector = (state) =>
  get(state, 'route.locationBeforeTransitions.pathname', '');

export const classificationTypeQueryParamSelector = createSelector(
  queryStringParamsSelector,
  ({ classification_type }) => {
    if (isEmpty(classification_type)) return EVENT;
    if ([EVENT, PRODUCT].includes(classification_type)) return classification_type.toLowerCase();
    return EVENT;
  }
);

export const dataSourceQueryParamSelector = createSelector(
  classificationTypeQueryParamSelector,
  queryStringParamsSelector,
  (classificationType, { data_source }) => {
    if (classificationType === EVENT) {
      if (isEmpty(data_source)) return EVENT_WEB;
      if (
        [EVENT_WEB, EVENT_MAP, EVENT_CRM, EVENT_CRM_EVENT, EVENT_CRM_TASK].includes(data_source)
      ) {
        return data_source.toLowerCase();
      }
      return EVENT_WEB;
    }

    if (classificationType === PRODUCT) {
      if (isEmpty(data_source)) return PRODUCT_WEB;
      if ([PRODUCT_WEB, PRODUCT_MAP, PRODUCT_CRM].includes(data_source)) {
        return data_source.toLowerCase();
      }
      return PRODUCT_WEB;
    }

    return EVENT_WEB;
  }
);

export const createRuleModalOpenParamSelector = createSelector(
  queryStringParamsSelector,
  ({ open }) => {
    if (isEmpty(open)) return false;
    if (lowerCase(open) === 'true') return true;
    return false;
  }
);

export const ruleIdParamSelector = createSelector(
  queryStringParamsSelector,
  ({ id }) => {
    if (isEmpty(id)) return null;
    return id;
  }
);

export const ruleObjSelector = createSelector(
  ruleEditSelector,
  (ruleEdit) => ruleEdit.ruleObj
);

export const ruleObjEditCopySelector = createSelector(
  ruleEditSelector,
  (ruleEdit) => ruleEdit.ruleObjEditCopy
);

export const ruleEntitySelector = createSelector(
    ruleEditSelector,
    (ruleEdit) => ruleEdit.ruleEntity
  );

export const isCreateRuleModalOpenSelector = createSelector(
  ruleEditSelector,
  (ruleEdit) => ruleEdit.isCreateRuleModalOpen
);

export const isRuleCreatedSuccessModalOpenSelector = createSelector(
  ruleEditSelector,
  (ruleEdit) => ruleEdit.isRuleCreatedSuccessModalOpen
);

export const isUpdateRuleCfmModalOpenSelector = createSelector(
  ruleEditSelector,
  (ruleEdit) => ruleEdit.isUpdateRuleCfmModalOpen
);

export const isDeleteRuleCfmModalOpenSelector = createSelector(
  ruleListSelector,
  (ruleList) => ruleList.isDeleteRuleCfmModalOpen
);

export const isDisableRuleCfmModalOpenSelector = createSelector(
  ruleListSelector,
  (ruleList) => ruleList.isDisableRuleCfmModalOpen
);

export const isEnableRuleCfmModalOpenSelector = createSelector(
  ruleListSelector,
  (ruleList) => ruleList.isEnableRuleCfmModalOpen
);

export const rulesSelector = createSelector(
  ruleListSelector,
  (ruleList) => ruleList.rules
);

export const isChangeRuleOrderInProgressSelector = createSelector(
  ruleListSelector,
  (ruleList) => ruleList.isChangeRuleOrderInProgress
);

export const rulesBeforeReorderSelector = createSelector(
  ruleListSelector,
  (ruleList) => ruleList.rulesBeforeReorder
);

export const isUpdateRuleOrderCfmModalOpenSelector = createSelector(
  ruleListSelector,
  (ruleList) => ruleList.isUpdateRuleOrderCfmModalOpen
);

export const impactedRecordsLastCallPayloadSelector = createSelector(
  ruleEditSelector,
  (ruleEdit) => ruleEdit.impactedRecordsLastCallPayload
);

export const impactedRecordsCountSelector = createSelector(
  ruleEditSelector,
  (ruleEdit) => ruleEdit.impactedRecordsCount
);

export const ruleEditModeSelector = createSelector(
  ruleEditSelector,
  (ruleEdit) => ruleEdit.ruleEditMode
);

export const ruleToDeleteSelector = createSelector(
  ruleListSelector,
  (ruleList) => ruleList.ruleToDelete
);

