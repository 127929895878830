import { toaster } from '@sixsense/core';
import { Text } from '@sixsense/core/components';
import { FileCheck, FileX } from '@sixsense/core/icons';
import { capitalize } from 'lodash';
import { orgSelector, userObjectSelector } from 'modules/user/selectors';
import React from 'react';
import { createSelector } from 'reselect';
import { stateGenerator } from 'routes/Advertising/routes/Campaigns/stateGenerator';
import { PRESET_STRING } from './constants';
import {
  mappingReviewGlobalSelector,
  sourceAndTypeSelector,
} from './selectors';

const ruleListEndpointSelector = createSelector(
  orgSelector,
  sourceAndTypeSelector,
  (orgId, { dataSource, classificationType }) =>
    `turbotax/taxonomy/org/${orgId}/turbotax_rules/` +
    `?data_source=${dataSource}` +
    `&classification_type=${classificationType}`
);

export const loadRuleNamesStateGenerator = stateGenerator({
  baseStateSelector: mappingReviewGlobalSelector,
  actionPrefix: `${PRESET_STRING}/RULES`,
  endpointSelector: ruleListEndpointSelector,
  stateKey: 'ruleList',
  onSuccessActions: [],
  onFailureActions: [],
  initialData: [],
  method: 'GET',
});

const userListEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `turbotax/taxonomy/org/${orgId}/turbotax_override_target/users/`
);

export const loadOverrideUserStateGenerator = stateGenerator({
  baseStateSelector: mappingReviewGlobalSelector,
  actionPrefix: `${PRESET_STRING}/OVERRIDE_USERS`,
  endpointSelector: userListEndpointSelector,
  stateKey: 'overrideUsers',
  onSuccessActions: [],
  onFailureActions: [],
  initialData: [],
  method: 'GET',
});

const downloadJobsEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `turbotax/taxonomy/org/${orgId}/download_mappings/`
);

const downloadJobsPayloadSelector = createSelector(
  userObjectSelector,
  (_, loadAction) => loadAction,
  (user, loadAction) => ({
    body: JSON.stringify({
      input_metadata: loadAction.payload.inputMetadata,
      user: user.username,
    }),
  })
);

export const downloadJobsStateGenerator = stateGenerator({
  baseStateSelector: mappingReviewGlobalSelector,
  actionPrefix: `${PRESET_STRING}/DOWNLOAD_JOBS`,
  endpointSelector: downloadJobsEndpointSelector,
  stateKey: 'downloadJobs',
  onSuccessActions: [
    (_, loadAction) => {
      const ct = loadAction.payload.inputMetadata.classification_type;
      const classificationType = ct === 'event' ? 'Activity' : capitalize(ct);

      toaster.showCustomToast({
        title: 'Your download is successfully queued',
        subtitle: (<Text.Body>
          {'The downloadable file will soon be available in the '}
          <a
            href="/settings/taxonomyV2/jobs"
            target="_blank" rel="noopener noreferrer"
          >
            {`"Download History / ${classificationType} Taxonomy"`}
          </a>
          {" page. You'll be notified via email once it's ready for download."}
        </Text.Body>),
        type: 'icon',
        icon: FileCheck,
        iconColor: 'Success',
      });
      return null;
    },
  ],
  onFailureActions: [
    () => {
      toaster.showCustomToast({
        title: 'Unable to queue download',
        subtitle: 'Please try again',
        type: 'icon',
        icon: FileX,
        iconColor: 'Error',
      });
      return null;
    },
  ],
  method: 'POST',
  requestOptions: downloadJobsPayloadSelector,
});
