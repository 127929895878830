import React, { memo } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { browserHistory } from 'react-router';
import { Badge, Card, Flex, Skeleton, Text } from '@sixsense/core/components';
import { ChevronRight } from '@sixsense/core/icons';
import { EVENT } from '../../../constants';

import NodeContainer from './NodeContainer';

const subTypeTextMap = {
  Intent: 'Intent',
  Reach: 'Reach',
  null: 'Not used in Models',
};

const MappedChildNode = ({ data, isConnectable }) => {
  const loading = false;
  const { usedInModels, subType, title, activityCount, percentage, taxonomyType } = data;
  let BadgeComponent = <div />;
  if (taxonomyType === EVENT) {
    BadgeComponent = usedInModels ?
      (<Badge
        color={subType === 'Intent' ? 'primary' : 'success'}
      >
        {subTypeTextMap[subType]}
      </Badge>) :
      <Badge color="gray">{subTypeTextMap[subType]}</Badge>;
  }
  return (<NodeContainer data={data} isConnectable={isConnectable}>
    <Card style={{ margin: '0', width: '300px', height: '100px' }}>
      <Card.Content>
        <Flex direction="column">
          <Flex justifyContent="space-between">
            <Text.Title>{title}</Text.Title>
            <ChevronRight
              onClick={() => browserHistory.push({
                pathname: '/settings/taxonomyV2/mapping-review/' +
                  `${data.dataSource}/${data.taxonomyType}`,
                search: `?${qs.stringify({
                  qcfilters: data.qcfilters,
                })}`,
              })}
              style={{ cursor: 'pointer' }}
            />
          </Flex>
          <Flex>
            {loading ? (
              <Skeleton style={{ height: 20, width: 100 }} variant="rectangular" />
            ) : (
              <React.Fragment>
                <Text.RegularText>{
                  Intl.NumberFormat('en-US').format(activityCount)}</Text.RegularText>
                {taxonomyType === EVENT &&
                (<Text.RegularText>&nbsp;({percentage}%)</Text.RegularText>)}
              </React.Fragment>
            )}
          </Flex>
          <Flex className="aam-t--10">
            {BadgeComponent}
          </Flex>
        </Flex>
      </Card.Content>
    </Card>
  </NodeContainer>
  );
};

MappedChildNode.propTypes = {
  data: PropTypes.any,
  isConnectable: PropTypes.bool,
};

export default memo(MappedChildNode);
