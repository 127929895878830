import React from 'react';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Button, Card, Flex, Skeleton, Text } from '@sixsense/core/components';
import { DesignSystemColor } from '@sixsense/core/style';
import { ZapFast, ChevronRight } from '@sixsense/core/icons';
import NodeContainer from './NodeContainer';
import { ProgressIndicator } from '../../ProgressIndicator';
import {
  reviewPercentageSelector,
  mappingTotalsAndPercentagesSelector,
  mappingDataLoadingSelector,
  loadRulesCountDataSelector,
  loadRulesCountLoadingSelector,
 } from '../../../selectors';
import { PROMISE_STATES } from 'modules/global/constants';
import { DATASOURCE_PUBLISH_THRESHOLDS, DATA_SOURCE_ABBREVIATIONS } from '../../../constants';
import { loadRecordsReviewStatusCountGenerator } from '../../../stateGenerators';

const classificationType = {
  activity: 'event',
  product: 'product',
};

const getReviewText = (progress) => {
  let text = 'Continue Reviewing';
  if (progress === 0) {
    text = 'Start Reviewing';
  }
  return text;
};

const TopLevelNodeComponent = ({
  data,
  isConnectable,
  reviewPercentages,
  mappingTotalsAndPercentages,
  reviewPercentagesLoading,
  mappingDataLoading,
  loadRulesCountData,
  loadRulesCountLoading,
}) => {
  const {
    dataSource,
    taxonomyType='activity',
  } = data;
  const threshold = DATASOURCE_PUBLISH_THRESHOLDS[dataSource][taxonomyType];
  const progress = reviewPercentages[`${taxonomyType}Percentage`];
  const mappingTotalsObj = mappingTotalsAndPercentages.find((row) => row.type === taxonomyType);
  const ruleCount = loadRulesCountData.find(
    (row) => row.data_source === dataSource
    && row.classification_type === classificationType[taxonomyType])?.count || 0;
  return (
    <NodeContainer data={data} isConnectable={isConnectable}>
      <Card
        style={{
          margin: '0',
          width: '300px',
          borderTop: `4px solid ${DesignSystemColor.Blue.DEFAULT}`,
        }}
      >
        <Card.Content>
          <Flex direction="column" gap={10}>
            {reviewPercentagesLoading ? (
              <Skeleton style={{ height: 20, width: 250 }} variant="rectangular" />
            ) : (<Flex alignItems="center" justifyContent="space-between">
              <Flex alignItems="center">
                <ProgressIndicator
                  progress={progress}
                  percentageIndictatorRequired={false}
                  size="xs"
                />
                <Text.Body>{progress}% Reviewed</Text.Body>
              </Flex>
              <ChevronRight
                onClick={() =>
                  browserHistory
                  .push('/settings/taxonomyV2/mapping-review/' +
                  `${data.dataSource}/${data.taxonomyType}`)}
                style={{ cursor: 'pointer' }}
              />
            </Flex>)}
            <Flex direction="column">
              <Flex justifyContent="space-between" alignItems="center">
                <Text.Title>{startCase(taxonomyType)} Taxonomy</Text.Title>
                {/* {!underReview && (
                <ChevronRight
                  onClick={() => console.log('coming here')}
                  style={{ cursor: 'pointer' }}
                />
              )} */}
              </Flex>
              <Flex>
                {mappingDataLoading ? (
                  <Skeleton style={{ height: 20, width: 150 }} variant="rectangular" />
                ) : (
                  <React.Fragment>
                    <Text.RegularText>{
            Intl.NumberFormat('en-US').format(mappingTotalsObj.total)}</Text.RegularText>
                    <Text.RegularText>&nbsp;(100%)</Text.RegularText>
                  </React.Fragment>
              )}
              </Flex>
            </Flex>
            <Flex direction="column">
              <Button
                hierarchy="secondary"
                leadingIcon={ZapFast}
                onClick={() =>
                  browserHistory.push('/settings/taxonomyV2/rule-manager/' +
                  `?classification_type=${data.taxonomyType}` +
                  `&data_source=${data.dataSource}${ruleCount === 0 ? '&open=true' : ''}`)}
              >Add Rule {
                loadRulesCountLoading ?
                  <Skeleton style={{ height: 20, width: 10 }} variant="rectangular" /> :
                  <Text.RegularText>({ruleCount})</Text.RegularText>
              }</Button>
            </Flex>
            <Flex direction="column">
              {progress <= threshold ? (<Button
                onClick={() =>
                browserHistory
                .push('/settings/taxonomyV2/mapping-review/' +
                `${data.dataSource}/${data.taxonomyType}`)}
              >{getReviewText(progress)}</Button>) : <Text.RegularText>
                You are upto date.
                Go ahead and publish data mappings for { DATA_SOURCE_ABBREVIATIONS[dataSource]}
              </Text.RegularText>
                }
            </Flex>
          </Flex>
        </Card.Content>
      </Card>
    </NodeContainer>
  );
};

TopLevelNodeComponent.propTypes = {
  data: PropTypes.any,
  isConnectable: PropTypes.bool,
  reviewPercentages: PropTypes.object,
  mappingTotalsAndPercentages: PropTypes.object,
  reviewPercentagesLoading: PropTypes.bool,
  mappingDataLoading: PropTypes.bool,
  loadRulesCountData: PropTypes.array,
  loadRulesCountLoading: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
  reviewPercentages: reviewPercentageSelector(ownProps.data.dataSource)(state),
  reviewPercentagesLoading:
  loadRecordsReviewStatusCountGenerator.promiseStateSelector(state) === PROMISE_STATES.PENDING,
  mappingTotalsAndPercentages: mappingTotalsAndPercentagesSelector(ownProps.data.dataSource)(state),
  mappingDataLoading: mappingDataLoadingSelector(state),
  loadRulesCountData: loadRulesCountDataSelector(state),
  loadRulesCountLoading: loadRulesCountLoadingSelector(state),
});

const TopLevelNode = connect(mapStateToProps, null)(TopLevelNodeComponent);

export default TopLevelNode;
