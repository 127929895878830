import React, { useState } from 'react';
import { useOrg } from 'hooks';
import { PageHeader } from 'HOCS';
import JobTitlesHeader from '../components/JobTitlesHeader';
import { Card, Flex, Loading, Tooltip } from '@sixsense/core/components';
import ListItem from '../../SalesConfig/routes/Technologies/components/ListItem';
import { useSharedState, useRequest } from '@sixsense/core';
import { css } from 'styles/emotion';
import {
  jobTitlesSearchState,
  jobTitlesState,
  userJobTitlesState,
} from '../state';
import { AccessDenied, Button, DropdownSearch, Modal } from 'v2-components';
import DnDList from '../../SalesConfig/components/DnDList';
import { get, map } from 'lodash';
import { actions as globalActions } from 'modules/global';
import { useDispatch } from '@sixsense/core/versioned/react-redux';
import ProductCategorySelect from '../../SI-Psychographics/components/ProductCategorySelect';
import useCurrentUser from 'hooks/useCurrentUser';
import {
  JOB_TITLES_CONFIRM_TEXT,
  JOB_TITLE_CONFIRM_TEXT,
  ADD_JOB_TITLES_SUCCESS_MSG,
  DELETE_JOB_TITLE_SUCCESS_MSG,
  ORDER_JOB_TITLE_SUCCESS_MSG,
  JOB_TITLE_ERROR_MSG,
  JOB_TITLE_DUPLICATE_ERROR_MSG,
  JOB_TITLE_DISABLED_TOOTLTIP,
} from '../constants';
import moment from 'moment';
import { AddJobTitlesType, OrderJobTitlesType, ProductCategory, UserJobTitlesType } from '../types';
import { withPermissionBoundary } from '@sixsense/rbac/hocs';
import { compose } from 'redux';
import { SalesPermission } from '@sixsense/rbac';
import { connect } from 'react-redux';
import { siPackagePlanNameSelector } from 'routes/Settings/selectors';
import PropTypes from 'prop-types';
import { SI_PlANS, MAINTENANCE_INFO } from 'routes/Settings/constants';
import { getTechnoPsychoJobCreatedDate
  , reduceTechoPsychoJobDataByProduct } from '../../SI-Psychographics/utils';
import { PRODUCT_LABELS } from '../../SI-Psychographics/constants';
import { orgObjSelector } from 'modules/user/selectors';

const styles = {
  categoryDropdown: css({
    width: '200px',
    height: '36px',
  }),
  category: css({
    width: '100%',
  }),
  container: css({
    width: '100%',
  }),
  psychographicsDropdown: css({
    height: '100%',
    padding: '6px',
  }),
  jobTitlesListWrapper: css({
    background: 'white',
    border: '1px solid #C6CACE',
    marginTop: '20px',
    padding: '0px !important',
  }),
  dndList: css({
  }),
  searchBar: css({
    width: '350px',
    flex: '0 1 auto',
    paddingTop: '4px',
  }),
  buttonStyle: css({
    height: '36px',
  }),
  removeButtonStyle: css({
    height: '36px',
    background: '#FF583D',
    borderRadius: '2px',
  }),
  removeButtonLabel: css({
    color: '#ffffff !important',
  }),
  headerRow: css({
    borderBottom: '1px solid #c6cace',
    padding: '15px 30px',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
  }),
  error: css({
    marginTop: '10px',
  }),
  actionComponent: css({
    textAlign: 'left',
  }),
  actionComponentHeader: css({
    textAlign: 'left',
    fontWeight: 'bold',
  }),
  actionComponentFlex: css({
    justifyContent: 'space-between',
  }),
};

const JobTitlesContainer = ({ siPackagePlanName, org }) => {
  const { id: orgId } = useOrg();
  const user = useCurrentUser();
  const request = useRequest();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useSharedState(
    jobTitlesSearchState
  );
  const [jobTitles, setJobTitles] =
    useSharedState(jobTitlesState);
  const [userJobTitles, setUserJobTitles] = useSharedState(
    userJobTitlesState
  );
  const [selectedCategory, setSelectedCategory] = useState<ProductCategory>(Object);
  const [selectedJobTitle, setSelectedJobTitle] = useState(null);
  const [loading, setLoading] = useState<Boolean>(false);

  if (!siPackagePlanName || siPackagePlanName === SI_PlANS.PLAN_F) return <AccessDenied />;
  if (MAINTENANCE_INFO.with_read_only) return <AccessDenied message={MAINTENANCE_INFO.message} />;

  const isAllCategoriesSelected = selectedCategory?.name === 'all';
  const productsLabels = [
    ...get(org, 'packages.SI.products', []),
    ...PRODUCT_LABELS,
  ].reduce((acc, data) => ({ ...acc, [data.name]: data.display_name }), {});

  const alertMessage = (type: string, msg: string) => {
    dispatch(globalActions.showNotification(type, msg));
  };
  const checkSelected = () =>
    userJobTitles.length &&
    userJobTitles.every((el: any) => el.isSelected);
  const disableRemoveButton = () =>
    userJobTitles.length < 1 ||
    !userJobTitles.some((el) => el.isSelected);

  const onCheckSelectAll = (el) => {
    setUserJobTitles(
      userJobTitles.map((x) => ({ ...x, isSelected: el.target.checked }))
    );
  };

  const checkListItem = (id: number) => {
    setUserJobTitles(
      userJobTitles.map((x) =>
        x.id === id ? { ...x, isSelected: !x.isSelected } : x
      )
    );
  };

  const resetState = () => {
    setSearchInput('');
    setJobTitles([]);
    setSelectedJobTitle(null);
  };

  const onChangeCategory = () => {
    resetState();
    getUserJobTitles();
  };

  const addJobTitles = async () => {
    if (userJobTitles.some((el) => el.name.toLowerCase() === selectedJobTitle.toLowerCase())) {
      alertMessage('error', JOB_TITLE_DUPLICATE_ERROR_MSG);
      return;
    }
    const body: AddJobTitlesType = {
      name: selectedJobTitle,
      org_id: orgId,
      product: selectedCategory.name,
      created_by: user.id,
      updated_by: user.id,
    };
    try {
      setLoading(true);
      await request(
        `settings/${orgId}/job-titles/?product=${selectedCategory.name}`,
        {
          method: 'POST',
          body: JSON.stringify(body),
        }
      );
      setLoading(false);
      getUserJobTitles();
      resetState();
      alertMessage('success', ADD_JOB_TITLES_SUCCESS_MSG);
    } catch (e) {
      setLoading(false);
      if (e.body && e.body?.name?.length) {
        alertMessage('error', e.body.name[0]);
        return;
      }
      alertMessage('error', JOB_TITLE_ERROR_MSG);
    }
  };

  const removeJobTitles = async () => {
    const selectedTechnographics = userJobTitles
      .filter((el) => el.isSelected)
      .map((e) => ({ id: e.id }));
    try {
      await request(`settings/${orgId}/job-titles/bulk_delete/`, {
        method: 'PATCH',
        body: JSON.stringify(selectedTechnographics),
      });
      getUserJobTitles();
      alertMessage(
        'success',
        DELETE_JOB_TITLE_SUCCESS_MSG
      );
    } catch (e) {
      alertMessage('error', JOB_TITLE_ERROR_MSG);
    }
  };

  const orderJobTitles = async (oldIndex, newIndex) => {
    const body: OrderJobTitlesType = {
      order: userJobTitles[newIndex].order,
    };
    try {
      await request(
        `settings/${orgId}/job-titles/${userJobTitles[oldIndex].id}
/?product=${selectedCategory.name}`,
        {
          method: 'PATCH',
          body: JSON.stringify(body),
        }
      );
      getUserJobTitles();
      alertMessage('success', ORDER_JOB_TITLE_SUCCESS_MSG);
    } catch (e) {
      alertMessage('error', JOB_TITLE_ERROR_MSG);
    }
  };

  const getUserJobTitles = async () => {
    try {
      setLoading(true);
      const isSingleProduct = !isAllCategoriesSelected
      ? `?product=${selectedCategory?.name}`
      : '';
      let result: UserJobTitlesType = await request(
        `settings/${orgId}/job-titles/${isSingleProduct}`
      );
      if (!isSingleProduct) {
        result = reduceTechoPsychoJobDataByProduct(result);
      }
      setUserJobTitles(result || []);
      setLoading(false);
    } catch (ex) {
      setUserJobTitles([]);
      setLoading(false);
    }
  };
  const productsTooltip = (products) => (
    <Tooltip
      overlay={
        <div>
          {products.map((el) => (
            <div>{productsLabels[el.product] || el.product} </div>
          ))}
        </div>
      }
      placement="right"
    >
      <Flex>
        <a>&nbsp;+{products.length} more</a>
      </Flex>
    </Tooltip>
  );
  const jobTitlesMappedList = map(userJobTitles, (el, index) => (
    <ListItem
      siDisabled={isAllCategoriesSelected}
      index={index + 1}
      key={el.id}
      label={el.name}
      ActionComponent={
        <Flex className={styles.actionComponentFlex}>
          <div className={styles.actionComponent}>
            <Flex>
              {productsLabels[el.product] || el.product}
              {el.products && el.products.length
                ? productsTooltip(el.products)
                : ''}
            </Flex>
          </div>
          <div className={styles.actionComponent}>
            {moment(getTechnoPsychoJobCreatedDate(el)).format('MMM DD, YYYY')}
          </div>
        </Flex>
      }
      isSelected={el.isSelected}
      onCheck={() => checkListItem(el.id)}
    />
  ));

  const removeLabel =
    userJobTitles.filter((el) => el.isSelected).length > 1
      ? JOB_TITLES_CONFIRM_TEXT
      : JOB_TITLE_CONFIRM_TEXT;

  return (
    <div id={'job_titles'}>
      {loading && <Loading.Spinner level="page" />}
      <Flex direction="row" gap={8}>
        <ProductCategorySelect
          onChange={onChangeCategory}
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
        />
        <div className={styles.searchBar} style={{ flex: 1 }}>
          {
            <DropdownSearch
              iconPlacement={false}
              id={'job_titles_search'}
              handleSearchResultSelect={(technology) => {
                setSelectedJobTitle(technology);
              }}
              searchData={jobTitles}
              mapOption="jobTitle"
              handleInput={(userInput) => {
                if (!isAllCategoriesSelected) {
                  if (userInput && userInput.length > 128) {
                  // eslint-disable-next-line no-param-reassign
                    userInput = userInput.slice(0, 128);
                  }
                  setSearchInput(userInput);
                  if (userInput) {
                    setJobTitles([{
                      jobTitle: userInput,
                      value: userInput,
                      key: userInput,
                    }]);
                  } else {
                    setJobTitles([]);
                  }
                  if (selectedJobTitle) {
                    setSelectedJobTitle(null);
                  }
                }
              }}
              value={searchInput}
              valueAccessor={'jobTitle'}
              searchBar
              placeholder={'Add Relevant Title'}
            />
          }
        </div>
        <div style={{ flex: 1 }}>
          <Button
            tooltipContent={isAllCategoriesSelected && JOB_TITLE_DISABLED_TOOTLTIP}
            className={styles.buttonStyle}
            onClick={addJobTitles}
            type="primary"
            disabled={!selectedJobTitle ||
               !selectedCategory.name || loading || isAllCategoriesSelected}
          >
            Add
          </Button>
        </div>
        <div>
          <Button
            disabled={disableRemoveButton()}
            labelClass={styles.removeButtonLabel}
            className={styles.removeButtonStyle}
            onClick={() =>
              Modal.prompt({
                type: Modal.PROMPT_TYPES.CONFIRM,
                title: removeLabel,
                onOk() {
                  removeJobTitles();
                },
              })
            }
          >
            Remove
          </Button>
        </div>
      </Flex>
      <Card.Content className={styles.jobTitlesListWrapper} >
        <ListItem
          label={`Titles (${jobTitlesMappedList.length})`}
          isSelected={checkSelected()}
          onCheck={(el) => {
            if (!isAllCategoriesSelected) {
              onCheckSelectAll(el);
            }
          }}
          ActionComponent={<Flex className={styles.actionComponentFlex}>
            <div className={styles.actionComponentHeader}>
            Product Category
          </div>
            <div className={styles.actionComponentHeader}>Created On</div>
          </Flex>}
        />
        {
          jobTitlesMappedList.length ?
            <DnDList
              scrollableId={'appBody'}
              options={jobTitlesMappedList}
              onSortEnd={({ oldIndex, newIndex }) => {
                if (oldIndex !== newIndex && !isAllCategoriesSelected) {
                  orderJobTitles(oldIndex, newIndex);
                }
              }}
            />
            : null
        }
      </Card.Content>
    </div>
  );
};

const mapStateToProps = (state) => ({
  siPackagePlanName: siPackagePlanNameSelector(state),
  org: orgObjSelector(state),
});
JobTitlesContainer.propTypes = {
  siPackagePlanName: PropTypes.string,
  org: PropTypes.object,
};

export default compose(
  withPermissionBoundary({
    allow: (permissions) => [
      SalesPermission.SETTINGS_JOBTITLES_VIEW,
      SalesPermission.SETTINGS_JOBTITLES_EDIT,
    ].some((permission) => permissions.has(permission)),
    renderDenied: () => <AccessDenied />,
  }),
  connect(mapStateToProps),
  PageHeader([JobTitlesHeader]),
)(JobTitlesContainer);
