import { filter, get, isEmpty, pickBy, some } from 'lodash';
import {
  orgFlagsSelector,
  userIsDevSelector,
  userIsInternalSelector,
  userIsStaffSelector,
  userIsSuperuserSelector,
} from 'modules/user/selectors';
import { createSelector } from 'reselect';
import {
  DATA_SOURCE_FLAG_NAMES,
  DUMMY_PRODUCT_FLAG,
  DUMMY_PRODUCT_NAME,
  DUMMY_PRODUCT_VALUE,
  EVENT,
  HAS_OTHER_PRODUCTS_WITHOUT_ADVANCED_PACKAGE,
  KEYWORD_EXPERIMENT_PRODUCT,
  PRODUCT,
  TURBOTAX_SI_PACKAGE_PLAN_NAMES,
  REVIEW_ACCESS_INTERNAL_USER_FLAG,
  WEB,
  CRM,
  MAP,
  CRM_EVENT,
  CRM_TASK,
} from './constants';
import {
  loadMappedActivitesStateGenerator,
  loadRecordsReviewStatusCountGenerator,
} from './stateGenerators';
import { getDateRanges } from './utils';

export const taxonomySelector = (state) => state.taxonomyV2;

export const commonSelector = createSelector(taxonomySelector, (taxonomy) => taxonomy.common);

export const productListSelector = (state) => {
  const allEnabledProducts = pickBy(
    get(state, 'user.user.organization.allProducts', {}),
    ({ is_deleted }) => !is_deleted
  );
  const siPackage = get(state, 'user.user.organization.packagePlan.SI', false);

  if (siPackage && TURBOTAX_SI_PACKAGE_PLAN_NAMES.has(siPackage.plan.name)) {
    return filter(
      get(state, 'user.user.organization.packages.SI.products', []),
      ({ is_deleted, name }) =>
        !is_deleted &&
        name !== KEYWORD_EXPERIMENT_PRODUCT &&
        allEnabledProducts[name]
    );
  }
  return filter(
    get(state, 'user.user.organization.packages.predictive.products', []),
    ({ is_deleted, name }) =>
      !is_deleted &&
      name !== KEYWORD_EXPERIMENT_PRODUCT &&
      allEnabledProducts[name]
  );
};

export const isSingleProductSelector = createSelector(
  productListSelector,
  orgFlagsSelector,
  (products, orgFlags) => {
    const hasOtherProducts = get(
      orgFlags,
      HAS_OTHER_PRODUCTS_WITHOUT_ADVANCED_PACKAGE
    );
    return !hasOtherProducts && products.length <= 1;
  }
);

export const taxonomyProductsSelector = createSelector(
  productListSelector,
  orgFlagsSelector,
  (products, orgFlags) => {
    // This is for the case where customers have other products
    // that are not predictive/registered with 6sense. In this
    // case, they will have to add a "dummy" product category
    // called 'Others' to classify the ones that are not registered.
    const hasOtherProducts = get(orgFlags, DUMMY_PRODUCT_FLAG);
    if (hasOtherProducts) {
      return products.concat({
        display_name: DUMMY_PRODUCT_NAME,
        name: DUMMY_PRODUCT_VALUE,
      });
    }
    return products;
  }
);


export const generateStatusTypesForReviews = (type) => {
  if (['crm_task', 'crm_event'].includes(type)) {
    return {
      reviewed: [
        `${type}_event_reviewed`,
      ],
      unreviewed: [
        `${type}_event_unreviewed`,
      ],
      activity: [
        `${type}_event_reviewed`,
        `${type}_event_unreviewed`,
      ],
    };
  }
  return {
    reviewed: [
      `${type}_event_reviewed`,
      `${type}_product_reviewed`,
    ],
    unreviewed: [
      `${type}_event_unreviewed`,
      `${type}_product_unreviewed`,
    ],
    activity: [
      `${type}_event_reviewed`,
      `${type}_event_unreviewed`,
    ],
    product: [
      `${type}_product_reviewed`,
      `${type}_product_unreviewed`,
    ],
  };
};

export const reviewPercentageSelector = (type) =>
  createSelector(loadRecordsReviewStatusCountGenerator.dataSelector, (counts) => {
    if (!counts) return {};
    const req_counts = counts.filter(({ review_status }) => review_status.includes(type));
    const valuesMap = req_counts.reduce((acc, currCount) => {
      const key = currCount.review_status;
      const value = currCount.review_status_count;
      acc[key] = value;
      return acc;
    }, {});
    const types = generateStatusTypesForReviews(type);
    const typesArray = Object.keys(types);
    const totalsMap = typesArray.reduce((vMap, key) => {
      // eslint-disable-next-line no-param-reassign
      vMap[`total${key}`] = types[key].reduce((acc, _type) => {
        // eslint-disable-next-line no-param-reassign
        acc += get(valuesMap, _type, 0);
        return acc;
      }, 0);
      return vMap;
    }, {});


    const { totalreviewed, totalunreviewed, totalactivity, totalproduct = 0 } = totalsMap;

    const totalCases = totalreviewed + totalunreviewed;
    const activityReviewed = get(valuesMap, types.activity[0], 0);
    let productReviewed = 0;

    const totalPercentage = totalCases === 0 ? 0 :
      Math.round(totalreviewed * 100 / totalCases);
    const activityPercentage = totalactivity === 0 ? 0 :
      Math.round(activityReviewed * 100 / totalactivity);
    let productPercentage = 0;
    if (!['crm_task', 'crm_event'].includes(type)) {
      productReviewed = get(valuesMap, types.product[0], 0);
      productPercentage = totalproduct === 0 ? 0 :
        Math.round(productReviewed * 100 / totalproduct);
    }

    return {
      totalPercentage,
      activityPercentage,
      productPercentage,
      ...totalsMap,
      totalCases,
      activityReviewed,
      productReviewed,
    };

  });

export const allTotalUnreviewedSelector = createSelector(
  reviewPercentageSelector(WEB),
  reviewPercentageSelector(MAP),
  reviewPercentageSelector(CRM),
  reviewPercentageSelector(CRM_EVENT),
  reviewPercentageSelector(CRM_TASK),
  (web, map, crm, crmEvent, crmTask) => [
      { data_source: WEB, totalUnreviewed: web.totalunreviewed },
      { data_source: MAP, totalUnreviewed: map.totalunreviewed },
      { data_source: CRM, totalUnreviewed: crm.totalunreviewed },
      { data_source: CRM_EVENT, totalUnreviewed: crmEvent.totalunreviewed },
      { data_source: CRM_TASK, totalUnreviewed: crmTask.totalunreviewed },
  ]
);


const commonStateSelector = (state) => state.taxonomyV2.common;
const overviewDataSelector = createSelector(commonStateSelector, (state) => state.loadStatuses);

export const dateRangeSelector =
  createSelector(commonStateSelector, (state) => state.dateRangeOption.dateRange);

export const loadRulesCountSelector = createSelector(commonStateSelector,
  (state) => state.loadRulesCount);

export const loadRulesCountDataSelector = createSelector(loadRulesCountSelector,
  (state) => state.rulesCount);

export const loadRulesCountLoadingSelector = createSelector(loadRulesCountSelector,
  (state) => state.loading);

export const getStartAndEndDateSelector = createSelector(dateRangeSelector,
  (dateRange) => getDateRanges(dateRange));

export const mappingDataLoadingSelector =
  createSelector(overviewDataSelector, (state) => state.loading);

const generateStatusTypesForMappings = (type, isSingleProduct) => {
  const retVal = {
    activity: [
      `${type}_event_mapped`,
      `${type}_event_unmapped`,
      `${type}_event_excluded`,
    ],
  };
  if (['crm_task', 'crm_event'].includes(type) || isSingleProduct) {
    return retVal;
  }

  return {
    activity: [
      `${type}_event_mapped`,
      `${type}_event_unmapped`,
      `${type}_event_excluded`,
    ],
    product: [
      `${type}_product_mapped`,
      `${type}_product_unmapped`,
      `${type}_product_excluded`,
    ],
  };
};

export const mappingTotalsAndPercentagesSelector = (type) =>
  createSelector(isSingleProductSelector,
    overviewDataSelector, (isSingleProduct, { overviewStatus }) => {
      if (!overviewStatus) return [];
      const req_counts = overviewStatus.filter(({ status }) => status.startsWith(type));
      const valuesMap = req_counts.reduce((acc, currCount) => {
        const key = currCount.status;
        const value = currCount.status_count;
        acc[key] = value;
        return acc;
      }, {});
      const types = generateStatusTypesForMappings(type, isSingleProduct);
      const typesArray = Object.keys(types);
      const totalsArray = typesArray.reduce((vArr, key) => {
        const totals = types[key].reduce((acc, _type) => {
          const currVal = get(valuesMap, _type, 0);
          const splitName = _type.split('_');
          const currKey = splitName[splitName.length - 1];
          acc[currKey] = currVal;
          acc.total += currVal;
          return acc;
        }, { total: 0 });
        const totalsWithPercentages = Object.keys(totals).reduce((acc, _key) => {
          if (_key === 'total') {
            acc[_key] = totals.total;
            return acc;
          }
          acc[`${_key}Percentage`] =
          totals.total ? Math.round(totals[_key] * 100 / totals.total) : 0;
          acc[_key] = totals[_key];
          return acc;
        }, {});
        vArr.push({ type: key, ...totalsWithPercentages });
        return vArr;
      }, []);
      return totalsArray;
    });

export const dataSourcePresentInStatusSelector = (type) =>
  createSelector(overviewDataSelector, dateRangeSelector,
  ({ entitiesArray, dateRange }) =>
    entitiesArray.some((obj) => obj.dataSource === type && obj.dataRange === dateRange));

export const countByMappingBaseSelector = createSelector(taxonomySelector, (state) =>
    state.common.loadCountByMapping);

export const countByMappingSelector = createSelector(countByMappingBaseSelector,
    ({ countByMapping }) => {
      // this would give a result like { 'activity': { }, 'product': { } }
      if (isEmpty(countByMapping)) {
        return {};
      }
      const keyMap = { event: 'activity', product: 'product' };
      const keys = Object.keys(countByMapping);
      const exclusionList = ['__do_not_map__', null];
      return keys.reduce((acc, currKey) => {
        const values = countByMapping[currKey];
        acc[currKey] = values.reduce((acc1, obj) => {
          if (exclusionList.includes(obj[keyMap[currKey]])) {
            return acc1;
          }
          // eslint-disable-next-line no-param-reassign
          acc1[obj[keyMap[currKey]]] = obj.cnt;
          return acc1;
        }, {});
        return acc;
      }, {});
    });

const getProcessedActivities = (rawActivities, dataSource) => {
  const exclusionList = ['__do_not_map__', null];
  const filteredActivities =
  rawActivities
  .filter((obj) => obj.data_source === dataSource)
  .filter((obj) =>
    obj.data_source === WEB ? obj.field_type === 'activity' : obj.field_type === 'action')
  .filter((obj) => !exclusionList.includes(obj.value));

  const returnVal = filteredActivities.flatMap((activity) => {
    if (!isEmpty(activity.relations)) {
      return activity.relations.flatMap(({ label, value, type_relation, ...others }) => {
        let res = [];
        if (!isEmpty(type_relation)) {
          res = type_relation.map((tr) => ({
            label: `${activity.label} ↪ ${label} ↪ ${tr.label}`,
            value: `${activity.value} ${value} ${tr.value}`,
            qcfilters: {
              status: ['Mapped'],
              activity_mapping: [
                {
                  predicted_action: `${activity.value}`,
                  predicted_channel: `${value}`,
                  mapped_type: `${tr.value}`,
                },
              ],
            },
            ...others,
          }));
        }
        const parentActivity = {
          label: `${activity.label} ↪ ${label}`,
          value: `${activity.value} ${value}`,
          field_type: activity.field_type,
          qcfilters: {
            status: ['Mapped'],
            activity_mapping: [
              {
                predicted_action: `${activity.value}`,
                predicted_channel: `${value}`,
              },
            ],
          },
          ...others,
        };
        return [parentActivity, ...res];
      });
    }
    // this belongs to web
    return {
      ...activity,
      usedInModels: activity.used_in_models,
      qcfilters: {
        status: ['Mapped'],
        activity_mapping: [
          {
            event: `${activity.value}`,
          },
        ],
      },
    };
  });
  return returnVal;
};

// specific selector written for mappingParentNode.js
export const mappedActivitiesSelector = (dataSource, type, usedInModels) =>
  createSelector(loadMappedActivitesStateGenerator.dataSelector,
    taxonomyProductsSelector,
    countByMappingSelector,
    (activities, products, counts) => {
      if (isEmpty(counts) && isEmpty(counts[EVENT]) && isEmpty(counts[PRODUCT])) {
        return [];
      }
      if (type === EVENT) {
        const activityCounts = counts[EVENT];
        const values = Object.values(activityCounts);
        const globalTotal = values.reduce((acc, val) => {
          // eslint-disable-next-line no-param-reassign
          acc += val;
          return acc;
        }, 0);
        const processedActivities = getProcessedActivities(activities, dataSource);
        const filteredData =
        processedActivities
        .filter((obj) => obj.usedInModels === usedInModels);
        const total = filteredData.reduce((acc, obj) => {
          // eslint-disable-next-line no-param-reassign
          acc += activityCounts[obj.value] || 0;
          return acc;
        }, 0);
        const nodes = filteredData.map((data) => ({
          title: data.label,
          activityCount: activityCounts[data.value] || 0,
          taxonomyType: 'event',
          percentage: activityCounts[data.value] ?
          parseFloat((activityCounts[data.value]/total) * 100).toFixed(2) : 0,
          subType: data.model_type,
          qcfilters: data.qcfilters,
          dataSource,
        })).sort((a, b) => b.activityCount - a.activityCount);
        return { nodes, total, globalTotal };
      } else if (type === PRODUCT) {
        const productCounts = counts[PRODUCT];
        const total = productCounts ? productCounts.all: 0;
        const nodes = products.map((product) => ({
          title: product.display_name,
          activityCount: productCounts ? productCounts[product.name]: 0,
          taxonomyType: 'product',
          dataSource,
          qcfilters: {
            products: [`${product.name}`],
          },
        }));
        return { nodes, total };
      }
      return [];
    });

export const hasPublishedMappingsSelector = (dataSource) => createSelector(
  commonStateSelector,
  orgFlagsSelector,
  ({ publishMapping }, orgFlags) => {
    // if turbotax_ready is false for org, we don't want to show publish mapping for that org
    if (!get(orgFlags, DATA_SOURCE_FLAG_NAMES[dataSource])) {
      return true;
    }
    const valuesObj = get(publishMapping.mappingsPublished, [dataSource], {});
    return Object.keys(valuesObj).reduce((acc, key) => {
      // eslint-disable-next-line no-param-reassign
      acc = acc && valuesObj[key];
      return acc;
    }, true);
  }
);

export const isReviewAccessUserSelector = createSelector(
  orgFlagsSelector,
  userIsDevSelector,
  userIsStaffSelector,
  userIsInternalSelector,
  userIsSuperuserSelector,
  (orgFlags, ...userTypes) => !get(orgFlags, REVIEW_ACCESS_INTERNAL_USER_FLAG) && some(userTypes)
);
