import React, { useEffect, useState } from 'react';
import { Card, Flex, Modal } from '@sixsense/core/components';
import { Button, Input, Text } from 'v2-components';
import { css } from 'styles/emotion';
import { useSharedState } from '@sixsense/core';
import { creditValidationState } from '../state';

const styles = {
  card: css({
    width: 360,
    height: 256,
  }),
  errorMessage: css({
    color: '#D92D20',
    marginTop: '-12px',
  }),
};

type Props = {
  isOpen: boolean;
  onConfirm: (credits: number) => void;
  onReject: () => void;
  validateAddCredit: (credits: number) => boolean;
};

const AddCreditModal = ({ isOpen, onConfirm, onReject, validateAddCredit }: Props) => {
  const [credits, setCredits] = useState(0);
  const [creditValidation] = useSharedState(creditValidationState);
  const reset = () => {
    setCredits(0);
  };

  useEffect(() => {
    validateAddCredit(credits);
  }, [credits]);

  return open ? (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        onReject();
        reset();
      }}
    >
      <Card className={styles.card}>
        <Card.Header>
          <Card.Header.Title style={{ textTransform: 'none' }}>
            Add Credits
          </Card.Header.Title>
        </Card.Header>
        <Card.Content style={{ minHeight: 124 }}>
          <Flex direction="column" gap={16}>
            <Text>Amount of credits to add for each person</Text>
            <Input
              type="number"
              value={credits}
              onChange={(e) => setCredits(e.target.value)}
            />
            { creditValidation.disabled && <div className={styles.errorMessage}>
              {creditValidation.error}</div>}
          </Flex>
        </Card.Content>
        <Card.Footer>
          <Flex justifyContent="flex-end">
            <Button
              onClick={() => {
                onReject();
                reset();
              }}
            >
              Cancel
            </Button>
            <div style={{ width: 8 }} />
            <Button
              disabled={creditValidation.disabled}
              type="primary"
              onClick={() => {
                onConfirm(credits);
                reset();
              }}
            >
              Add
            </Button>
          </Flex>
        </Card.Footer>
      </Card>
    </Modal>
  ) : null;
};

type Executor =
  | {
      resolve: (value: any) => void;
      reject: (reason?: any) => void;
    }
  | undefined;

const useConfirm = ({ validateAddCredit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [executor, setExecutor] = useState<Executor>(undefined);

  const confirm = () =>
    new Promise((resolve, reject) => {
      setExecutor({ resolve, reject });
      setIsOpen(true);
    });

  const onConfirm = (credits: number) => {
    if (executor) {
      executor.resolve({ credits });
      setIsOpen(false);
      setExecutor(undefined);
    }
  };

  const onReject = () => {
    if (executor) {
      executor.reject();
      setIsOpen(false);
      setExecutor(undefined);
    }
  };

  return {
    confirm,
    modalProps: {
      isOpen,
      onConfirm,
      onReject,
      validateAddCredit,
    },
  };
};

export default Object.assign(AddCreditModal, { useConfirm });
