import React, { useCallback, useState, useEffect, useRef } from 'react';
import { differenceBy } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useReactFlow } from 'reactflow';
import {
  Button,
  ButtonGroup,
  Card,
  Flex,
  Skeleton,
  Text,
} from '@sixsense/core/components';
import { ChevronDown, ChevronUp } from '@sixsense/core/icons';
import NodeContainer from './NodeContainer';
import { mappedActivitiesSelector,
  countByMappingBaseSelector,
 } from '../../../selectors';
import { MAP, CRM, CRM_EVENT, CRM_TASK } from '../../../constants';
import { DesignSystemColor } from '@sixsense/core/style';

function getYPos(y, i) {
  if (i > 1) {
    return y + (110 * (i + 2));
  } else if (i === 1) {
    return y + 325;
  }
  return y + 200;
}

function createNodesAndEdges(
  childNodes,
  startingNodeId,
  startingPos,
  usedInModels = false,
  filter,
  ) {
  const nodes = [];
  const x = startingPos.x;
  const y = startingPos.y;
  // let recentNodeId = startingNodeId;
  let sourceNodes = childNodes;
  if (filter !== 'all') {
    sourceNodes = sourceNodes.filter((node) => node.subType === filter);
  }
  sourceNodes.forEach((sNode, i) => {
    const nodeId = `${startingNodeId}-${sNode.subType}-${sNode.title}`;
    const yPos = getYPos(y, i);
    const position = { x, y: yPos };
    const data = { usedInModels, ...sNode };
    const node = {
      id: `${nodeId.toString()}`,
      type: 'MAPPED_CHILD_NODE',
      data,
      position,
    };
    nodes.push(node);

    // edges.push({
    //   id: `${recentNodeId}-${nodeId}`,
    //   source: `${recentNodeId.toString()}`,
    //   target: `${nodeId.toString()}`,
    // });

    // recentNodeId = nodeId;
  });
  return { nodes };
}

function getTitle(data) {
  const { taxonomyType, dataSource } = data;
  if (taxonomyType === 'product') {
    return 'All Products';
  }
  if (dataSource === MAP || dataSource === CRM_EVENT || dataSource === CRM_TASK) {
    return 'All Action ↪ All Channel';
  }
  if (dataSource === CRM) {
    return 'All Action ↪ All Channel ↪ All Type';
  }
  return 'All Activities';
}


// we have to get the child nodes data via redux because I'm not sure
// about the feasibility of prop passing
const MappedParentNodeComponent = ({
  data,
  isConnectable,
  mappedActivities,
  loading,
  ...props }) => {

  const [isExpanded, setExpanded] = useState(false);
  const [prevNodes, setPrevNodes] = useState([]);
  const [childNodes, setChildNodes] = useState([]);
  const [filter, setFilter] = useState('all');
  const reactFlow = useReactFlow();
  const { setNodes } = reactFlow;

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const currentDataSource = usePrevious(data.dataSource);

  useEffect(() => {
    if (currentDataSource !== data.dataSource) {
      setFilter('all');
      setExpanded(false);
    }
  }, [data.dataSource, currentDataSource]);

  useEffect(() => {
    setChildNodes(mappedActivities.nodes);
  }, [mappedActivities]);

  const { usedInModels, taxonomyType } = data;
  const activityCount = mappedActivities.total;
  // so that we avoid divide by 0
  const globalTotal = mappedActivities.globalTotal || 1;

  const updatePos = useCallback(() => {
    const { xPos, yPos, id } = props;
    const position = { x: xPos, y: yPos };
    const { nodes: newNodes } =
      createNodesAndEdges(childNodes, id, position, usedInModels, filter);
    if (!isExpanded) {
      setNodes((oldNodes) => [...differenceBy(oldNodes, prevNodes, 'id'), ...newNodes]);
      setPrevNodes(newNodes);
      setExpanded(true);
    } else {
      setNodes((oldNodes) => differenceBy(oldNodes, prevNodes, 'id'));
      setExpanded(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNodes, isExpanded, usedInModels, filter, childNodes]);

  const changeFilter = useCallback((filterVal) => {
    const { xPos, yPos, id } = props;
    const position = { x: xPos, y: yPos };
    const { nodes: newNodes } =
      createNodesAndEdges(childNodes, id, position, usedInModels, filterVal);
    setNodes((oldNodes) => [...differenceBy(oldNodes, prevNodes, 'id'), ...newNodes]);
    setPrevNodes(newNodes);
    setFilter(filterVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNodes, usedInModels, setPrevNodes, prevNodes, childNodes]);
  return (
    <NodeContainer data={data} isConnectable={isConnectable}>
      <Card
        style={{
          margin: '0',
          width: '300px',
          height: '165px',
          borderTop: `4px solid ${(usedInModels || taxonomyType === 'product') ?
            DesignSystemColor.Green.DEFAULT : DesignSystemColor.Gray.DEFAULT}`,
        }}
      >
        <Card.Content>
          <Flex direction="column">
            <Flex justifyContent="space-between">
              <Text.Title>{getTitle(data)}</Text.Title>
              {/* <ChevronRight
                onClick={() => console.log('coming here')}
                style={{ cursor: 'pointer' }}
              /> */}
            </Flex>
            <Flex className="aam-t--5">
              {usedInModels ? (<Text.Title>Used In Predictive Models</Text.Title>) :
                (<Text.Title>Not Used In Predictive Models</Text.Title>)
              }
            </Flex>
            <Flex className="aam-t--2">
              {loading ? (
                <Skeleton style={{ height: 15, width: 100 }} variant="rectangular" />
              ) : (
                <React.Fragment>
                  <Text.RegularText>{
                    Intl.NumberFormat('en-US').format(activityCount)}</Text.RegularText>
                  {taxonomyType === 'event' && (<Text.RegularText>&nbsp;
                    ({parseFloat((activityCount/ globalTotal) * 100).toFixed(2)})%
                    </Text.RegularText>)}
                </React.Fragment>
              )}
            </Flex>
            <Flex className="aam-v--5">
              <Flex style={{ visibility: usedInModels ? 'visible' : 'hidden' }}>
                <ButtonGroup value={filter} onChange={changeFilter}>
                  <ButtonGroup.Button value="all">All</ButtonGroup.Button>
                  <ButtonGroup.Button value="Intent">Intent</ButtonGroup.Button>
                  <ButtonGroup.Button value="Reach">Reach</ButtonGroup.Button>
                </ButtonGroup>
              </Flex>
            </Flex>
            <Flex>
              <Button
                size="sm"
                hierarchy="link-secondary"
                trailingIcon={isExpanded ? ChevronUp : ChevronDown}
                onClick={updatePos}
              >
                {isExpanded ? 'Show Less' : 'Show More'}
              </Button>
            </Flex>
          </Flex>
        </Card.Content>
      </Card>
    </NodeContainer>
  );
};

MappedParentNodeComponent.propTypes = {
  data: PropTypes.any,
  isConnectable: PropTypes.bool,
  mappedActivities: PropTypes.array,
  loading: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
  mappedActivities:
  mappedActivitiesSelector(
    ownProps.data.dataSource,
    ownProps.data.taxonomyType,
    ownProps.data.usedInModels)(state),
  loading: countByMappingBaseSelector(state).loading,
});

const MappedParentNode = connect(mapStateToProps, null)(MappedParentNodeComponent);


export default MappedParentNode;
