import { getMaintenanceInfo } from './utils';

export const SETTINGS_ROUTE = 'settings';

export const SI_PlANS = {
  PLAN_A: 'A',
  PLAN_B: 'B',
  PLAN_C: 'C',
  PLAN_D: 'D',
  PLAN_E: 'E',
  PLAN_F: 'F',
};
export const SI_TRAIL_STATUS = {
  trailInProgress: 'trial_in_progress',
};

export const DATA_SETTINGS_LABEL = 'Data Settings';
export const CUSTOM_VALUE_MAPPING_LABEL = 'Custom Value Mappings';
export const SETTINGS_MANAGE_PATH = '/settings/manage';

export const SYSTEM_DESIGN_PATHS = new Set([
  '/settings/fiscalyear',
]);
export const MAINTENANCE_INFO = getMaintenanceInfo();
