import React, { useState, useEffect } from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PageHeader } from 'HOCS';
import {
  Row,
  Col,
  Checkbox2,
  Text,
  Link,
  AccessDenied,
  Loading2 as Loading,
  Tooltip2 as Tooltip,
  Modal,
} from 'v2-components';
import { pick } from 'lodash';
import {
  DISPLAY_VALUES_ACTIONS,
  SI_APP_COLUMN_HEADERS,
  INTEGRATIONS_LABELS,
  SEP_LINK,
  CRM_LINK,
} from '../constants';
import { SalesPermission, withPermissionBoundary, usePermissionCheck } from '@sixsense/rbac';
import ActionSettingsHeader from '../ActionSettingsHeader';
import { css } from 'styles/emotion';
import useOrg from 'hooks/useOrg';
import { useRequest } from '@sixsense/core';
import { compose } from 'redux';
import { crmTypeSelector, sepTypeSelector } from 'modules/user/selectors';
import { siInternalLicenseTypeSelector } from '../../../selectors';
import { ActionSettingsType } from '../types';
import { siPackagePlanNameSelector } from 'routes/Settings/selectors';
import { InfoCircle } from '@sixsense/core/icons';
import toggleAppImg from '../../../assets/images/toggle_app_example.svg';
import { MAINTENANCE_INFO } from 'routes/Settings/constants';

const styles = {
  checkboxItem: css({
    lineHeight: '15px',
    marginTop: '10px',
  }),
  container: css({
    width: '100%',
    background: '#ffff',
    height: '100%',
    padding: '14px 30px 24px 30px',
  }),
  emptyValue: css({
    padding: '8px',
  }),
  extraMargin: css({
    paddingLeft: '5px',
  }),

  actionHeader: css({
    border: '#ffff',
  }),

  actionRow: css({
    borderBottom: 'solid #E5E7EB 1px',
  }),

  actionColumn: css({
    padding: '16px 16px 16px 16px',
  }),

  flexcenter: css({
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
  }),

  actionContainer: css({
    borderWidth: '1px 1px 1px 1px',
    borderStyle: 'solid',
    borderColor: '#E5E7EB',
  }),
  displayFlex: css({ display: 'flex' }),
  radioBtnsContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginLeft: '45px',
  }),
  radioBtn: css({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  }),
  clickable: css({
    cursor: 'pointer',
    color: '#007BC1',
    fontSize: '14px',
  }),
  flexCol: css({
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  }),
  radioBtnText: css({
    fontSize: '13px',
    color: '#001F32',
  }),
};
const widthOfColumn = `${100 / SI_APP_COLUMN_HEADERS.length}%`;

const SalesAppActionsCardComponent = ({
  crmType,
  sepType,
  siPackagePlanName,
  isInternalEntLite = false,
}) => {
  const { id: orgId } = useOrg();
  const request = useRequest();
  const [actionSettings, setActionSettings] =
    useState<ActionSettingsType>(Object);
  const [loading, setLoading] = useState<Boolean>(false);
  const [visibleModal, setVisibleModal] = useState<Boolean>(false);
  const LoadingComponent: any = Loading.LoadingComponent;
  const hasExportToCrmPermission =
    usePermissionCheck([
      SalesPermission.SETTINGS_SI_ACTIONS_EXPORT_TO_CRM_VIEW,
    ]) && !isInternalEntLite;
  const hasDownloadEnabledPermission =
    usePermissionCheck([
      SalesPermission.SETTINGS_SI_ACTIONS_EXPORT_TO_CSV_VIEW,
    ]) && !isInternalEntLite;
  const hasUnlockEnabledPermission =
    usePermissionCheck([SalesPermission.SETTINGS_SI_ACTIONS_UNLOCK_VIEW]) &&
    !isInternalEntLite;

  const actionNames = pick(
    actionSettings,
    'export_to_crm_enabled',
    'export_to_sep_enabled',
    'download_enabled',
    'export_dashboard_report_enabled',
    'find_on_salesnav_enabled',
    'find_on_linkedin_enabled',
    'unlock_enabled',
    'view_contacts_on_salesnav_enabled',
    'view_contacts_on_linkedin_enabled',
    'no_longer_at_company_enabled',
    'switch_to_old_or_new_app',
    'toggle_app_for_only_si_role'
  );
  const permissions = {
    export_to_crm_enabled: hasExportToCrmPermission,
    download_enabled: hasDownloadEnabledPermission,
    unlock_enabled: hasUnlockEnabledPermission,
    export_dashboard_report_enabled: hasDownloadEnabledPermission,
  };

  useEffect(() => {
    getActionSettings();
  }, []);

  if (!siPackagePlanName) return <AccessDenied />;
  const getActionSettings = async () => {
    try {
      setLoading(true);
      const result: ActionSettingsType = await request(
        `settings/${orgId}/actions-config/`
      );
      setLoading(false);
      setActionSettings({ ...result });
    } catch (ex) {
      setLoading(false);
    }
  };
  if (MAINTENANCE_INFO.with_read_only) return <AccessDenied message={MAINTENANCE_INFO.message} />;

  const updateActionSettings = async (body) => {
    try {
      setLoading(true);
      await request(`settings/${orgId}/actions-config/${actionSettings.id}/`, {
        method: 'PATCH',
        body: JSON.stringify(body),
      });
      getActionSettings();
    } catch (ex) {
      setLoading(false);
    }
  };

  const CRM_LABEL = (
    <div>
      <Link link={CRM_LINK}>Integrate your organization’s CRM instance</Link>
    </div>
  );
  const SEP_LABEL = (
    <div>
      <Link link={SEP_LINK}>Integrate your organization’s SEP instance</Link>
    </div>
  );
  const EMPTY_VALUE = <div className={styles.emptyValue}> - </div>;

  const renderActionExample = () => (
    <Col className={styles.flexCol}>
      <Tooltip
        placement="bottom"
        overlay={
          <span>
            Enables the switch between
            <br /> the old and the new Sales
            <br />
            Intelligence application.
          </span>
        }
      >
        <InfoCircle style={{ marginTop: '6px' }} />
      </Tooltip>
      <span className={styles.clickable} onClick={() => setVisibleModal(true)}>
        Example
      </span>
    </Col>
  );

  const renderAppToggleRadioBtn = () => (
    <div className={styles.radioBtnsContainer}>
      <div className={styles.radioBtn}>
        <Radio.Group
          value={actionNames.toggle_app_for_only_si_role}
          onChange={(evt) => {
            updateActionSettings({
              toggle_app_for_only_si_role: evt.target.value,
            });
          }}
        >
          <Radio value={false}>
            <span className={styles.radioBtnText}>All users</span>
          </Radio>
          <Radio value>
            <span className={styles.radioBtnText}>Users with SI role</span>
          </Radio>
        </Radio.Group>
      </div>
    </div>
  );

  const getContent = (el) => {
    const content = {
      label: <Text> {DISPLAY_VALUES_ACTIONS[el]} </Text>,
      value: (
        <Checkbox2
          size="24px"
          onChange={() => {
            updateActionSettings({ [el]: !actionNames[el] });
          }}
          checked={actionNames[el]}
        />
      ),
      key: el,
    };

    if (el === 'export_to_crm_enabled') {
      return {
        label: crmType ? (
          <Text> {INTEGRATIONS_LABELS[crmType]} </Text>
        ) : (
          <div>
            {content.label}
            {CRM_LABEL}
          </div>
        ),
        value: crmType ? content.value : EMPTY_VALUE,
        key: el,
      };
    }
    if (el === 'export_to_sep_enabled') {
      return {
        label: sepType ? (
          <Text> {INTEGRATIONS_LABELS[sepType]} </Text>
        ) : (
          <div>
            {content.label}
            {SEP_LABEL}
          </div>
        ),
        value: sepType ? content.value : EMPTY_VALUE,
        key: el,
      };
    }
    return content;
  };

  const renderActionNames = Object.keys(actionNames).map((el) => {
    if (el === 'toggle_app_for_only_si_role') return null;
    if (el in permissions && !permissions[el]) return null;
    const content = getContent(el);
    return (
      <Row className={styles.actionRow} key={`${content.key}row_header`}>
        <Col
          className={`${styles.actionColumn} ${styles.flexCol}`}
          flexBasis={'50%'}
        >
          {content.label}
          {el === 'switch_to_old_or_new_app' ? renderActionExample() : null}
        </Col>
        <Col
          className={`${styles.actionColumn} ${
            el === 'switch_to_old_or_new_app' ? styles.displayFlex : ''
          }`}
          flexBasis={'50%'}
        >
          {content.value}
          {el === 'switch_to_old_or_new_app' && actionNames[el]
            ? renderAppToggleRadioBtn()
            : null}
        </Col>
      </Row>
    );
  });

  const renderColHeader = SI_APP_COLUMN_HEADERS.map((item) => (
    <Col
      className={styles.actionColumn}
      key={`${item}row_header`}
      maxWidth={widthOfColumn}
    >
      <Text type={Text.TYPE.BODY}>{item}</Text>
    </Col>
  ));
  return (
    <div className={styles.container}>
      {loading && <LoadingComponent level={'cover'} />}
      <Row className={styles.actionHeader} key="actionSettingsHeaders">
        {renderColHeader}
      </Row>
      <div className={styles.actionContainer}>{renderActionNames}</div>
      <Modal
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        width={'fit-content'}
        closable
      >
        <img
          src={toggleAppImg}
          alt=""
          style={{ marginTop: '36px', width: '600px' }}
        />
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => ({
  crmType: crmTypeSelector(state),
  sepType: sepTypeSelector(state),
  siPackagePlanName: siPackagePlanNameSelector(state),
  isInternalEntLite: siInternalLicenseTypeSelector(state),
});

SalesAppActionsCardComponent.propTypes = {
  crmType: PropTypes.string,
  sepType: PropTypes.string,
  siPackagePlanName: PropTypes.string,
  isInternalEntLite: PropTypes.bool,
};

export default compose(
  withPermissionBoundary({
    allow: (permissions) =>
      [
        SalesPermission.SETTINGS_SI_ACTIONS_VIEW,
        SalesPermission.SETTINGS_SI_ACTIONS_EDIT,
      ].some((permission) => permissions.has(permission)),
    renderDenied: () => <AccessDenied />,
  }),
connect(mapStateToProps),
  PageHeader([ActionSettingsHeader])
)(SalesAppActionsCardComponent);
