import { orgSelector } from 'modules/user/selectors';
import { createSelector } from 'reselect';
import { stateGenerator } from 'routes/Advertising/routes/Campaigns/stateGenerator';

import { get } from 'lodash';
import {
  PRESET_STRING,
} from '../../constants';

export const baseSelector = (state) => state.taxonomyV2.overview;

const loadLastReviewedOnEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `turbotax/taxonomy/org/${orgId}/turbotax_last_reviewed_on/`
);

export const loadLastReviewedOnGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: `${PRESET_STRING}/RECORDS_LAST_REVIEWED`,
  endpointSelector: loadLastReviewedOnEndpointSelector,
  stateKey: 'lastReviewed',
  initialData: [],
  onSuccessActions: [],
  onFailureActions: [],
  method: 'GET',
});


const auditsEndpointSelector = createSelector(
  orgSelector,
  (_, loadAction) => loadAction,
  (orgId, loadAction) => {

    // 2024-08-31T18:30:00.000Z => 2024-08-31 => 2024/08/31
    const startDate = get(loadAction, 'payload.startDate') ?
      loadAction.payload.startDate.toISOString().split('T')[0].replaceAll('-', '/') :
      '2000/01/01';

    const endDate = get(loadAction, 'payload.endDate') ?
      loadAction.payload.endDate.toISOString().split('T')[0].replaceAll('-', '/') :
      new Date().toISOString().split('T')[0].replaceAll('-', '/');

    // const today = new Date();
    // const year = today.getFullYear();
    // // Months are zero-indexed, so add 1
    // const month = String(today.getMonth() + 1).padStart(2, '0');
    // // Pad single digit days with '0'
    // const day = String(today.getDate()).padStart(2, '0');

    // const endDate = `${year}/${month}/${day}`;

    let url = `turbotax/taxonomy/org/${orgId}/global_audits/` +
    `?start_date=${startDate}` +
    `&end_date=${endDate}`;

    if (get(loadAction, 'payload.user')) {
      url += `&user=${loadAction.payload.user}`;
    }

    if (get(loadAction, 'payload.activityType.value')) {
      url += `&activity_type=${loadAction.payload.activityType.value}`;
    }

    return url;
  }
);

export const loadAuditsStateGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: `${PRESET_STRING}/GET_AUDITS`,
  endpointSelector: auditsEndpointSelector,
  stateKey: 'getAudits',
  onSuccessActions: [],
  onFailureActions: [],
  method: 'GET',
});

