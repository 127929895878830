export const pendo_flags = [
  'rts_app',
  'hubspot_oauth',
  'sfdc_oauth',
  'pardot_oauth',
  'fortella_enabled',
  'capterra_enabled',
  'g2_product_mapping_enabled',
  'has_campaign_forecasting',
  'has_workflows_beta_access',
  'models_done',
  'enable_ai_data_standardization',
  'enable_data_standardization_v2',
  'enable_unified_pipeline',
  'has_workflows_beta_access',
  'standardization_v2_tables',
  'enable_1p_contact_enrichment',
  'enable_retargeting',
  'has_brand_safety',
  'has_contextual_targeting',
  'has_cpm_recommendation',
  'has_emc',
  'has_google_ads',
  'has_linkedin_advertising',
  'has_optimize_viewability',
  'segment_sync_to_linkedin',
  'enable_apikey_management',
  'enable_ol_webtag',
  'g2_has_categories',
  'g2_has_products',
  'g2_has_subscribed_categories',
  'g2_integration',
  'gartner_enabled',
  'hubspot_crm_enabled',
  'peerspot_enabled',
  'rts_eloqua_cdo',
  'show_legacy_webtag',
  'smart_form_fill',
  'enable_gobal_vm_reporting',
  'has_sales_license',
  'turbotax_all_mappings_published',
  'turbotax_in_production',
  'use_repete',
  'use_turbotax',
  'enable_ai_orchestration',
  'enable_cno',
  'has_salesloft_intg',
  'is_contact_vendor',
  'is_customer',
  'is_data_provider',
  'is_demo_org',
  'test_org',
  's3_testing',
  'enable_6signal_apikey_management',
  'is_oem_partner',
  'is_active',
  'enable_hubspot_app_migration',
  'hubspot_crm_workflows_migrated',
  'hubspot_map_workflows_migrated',
];

export const DEFAULT_PRODUCT_LIST = [{ name: '' }];
